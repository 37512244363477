import _ from 'lodash'
import React, { Component } from 'react'
import { Switch, Route, Redirect, BrowserRouter as Router, Link, browserHistory } from 'react-router-dom'

import Header from '../../layout/Header'
import SideBar from '../../layout/SideBar'
import Content from '../../layout/Content'
import UserProfile from '../UserProfile'
import GroupSetting from '../GroupSetting'
import ProfileModal from '../ProfileModal'
import AlertModal from '../AlertModal'
import Register from '../Register'
import ForgotPassword from '../ForgotPassword'
import Login from '../Login'

import { store } from '../../redux'
import { start_app  } from '../../redux/actions.js'

import { ToastContainer, toast } from 'react-toastify'

import sha256 from 'crypto-js/sha256';
import aes from 'crypto-js/aes';
import Base64 from 'crypto-js/enc-base64';

import XORCipher from '../../redux/hash.js'

const DefaultPage = () => {
    return (
        <div className="col-sm-7 conversation">
            <div className="row heading" style={{ backgroundColor: '#3b5998' }}></div>
            <div>
                <p style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)', textAlign: 'center', fontWeight: 'bold' }}>Please select a chat to start messaging</p>
            </div>
        </div>
    )
}


const ErrorPage = () => {
    return (
        <div className="col-sm-7 conversation">
            <div className="row heading" style={{ backgroundColor: '#3b5998' }}></div>
            <div>
                <p style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)', textAlign: 'center', fontWeight: 'bold' }}>CAN NOT ACCESS TO THIS CHAT</p>
            </div>
        </div>
    )
}

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }

        this.isAuthorization = localStorage.getItem('user_id')? true : false
        // this.isAuthorization = true
        this.redirectUrl = '';
    }

    updateData = () => {
        const state = store.getState()
        this.setState({
            data: state
        })
    }

    componentWillUnmount() {
        this.unsubscribe()
    }

    componentDidMount() {
        if(this.isAuthorization){
            if(window.location.pathname.indexOf('/login/') >= 0) {
                window.location = '/';
            }
        }

        this.updateData()
        this.unsubscribe = store.subscribe(() => {
            this.updateData()
        })

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        var sso = '';
        var redirect = '';
        if(urlParams.has('sso')){
            sso = urlParams.get('sso');
        }
        else if(sessionStorage.sso){
            sso = sessionStorage.sso;
        }

        if(urlParams.has('redirect')){
            redirect = urlParams.get('redirect');
        }
        else if(sessionStorage.redirect){
            redirect = sessionStorage.redirect;
        }

        if(sso!=''){
            if(this.isAuthorization){
                let url = new URL(sso);
                let params = new URLSearchParams(url.search.slice(1));

                let secret_key = 'BpK_app_SecreTKey';

                var user_id = XORCipher.decode('user_id', localStorage.getItem('user_id'));

                // Encrypt
                var encrypted = aes.encrypt(user_id+'-t='+Math.floor(Date.now() / 1000), secret_key);

                var data = encrypted.ciphertext.toString()
                var key = encrypted.key.toString()
                var iv = encrypted.iv.toString()

                params.append('token', data);
                params.append('key', key);
                params.append('iv', iv);
                params.append('redirect', redirect);
                window.location = url.origin+url.pathname+'?'+params.toString();
            }
            else{
                sessionStorage.sso = sso;
                sessionStorage.redirect = redirect;
            }
        }
    }
    
    render = () => {
        if(this.isAuthorization ) {
            return (
                <div className="container app">
                    <div className="row app-one">
                        <div className="toast-custom">
                            <ToastContainer autoClose={3000} />
                        </div>
                        <Switch>
                            <Route path="/" render={routeProps => <SideBar {...routeProps} data={this.state.data}/>} />
                            <Route exact path="/logout" render={routeProps => <SideBar {...routeProps} data={this.state.data}/>} />
                        </Switch>
                        <Switch>
                            <Route path='/chat/:id' render={routeProps => <Content {...routeProps} data={this.state.data} />} />
                            <Route exact path='/error' component={ErrorPage} />
                            <Route exact path='/' component={DefaultPage} />
                            <Route path='/login/:token' component={DefaultPage} />
                        </Switch>
                    </div>
                    <UserProfile data={this.state.data} />
                    <GroupSetting  data={this.state.data} />
                    <ProfileModal  data={this.state.data} />
                    <AlertModal  data={this.state.data} />
                </div>
            )
        } else {
            return (
                
                <Switch>
                    <Route path='/register' render={routeProps => <Register {...routeProps} data={this.state.data} />} />
                    <Route path='/forgot_password' render={routeProps => <ForgotPassword {...routeProps} data={this.state.data} />} />
                    <Route render={routeProps => <Login {...routeProps} data={this.state.data} />} />
                    <Route path='/login/:token' render={routeProps => <Login {...routeProps} data={this.state.data} />} />
                    <Route exact path="/logout" render={routeProps => <SideBar {...routeProps} data={this.state.data}/>} />
                </Switch>
            )
        }
    }
}

export default App
