import _ from 'lodash'
import $ from 'jquery'
import React, { Component } from 'react'

import { onUpdateProfile, isShowProfileModal, removeFavorite, addFavorite, onClickChat, startCall
    , isShowUserProfile, isShowGroupSetting } from '../../redux/actions.js'
import { store } from '../../redux'
import { Modal, Button } from 'react-bootstrap'

class ProfileModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            'user':null,
            'selectedFriend':null
        }
    }
    
    componentDidMount() {
      this.load_data()
    }

    componentWillUnmount() {
    }

    load_data = () => {
        if(_.get(this.props.data, 'user.user')) {
            // console.log(_.get(this.props.data, 'system.selectedFriend',null));
            this.setState({
                user: _.get(this.props.data, 'user.user',null),
                selectedFriend: _.get(this.props.data, 'system.selectedFriend',null),
                friends: _.get(this.props.data, 'friend.friends',null)
            })
        }

        if(_.get(this.props.data, 'system')) {
            this.setState({
                isShowProfileModal: _.get(this.props.data, 'system.isShowProfileModal')
            })
        }
    }

    componentWillReceiveProps() {
        this.load_data()
    }

    isInFavorite = () => {
        if(_.get(this.state, 'friends') && _.get(this.state, 'selectedFriend')) {
            const obj = this.state.friends.favorite.find((friend) => {
                return friend.friend_user_id == this.state.selectedFriend.friend_user_id
            })
            return !!obj
        }
        return false
    }
    
    _removeFavorite = () => {
        const selectedFriend = this.state.selectedFriend
        selectedFriend.is_favorite = 'F'
        this.setState({
            selectedFriend
        })
        console.log(this.state.numberOfFriendLists)
        store.dispatch(removeFavorite(this.state.user.user_id, this.state.selectedFriend.friend_user_id))
    }

    _addFavorite = () => {
        const selectedFriend = this.state.selectedFriend
        selectedFriend.is_favorite = 'T'
        this.setState({
            selectedFriend
        })
        console.log(this.state.numberOfFriendLists)
        store.dispatch(addFavorite(this.state.user.user_id, this.state.selectedFriend.friend_user_id, this.state.selectedFriend))
    }

    _toggleFavorite = () => {
        if(this.state.selectedFriend.is_favorite == 'T') {
            this._removeFavorite()
        } else {
            this._addFavorite()
        }
    }

    _go_to_group_setting = () => {
        store.dispatch(isShowProfileModal(false,this.state.selectedFriend))
        store.dispatch(isShowGroupSetting(true))
    }

    render = () => {
        return (
            <div className="static-modal">
            <Modal className="modal-profile" backdrop={'static'} show={this.state.isShowProfileModal} onHide={() => {
                    store.dispatch(isShowProfileModal(false))
                }}>

                <div className="profile-box">
                    <div className="profile-cover-image">
                        <img src={ _.get(this.state, 'selectedFriend.wall_pic_url')} />
                    </div>
                    <div className="profile-picture avatar-icon">
                        <span className="avatar-img" style={{border:0, width:100, height:100, backgroundImage:`url(${_.get(this.state, 'selectedFriend.profile_pic_url')})`}}></span>
                    </div>
                    <div className="profile-content">
                        <h1 style={{ fontSize: '28px'}} className="displayname">
                            { _.get(this.state, 'selectedFriend.display_name')}
                        </h1>
                        <p style={{ fontSize: '20px' }}>
                            { _.get(this.state, 'selectedFriend.section_name',"") }
                        </p>
                        <div className={ _.get(this.state, 'selectedFriend.chat_room_type') == 'C'? 'show': 'hide' } style={{ height: 'auto !important' }}>
                            <p style={{ fontSize: '15px' }}>
                                Patient Name : { _.get(this.state, 'selectedFriend.c_patient_name') || '-' }
                            </p>
                            <p style={{ fontSize: '15px' }}>
                                HN : { _.get(this.state, 'selectedFriend.c_hn') || '-' }
                            </p>
                            <p style={{ fontSize: '15px' }}>
                                Description : { _.get(this.state, 'selectedFriend.c_description') || '-' }
                            </p>
                        </div>   
                        { !_.get(this.state, 'selectedFriend.hideButton', false) &&
                          <div className={ _.get(this.state, 'selectedFriend.chat_room_type', 'undifined') == 'N' || _.get(this.state, 'selectedFriend.chat_room_type', 'undifined') == null? 'socials': 'hide' } style={{ marginTop: '20px' }}>
                            <a>
                              <i className="fa fa-comments fa-lg"  onClick={() => {
                                store.dispatch(isShowProfileModal(false))
                                store.dispatch(onClickChat(_.get(this.state, 'selectedFriend')))
                            }}></i>
                            </a>
                            {
                              1==0 && 
                              <a>
                                <i className="fa fa-phone-square fa-lg"  onClick={() => {
                                  store.dispatch(isShowProfileModal(false))
                                  store.dispatch(startCall(_.get(this.state, 'user.user_id'), _.get(this.state, 'selectedFriend'), _.get(this.state, 'user.profile_pic_url'), _.get(this.state, 'user.display_name')))
                                }}>
                                </i>
                              </a>
                            }
                            <a>
                              <i className="fa fa-heart" style={{  color: this.isInFavorite()? '#ff6666': '#d2d2d2' }} onClick={() => this._toggleFavorite() }></i>
                            </a>
                          </div>
                        }
                        { !_.get(this.state, 'selectedFriend.hideButton', false) &&
                        <div className={ _.get(this.state, 'selectedFriend.chat_room_type', 'undifined') != 'N' && _.get(this.state, 'selectedFriend.chat_room_type', 'undifined') != null? 'socials': 'hide' } style={{ marginTop: '20px' }}>
                            <a>
                                <i className="fa fa-comments fa-lg"  onClick={() => {
                                    store.dispatch(isShowProfileModal(false))
                                    if(_.get(this.state.user, 'user_id') == _.get(this.state.selectedFriend, 'user_id')) {
                                        store.dispatch(onClickChat(this.props.data.user.keepProfile))
                                    } else {
                                        store.dispatch(onClickChat(_.get(this.state, 'selectedFriend')))
                                    }
                                }}></i>
                            </a>
                            <a>
                                <i className="fa fa-cog fa-lg"  onClick={() => {
                                    store.dispatch(isShowProfileModal(false))
                                    if(_.get(this.state.user, 'user_id') == _.get(this.state.selectedFriend, 'user_id')) {
                                        store.dispatch(isShowUserProfile(true))
                                    } else {
                                        this._go_to_group_setting()
                                    }
                                }}></i>
                            </a>
                        </div>
                        }
                    </div>
                </div>

                <Modal.Footer>
                    <Button onClick={() => {
                        store.dispatch(isShowProfileModal(false))
                    }}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
        )
    }
}

export default ProfileModal