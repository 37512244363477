import _ from 'lodash'
import $ from 'jquery'

import React, { Component } from 'react'
import { Switch, Route, Redirect, BrowserRouter as Router, Link, browserHistory } from 'react-router-dom'

import { store } from '../../redux'
import { apiForgotPassword  } from '../../redux/api.js'

import '../../css/login/main.css'
import '../../css/login/util.css'

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          error: "",
          success: "",
          loading: false,
          email: '',
        }
        $('body').css("background-color","#3b5998")
    }


    updateData = () => {
      const state = store.getState()
      this.setState({
      })
    }


    componentDidMount() {
      this.updateData()
      this.unsubscribe = store.subscribe(() => {
          this.updateData()
      })
    }

    componentWillUnmount() {
        this.unsubscribe()
    }
    

    forgotPassword = async(e) => {
      if(e) {
        e.preventDefault()
      }
      var email = this.state.email;
      if(email==''){
        this.setState({
          error:'Please, specify email.',
        })
        return;
      }

      this.setState({
        loading:true,
        error:'',
        success:'',
      })

      var data = {
        'email':email
      };

      const res = await apiForgotPassword(data);
      console.log(res);
      if(_.get(res,'data',null)==null){
        this.setState({
          loading:false,
          error:'Error'
        })
      }
      else if(res.data.error!=''){
        this.setState({
          loading:false,
          error:res.data.error
        })
      }
      else{
        this.setState({
          loading:false,
          success:"Success. New password has been sent to your email."
        })
      }
    }

    render = () => {
        return (
            <div style={{ paddingTop: '40px', paddingBottom: '40px' }} className="signup">
                <div>
                    <form className="form-signin" onSubmit={(e)=>{this.forgotPassword(e);}}>
                        <h3 style={{ textAlign: 'center', marginBottom: '40px', color:'white' }}>Forgot Password</h3>
                        <label for="inputEmail" className="sr-only">ID</label>
                        <input type="text" id="inputEmail" className="form-control" placeholder="Email/อีเมล์" required="" style={{ marginBottom: '10px', border: '1px solid white' }} value={this.state.email} onChange={(event) => this.setState({email: event.target.value})} />
                        {
                          this.state.error!=''&&
                          <p style={{ marginTop: '10px', textAlign: 'center', marginBottom: '10px', color:'white' }}>{ this.state.error }</p>
                        }
                        {
                          this.state.success!=''&&
                          <p style={{ marginTop: '10px', textAlign: 'center', marginBottom: '10px', color:'white' }}>{ this.state.success }</p>
                        }
                        <div style={{ padding: '10px', textAlign: 'center' }}>
                        {
                          this.state.loading&&
                          <i className="fa fa-spinner fa-pulse fa-2x fa-fw" style={{ fontSize: '25px', color: 'white' }} ></i>
                        }
                        </div>

                        <button className="btn btn-lg btn-primary btn-block" type="button" style={{ backgroundColor: '#8e90c5', border: '1px solid #ccc' }} onClick={() => this.forgotPassword() } >Submit</button>
                        <div style={{ paddingTop: '30px', textAlign: 'center' }}>
                            <a  href="/" style={{ marginLeft: '10px', fontWeight: 'bold', color:'white' }}>
                                Back to Sign in
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default ForgotPassword
