import _ from 'lodash'
import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Contact from '../../components/Contact'
import ChatList from '../../components/ChatList'
import TagList from '../../components/TagList'
import QuickReplyList from '../../components/QuickReplyList'
import { Modal, Button } from 'react-bootstrap'

import { store } from '../../redux'
import {
    onClickChat,
    isShowUserProfile,
    isShowCovidBedModal,
    isShowProfileModal,
    onSearchChatList,
    isLoading,
    filterChatTag
} from '../../redux/actions'
import { apiGetCovidBedList
} from '../../redux/api'

class SideBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            is_show_chat_list: true,
            page: 'contact',
            hide_logout: 'F',
            is_crm: false,
            show_setting_submenu: false,
            isShowCovidBedModal: false,
            covidBedLists: [],
            isLoadingCovidBed: false,
        }
    }

    onClickShowCovidBed = async(e) => {
      e.preventDefault()
      e.stopPropagation()

      this.setState({
        isLoadingCovidBed: true
      });
      var res = await apiGetCovidBedList();
      this.setState({
        covidBedLists: _.get(res, 'data.results', []),
        isShowCovidBedModal: true,
        isLoadingCovidBed: false
      });
    }

    navigateToChat = () => {
        this.setState({
            is_show_chat_list: !this.state.is_show_chat_list
        })
    }

    goToContact = () => {
        this.setState({
          is_show_chat_list: false,
          page: 'contact'
        })
    }

    goToChatList = (filter='') => {
      this.setState({
        is_show_chat_list: true,
        page: 'contact'
      })
      store.dispatch(onSearchChatList(filter))
      store.dispatch(filterChatTag(filter))
    }

    goToTagList = () => {
        this.setState({
          is_show_chat_list: false,
          page: 'tags'
        })
    }

    goToQuickReplyList = () => {
        this.setState({
          is_show_chat_list: false,
          page: 'quick_reply'
        })
    }

    check_path = () => {
        if(this.props.history.location.pathname == '/logout') {
            localStorage.clear()
            window.location = '/';
        }else if(this.props.history.location.pathname == '/') {
            this.setState({
                page: 'contact'
            })
        }else {
            this.setState({
                page: 'content'
            })
        }
    }

    componentDidMount() {
        this.check_path()
        this.props.history.listen((location, action) => {
            this.check_path()
        })
    }

    componentWillReceiveProps() {
        if(_.get(this.props.data, 'user.user')) {
            this.setState({
                user: this.props.data.user.user
            })
        }
        this.setState({
            hide_logout: _.get(this.props.data, 'system.hide_logout','F')
        })

        const  state = this.props.data;

        this.setState({
          is_crm: _.get(state, 'user.user.is_crm', false),
        })
    }

    signout = () => {
        localStorage.clear()
        // location.reload()
        window.location = '/';
    }

    toggleShowSettingSubmenu = () => {
      this.setState({
        show_setting_submenu: !this.state.show_setting_submenu
      })
    }

    render = () => {
        let mainClass = '';
        if(this.state.page=='tags'){
          mainClass = 'col-sm-12 side';
        }
        else if(this.state.page=='quick_reply'){
          mainClass = 'col-sm-12 side';
        }
        else if(this.state.page=='contact'){
          mainClass = 'col-sm-5 side';
        }
        else{
          mainClass = 'col-sm-5 side mobile-hide';
        }
        return (
          <>
            <div className={ mainClass }>
                {/* { this.props } */}
                <nav className="main-menu mobile-hide">
                    <ul>
                        <li onClick={() => {}}>
                            <div className="heading-avatar " style={{ width: 'auto' }}>
                                <div className="avatar-icon">
                                    <span className="avatar-img" style={{backgroundImage:`url(${_.get(this.state, 'user.profile_pic_url')})`, margin: '1px 12px', width: '50px', height: '50px'}}></span>
                                </div>
                            </div>
                        </li>
                        { 
                          (_.get(this.state, 'user.user_role')!='outside' && _.get(this.state, 'user.user_role')!='agent') &&
                          <li onClick={() => this.goToContact() } style={{ cursor: 'pointer' }}>
                              <a style={{ margin: '8px' }}>
                                  <i className="fa fa-side-bar fa-users fa-2x" style={{ fontSize: '25px', color: 'white' }}></i>
                              </a>
                          
                          </li>
                        }
                        <li className="has-subnav" onClick={() => this.goToChatList() } style={{ cursor: 'pointer' }}>
                            <a style={{ margin: '8px' }}>
                                <i className="fa fa-side-bar fa-comments fa-2x" style={{ fontSize: '25px', color: 'white' }}></i>
                            </a>
                            
                        </li>
                    </ul>

                    <ul className="menu-bottom">
                        <li style={{ cursor: 'pointer' }} onClick={() => store.dispatch(onClickChat(this.props.data.user.keepProfile))}>
                            <a style={{ margin: '8px' }}>
                                <i className="fa fa-side-bar fa-cloud-download  fa-2x" style={{ fontSize: '25px', color: 'white' }} ></i>
                            </a>
                        </li>
                        {
                          this.state.is_crm && 
                          <li style={{ cursor: 'pointer' }} onClick={() => this.toggleShowSettingSubmenu()} style={{ cursor: 'pointer' }}>
                            <a style={{ margin: '8px' }}>
                              <i className="fa fa-side-bar fa-cog fa-2x" style={{ fontSize: '25px', color: 'white' }} ></i>
                            </a>
                            <div className={"btn-group-vertical"+(this.state.show_setting_submenu?"":" hide")}>
                              <button type="button" className="btn btn-primary" onClick={() => {
                                store.dispatch(isShowUserProfile(true));
                                this.toggleShowSettingSubmenu();
                              }}>Account</button>

                              <button type="button" className="btn btn-primary" onClick={() => {
                                this.toggleShowSettingSubmenu();
                                this.goToTagList();
                              }}>Tags</button>

                              <button type="button" className="btn btn-primary" onClick={() => {
                                this.toggleShowSettingSubmenu();
                                this.goToQuickReplyList();
                              }}>Quick Reply</button>
                            </div>
                          </li>  
                        }
                        {
                          !this.state.is_crm && 
                          <li style={{ cursor: 'pointer' }} onClick={() => store.dispatch(isShowUserProfile(true))} style={{ cursor: 'pointer' }}>
                            <a style={{ margin: '8px' }}>
                              <i className="fa fa-side-bar fa-cog fa-2x" style={{ fontSize: '25px', color: 'white' }} ></i>
                            </a>
                          </li>  
                        }
                        {
                          this.state.is_crm && 
                          <li style={{ cursor: 'pointer' }} onClick={(e) => this.onClickShowCovidBed(e)} style={{ cursor: 'pointer' }}>
                            <a style={{ margin: '8px' }}>
                              {
                                this.state.isLoadingCovidBed &&
                                <i className="fa fa-side-bar fa-spinner fa-pulse fa-2x fa-fw" style={{ fontSize: '25px', color: 'white' }} ></i>
                              }
                              {
                                !this.state.isLoadingCovidBed &&
                                <i className="fa fa-side-bar fa-bed fa-2x" style={{ fontSize: '25px', color: 'white' }} ></i>
                              }
                            </a>
                          </li>  
                        } 
                        { 
                          this.state.hide_logout!='T' &&
                          <li style={{ cursor: 'pointer' }} onClick={() => this.signout()}>
                              <a style={{ margin: '8px' }}>
                                  <i className="fa fa-side-bar fa-sign-out fa-2x" style={{ fontSize: '25px', color: 'white' }} ></i>
                              </a>
                          </li> 
                        }
                    </ul>
                </nav>

                <div className="side-one nav-side-bar">
                    <Contact navigateToChat={this.navigateToChat} goToContact={this.goToContact} goToChatList={this.goToChatList} onRef={ref => (this.child = ref)} navigateToChat={this.navigateToChat} data={this.props.data} history={this.props.history} />
                </div>

                <div className={this.state.is_show_chat_list ? 'side-two nav-side-bar chat-list-show': 'side-two nav-side-bar chat-list-hide'}>
                    <ChatList navigateToChat={this.navigateToChat} goToContact={this.goToContact} goToChatList={this.goToChatList} data={this.props.data} history={this.props.history} />
                </div>

                {
                  this.state.page=='tags' &&
                  <div className={'side-two nav-side-bar chat-list-show tag-page'}>
                      <TagList goToChatList={this.goToChatList} data={this.props.data} history={this.props.history} />
                  </div>
                }

                {
                  this.state.page=='quick_reply' &&
                  <div className={'side-two nav-side-bar chat-list-show tag-page'}>
                      <QuickReplyList goToChatList={this.goToChatList} data={this.props.data} history={this.props.history} />
                  </div>
                }

                <div className="height-auto">
                    <div className="static-modal">
                        <Modal show={this.state.isShowCovidBedModal} onHide={() => {
                                this.setState({
                                    isShowCovidBedModal: false
                                })
                            }}>
                            <Modal.Header style={{ backgroundColor: '#eee' }}>
                              <Modal.Title>เตียงสำหรับผู้ป่วย COVID19</Modal.Title>
                            </Modal.Header>
                            <div style={{padding:3}}>
                            {
                                this.renderCovidBedLists()
                            }
                            </div>
                            <Modal.Footer>
                                <Button onClick={() => {
                                    this.setState({
                                        isShowCovidBedModal: false
                                    })
                                }}>Close</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
          </>
        )
    }
    
    renderCovidBedLists = () => {
      var last_hos_name = '';
      const covidBedLists = this.state.covidBedLists.map((info) => {
        var hosHeader = '';
        if(info.hos_name != last_hos_name){
          hosHeader = (<tr><td colSpan="4"><b>{info.hos_name}</b></td></tr>);
        }
        last_hos_name= info.hos_name;

        if(hosHeader!=''){
          return (
            <>
              {hosHeader}
              <tr><td>{info.ward}</td><td>{info.room_type}</td><td>{info.status}</td><td>{info.count}</td></tr>
            </>
          )
        }
        else{
          return (
            <tr><td>{info.ward}</td><td>{info.room_type}</td><td>{info.status}</td><td>{info.count}</td></tr>
          )
        }
      })
      return (<table className="table-covid-bed" width="100%">
        <thead><tr><th>Ward</th><th>Room Type</th><th>Status</th><th>Totals</th></tr></thead>
        <tbody>{covidBedLists}</tbody>
        </table>)
    }
}

export default SideBar
