import _ from 'lodash'
import $ from 'jquery'
import React from 'react'
import moment from 'moment'
import download from 'downloadjs'
import Friend from '../../components/Friend'

import { ReactMic } from 'react-mic'
import { Modal, Button } from 'react-bootstrap'
import AudioPlayer from '../../components/AudioPlayer'
import Checkbox from '../../components/Checkbox'
import ForwardModal from '../../components/ForwardModal'
import ReactChatView from 'react-chatview'
import { store } from '../../redux'
import { start_socket_conference,disconnect_socket_conference,get_conference_room,delete_conference_room } from '../../redux/socket_conference.js'
import { navigateSelector } from '../../redux/selectors.js'

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import ReactDOM from 'react-dom'
import ReactTags from 'react-tag-autocomplete'
import Linkify from 'react-linkify';

import {
    onLoadMoreMessageLists,
    onFetchInviteFriend,
    loadMoreInviteFriends, 
    onInviteFriendToGroup,
    onRemoveFriendFromGroup,
    onExitTheGroup,
    onFetchFriendInGroup,
    onLoadMoreMemberInGroup,
    onEnterOptionMessage,
    onLoadMoreOptionMessage,
    onInviteFriendToGroupWithOpenCase,
    onInviteFriendToGroupWithConference,
    onFetchMessageLists,
    isShowSearchBar,
    onForward,
    inviteFriends,
    chat,
    selectChat,
    onMuteChat, 
    onHideChat, 
    onBlockChat, 
    onDeleteChat, 
    onUnblockChat, 
    onUnmuteChat,
    isLoading,
    onGroupToggleAdmin,
    onLeaveChat,
    isShowProfileModal,
    onSearchFriendForward,
    isShowAlertModal
} from '../../redux/actions'
import {sendTheMessage, fetchFriendProfile, saveInKeep, sendFileMessage, fetchChatInfo
  , chatRoomNoteSave, chatRoomNoteGet, chatRoomNoteDelete
  , chatRoomTagSave, chatRoomTagGet, chatRoomTagDelete
  , chatRoomFollow, chatRoomResolve, chatRoomUpdateOutsideCustomer, deleteConference
  , apiUnsendMessage, apiDeleteMessageFromUserChatRoom
  , apiGetTagSuggestion, apiGetQuickReplyList
  , apiGetSearchPatientList, apiGetCovidResultList
  , apiAddCovidResultLog
} from '../../redux/api'
import {
    emit_update_friend_chat_list,
    emit_unsubscribe,
    emit_message
} from '../../redux/socket.js'
import {Confirm } from 'react-confirm-bootstrap'

function getBase64(file) {
    return new Promise((resolve, reject) => {
        var reader = new FileReader()
        if(file){
          reader.readAsDataURL(file)
          reader.onload = function () {
              resolve(reader.result)
          }
          reader.onerror = function (error) {
              resolve(error)
          }
        }
    })
    
}

class Content extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sticker: [],
            collectionKeySelected: 0,
            currentTime: 0.0,
            roundRecording: 0,
            timer: 0,
            member: [],
            inviteFriends: [],
            inviteFilter: '',
            inviteFriendsCount: 0,
            selectedOptionMessageId: {},
            isLoading: true,
            conferenceCount: 0,
            last_read: null,
            is_group_admin: 'F',
            is_manager: 'F',
            isShowForwardModal: false,
            is_crm: false,
            is_show_note: false,
            can_add_conf: false,
            is_resolved: 'F',
            is_follow: 'F',
            is_conference: 'F',
            chat_room_tags: [],
            chatTagSuggestions: [],
            chat_room_notes:[],
            isShowAddConferenceModal: false,
            outside_customer: null,
            showUnsendBtn: false,
            isShowQuickReplyModal: false,
            quickReplyLists: [],
            isShowSearchPatientModal: false,
            searchPatientFor: '',
            searchPatientLists: [],
            searchPatientListShowMore: true,
            searchPatientListStart: 0,
            search_patient_hos_code: '',
            search_patient_hn: '',
            search_patient_pid: '',
            search_patient_passport_no: '',
            search_patient_firstname: '',
            search_patient_lastname: '',
            selectedSearchPatientId: '',
            isShowSearchCovidResultModal: false,
            searchCovidResultList: [],
            system_type: null,
            user_type_branchcode: null,
            showMessageSending: false
        }
        this.handleWindowMessage = this.handleWindowMessage.bind(this);

        this.reactChatTags = React.createRef()
    }

    showProfileModal = async(e, chat) => {
      e.preventDefault();
      store.dispatch(isLoading(true))
      const res = await fetchFriendProfile(chat.create_by);
      store.dispatch(isLoading(false))
      if(res.data.data!=null){
        res.data.data.hideButton = true;
        store.dispatch(isShowProfileModal(true,res.data.data))
      }
    }

    unsendMessage = async(chat_message_id) => {
      const res = await apiUnsendMessage(chat_message_id);
      if(res.data.error!=''){
        alert(res.data.error);
      }
      else{
        var newChats = new Array();
        _.get(this.state, 'chat', []).map((chat, index) => {
          if(chat.chat_message_id == chat_message_id){
            chat.is_unsent='T';
          }
          newChats.push(chat);
        });      
        this.setState({ 
          show_selected_chat_message: false,
          selected_chat_message_id: '',
          chat: newChats
        })  
        store.dispatch(chat(newChats));

        emit_update_friend_chat_list(this.state.user.user_id, this.state.user.user_id)   

        // update every friends in group
        if(this.state.chatInfo.chat_room_type == 'G' || this.state.chatInfo.chat_room_type == 'C') {
            const friend_user_ids = this.state.chatInfo.friend_user_ids.split(',')
            friend_user_ids.forEach((friend_user_id) => {
                emit_update_friend_chat_list(this.state.user.user_id, friend_user_id)
            })
        } else {
            emit_update_friend_chat_list(this.state.user.user_id, this.state.chatInfo.friend_user_id)
        }      
      }
    }

    deleteMessage = async(chat_message_id) => {
      const res = await apiDeleteMessageFromUserChatRoom(chat_message_id);
      if(res.data.error!=''){
        alert(res.data.error);
      }
      else{
        var newChats = new Array();
        _.get(this.state, 'chat', []).map((chat, index) => {
          if(chat.chat_message_id != chat_message_id){
            newChats.push(chat);
          }
        });      
        this.setState({ 
          show_selected_chat_message: false,
          selected_chat_message_id: '',
          chat: newChats
        })  
        store.dispatch(chat(newChats));    
      }
    }

    onDeleteChatTag = async(i) => {
      const chatTags = this.state.chat_room_tags.slice(0)
      var chatTag = chatTags[i];
      if(!chatTag) return;

      const res = await chatRoomTagDelete(chatTag.id);
      if(res.data.error!=''){
        alert(res.data.error);
      }
      else{
        chatTags.splice(i, 1)
        this.setState({ 'chat_room_tags':chatTags })
      }
    }

    onAdditionChatTag = async(tag) => {
      var data = {
        'tag':tag.name
      };
      data.chat_room_tag_id = -1;
      data.chat_room_id = this.state.chatInfo.chat_room_id;
      data.update_by = this.state.user.user_id;
      const res = await chatRoomTagSave(data);
      if(res.data.error!=''){
        alert(res.data.error);
      }
      else{
        tag = res.data.chat_room_tag;
        const chatTags = [].concat(this.state.chat_room_tags, tag)
        this.setState({ 'chat_room_tags':chatTags })
      }
    }

    onClickAddConference = async() => {
      var friend_user_ids =  this.state.chatInfo.friend_user_ids;
      const selectedOptionMessageId = []
      _.forEach(this.state.selectedOptionMessageId, (value, key) => {
          if(value) {
              selectedOptionMessageId.push(key)
          }
      })
      store.dispatch(onInviteFriendToGroupWithConference(this.state.chatInfo.chat_room_id, friend_user_ids, selectedOptionMessageId))
    }

    onClickEndConference = async() => {
      delete_conference_room(this.state.chatInfo.chat_room_id);

      var data = {
        chat_room_id : this.state.chatInfo.chat_room_id
      }
      const res = await deleteConference(data);
      if(res.data.error!=''){
        alert(res.data.error);
      }
      else{
        store.dispatch(onLeaveChat());

        // update our own chat list
        emit_update_friend_chat_list(this.state.user.user_id, this.state.user.user_id)
      }
    }

    onClickChatRoomFollow = async(is_follow) => {
      const res = await chatRoomFollow(this.state.chatInfo.chat_room_id, is_follow);
      if(res.data.error!=''){
        alert(res.data.error);
      }
      else{
        // update our own chat list
        emit_update_friend_chat_list(this.state.user.user_id, this.state.user.user_id)
      }
    }

    onClickChatRoomResolve = async(is_resolved) => {
      const res = await chatRoomResolve(this.state.chatInfo.chat_room_id, is_resolved);
      if(res.data.error!=''){
        alert(res.data.error);
      }
      else{
        // update our own chat list
        emit_update_friend_chat_list(this.state.user.user_id, this.state.user.user_id)
      }
    }

    onClickCopyLink = async(id) =>{
      var copyText = document.getElementById(id);

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");
    }

    generateID = () => {
        return '_' + Math.random().toString(36).substr(2, 9)
    }

    async _pushMessage(message,isConference) {
        if (!message)
            return

        const draft_message_id = this.generateID()
        // send local message
        const draftMessage = {
            chat_message_id: draft_message_id,
            draft_message_id: draft_message_id,
            content: message,
            username: this.state.user.username,
            who_read: [],
            create_date: new Date(),
            profile_pic_url: this.state.user.profile_pic_url,
            message_type: '1',
            isError: false
        }

        this.setState({
            message: ''
        })

        const messageLists = _.get(this.state, 'chat', [])
        const chatData = [draftMessage].concat(messageLists)
        store.dispatch(chat(chatData))

        try {
            var stream_id='';
            if(isConference!=null && isConference){
              stream_id = this.state.chatInfo.chat_room_id;
            }

            const resSendTheMessage = await sendTheMessage(this.state.chatInfo.chat_room_id, '1', message, '', '','',stream_id)

            if(_.get(resSendTheMessage, 'error','') != ''){
              throw _.get(resSendTheMessage, 'error','');    // throw errror
              return;
            }

            const chat_message_id = _.get(resSendTheMessage, 'new_chat_message.chat_message_id')
            this.setState({'last_read':_.get(resSendTheMessage, 'last_read')});

            // update message for everyone in group
            emit_message(message, this.state.chatInfo.chat_room_id, this.state.user.user_id, chat_message_id, draft_message_id)

            // update our own
            emit_update_friend_chat_list(this.state.user.user_id, this.state.user.user_id)

            // update every friends in group
            if(this.state.chatInfo.chat_room_type == 'G' || this.state.chatInfo.chat_room_type == 'C') {
                const friend_user_ids = this.state.chatInfo.friend_user_ids.split(',')
                friend_user_ids.forEach((friend_user_id) => {
                    emit_update_friend_chat_list(this.state.user.user_id, friend_user_id)
                })
            } else {
                emit_update_friend_chat_list(this.state.user.user_id, this.state.chatInfo.friend_user_id)
            }

            this.setState({
                message: ''
            })

            $( "div.row.message.content" ).scrollTop( this.messagesEnd.scrollHeight )
        } catch(err) {
            const indexLocal = chatData.findIndex((message) => {
                return _.get(message, 'draft_message_id', 'unknown') == draft_message_id
            })

            chatData[indexLocal].isError = true
            store.dispatch(chat(chatData))

            $( "div.row.message.content" ).scrollTop( this.messagesEnd.scrollHeight )
            return
        }
    }

    async _pushSticker(sticker_path, object_url) {
        const draft_message_id = this.generateID()

        // send local message
        const draftMessage = {
            chat_message_id: draft_message_id,
            draft_message_id: draft_message_id,
            content: '',
            username: this.state.user.username,
            who_read: [],
            create_date: new Date(),
            profile_pic_url: this.state.user.profile_pic_url,
            message_type: '4',
            object_url: object_url,
            sticker_path: sticker_path,
            isError: false
        }

        this.setState({
            message: '',
            footer_selected: ''
        })

        const messageLists = _.get(this.state, 'chat', [])
        const chatData = [draftMessage].concat(messageLists)
        store.dispatch(chat(chatData))

        try {
            const resSendTheMessage = await sendTheMessage(this.state.chatInfo.chat_room_id, '4', '', sticker_path, '')
            const chat_message_id = _.get(resSendTheMessage, 'new_chat_message.chat_message_id')
            this.setState({'last_read':_.get(resSendTheMessage, 'last_read')});

            // update message for everyone in group
            emit_message('', this.state.chatInfo.chat_room_id, this.state.user.user_id, chat_message_id, draft_message_id)

            // update our own
            emit_update_friend_chat_list(this.state.user.user_id, this.state.user.user_id)

            // update every friends in group
            const friend_user_ids = this.state.chatInfo.friend_user_ids.split(',')
            friend_user_ids.forEach((friend_user_id) => {
                emit_update_friend_chat_list(this.state.user.user_id, friend_user_id)
            })

            this.setState({
                message: ''
            })

            $( "div.row.message.content" ).scrollTop( this.messagesEnd.scrollHeight )
        } catch(err) {
            const indexLocal = chatData.findIndex((message) => {
                return _.get(message, 'draft_message_id', 'unknown') == draft_message_id
            })

            chatData[indexLocal].isError = true
            store.dispatch(chat(chatData))
            $( "div.row.message.content" ).scrollTop( this.messagesEnd.scrollHeight )
            return
        }
    }

    async _pushPhoto(base64, object_url) {
      console.log('_pushPhoto');
        const draft_message_id = this.generateID()
        // send local message
        const draftMessage = {
            chat_message_id: draft_message_id,
            draft_message_id: draft_message_id,
            content: '',
            username: this.state.user.username,
            who_read: [],
            create_date: new Date(),
            profile_pic_url: this.state.user.profile_pic_url,
            message_type: '2',
            object_url: object_url,
            base64: base64,
            isError: false
        }

        const messageLists = _.get(this.state, 'chat', [])
        const chatData = [draftMessage].concat(messageLists)
        store.dispatch(chat(chatData))

        this.setState({
          'showMessageSending': false
        });

        try {
            const resSendTheMessage = await sendTheMessage(this.state.chatInfo.chat_room_id, '2', '', '', base64)
            const chat_message_id = _.get(resSendTheMessage, 'new_chat_message.chat_message_id')
            this.setState({'last_read':_.get(resSendTheMessage, 'last_read')});

            // update message for everyone in group
            emit_message('', this.state.chatInfo.chat_room_id, this.state.user.user_id, chat_message_id, draft_message_id)

            // update our own
            emit_update_friend_chat_list(this.state.user.user_id, this.state.user.user_id)

            // update every friends in group
            const friend_user_ids = this.state.chatInfo.friend_user_ids.split(',')
            friend_user_ids.forEach((friend_user_id) => {
                emit_update_friend_chat_list(this.state.user.user_id, friend_user_id)
            })

            this.setState({
                message: ''
            })

            $( "div.row.message.content" ).scrollTop( this.messagesEnd.scrollHeight )
        } catch(err) {
            const indexLocal = chatData.findIndex((message) => {
                return _.get(message, 'draft_message_id', 'unknown') == draft_message_id
            })

            chatData[indexLocal].isError = true
            store.dispatch(chat(chatData))

            $( "div.row.message.content" ).scrollTop( this.messagesEnd.scrollHeight )
            return
        }
    }
    
    async _pushFile(file) {
        const draft_message_id = this.generateID()
        // send local message
        const draftMessage = {
            chat_message_id: draft_message_id,
            draft_message_id: draft_message_id,
            content: '',
            username: this.state.user.username,
            who_read: [],
            create_date: new Date(),
            profile_pic_url: this.state.user.profile_pic_url,
            message_type: '5',
            object_url: file,
            file_name: file.name,
            file_extension: file.type,
            isError: false
        }

        const messageLists = _.get(this.state, 'chat', [])
        const chatData = [draftMessage].concat(messageLists)
        store.dispatch(chat(chatData))

        this.setState({
          'showMessageSending': false
        });

        try {
            const resSendTheMessage = await sendFileMessage(this.state.chatInfo.chat_room_id, '5', file)
            const chat_message_id = _.get(resSendTheMessage, 'new_chat_message.chat_message_id')
            this.setState({'last_read':_.get(resSendTheMessage, 'last_read')});

            // update message for everyone in group
            emit_message('', this.state.chatInfo.chat_room_id, this.state.user.user_id, chat_message_id, draft_message_id)

            // update our own
            emit_update_friend_chat_list(this.state.user.user_id, this.state.user.user_id)

            // update every friends in group
            const friend_user_ids = this.state.chatInfo.friend_user_ids.split(',')
            friend_user_ids.forEach((friend_user_id) => {
                emit_update_friend_chat_list(this.state.user.user_id, friend_user_id)
            })

            this.setState({
                message: ''
            })

            $( "div.row.message.content" ).scrollTop( this.messagesEnd.scrollHeight )
        } catch(err) {
            const indexLocal = chatData.findIndex((message) => {
                return _.get(message, 'draft_message_id', 'unknown') == draft_message_id
            })

            chatData[indexLocal].isError = true
            store.dispatch(chat(chatData))

            $( "div.row.message.content" ).scrollTop( this.messagesEnd.scrollHeight )
            return
        }
    }

    async _pushFileBase64(file_base64,file_name,file_type) {
        const draft_message_id = this.generateID()
        // send local message
        const draftMessage = {
            chat_message_id: draft_message_id,
            draft_message_id: draft_message_id,
            content: '',
            username: this.state.user.username,
            who_read: [],
            create_date: new Date(),
            profile_pic_url: this.state.user.profile_pic_url,
            message_type: '5',
            object_url: null,
            file_name: file_name,
            file_extension: file_type,
            isError: false
        }

        const messageLists = _.get(this.state, 'chat', [])
        const chatData = [draftMessage].concat(messageLists)
        store.dispatch(chat(chatData))

        try {
            const resSendTheMessage = await sendFileMessage(this.state.chatInfo.chat_room_id, '5', null, file_base64, file_name+"."+file_type)
            const chat_message_id = _.get(resSendTheMessage, 'new_chat_message.chat_message_id')
            this.setState({'last_read':_.get(resSendTheMessage, 'last_read')});

            // update message for everyone in group
            emit_message('', this.state.chatInfo.chat_room_id, this.state.user.user_id, chat_message_id, draft_message_id)

            // update our own
            emit_update_friend_chat_list(this.state.user.user_id, this.state.user.user_id)

            // update every friends in group
            const friend_user_ids = this.state.chatInfo.friend_user_ids.split(',')
            friend_user_ids.forEach((friend_user_id) => {
                emit_update_friend_chat_list(this.state.user.user_id, friend_user_id)
            })

            this.setState({
                message: ''
            })

            $( "div.row.message.content" ).scrollTop( this.messagesEnd.scrollHeight )
        } catch(err) {
            const indexLocal = chatData.findIndex((message) => {
                return _.get(message, 'draft_message_id', 'unknown') == draft_message_id
            })

            chatData[indexLocal].isError = true
            store.dispatch(chat(chatData))

            $( "div.row.message.content" ).scrollTop( this.messagesEnd.scrollHeight )
            return
        }
    }

    _pushAudio = async () => {
        const draft_message_id = this.generateID()
        // send local message
        const draftMessage = {
            chat_message_id: draft_message_id,
            draft_message_id: draft_message_id,
            content: '',
            username: this.state.user.username,
            who_read: [],
            create_date: new Date(),
            profile_pic_url: this.state.user.profile_pic_url,
            message_type: '3',
            object_url: this.soundRecord.blobURL,
            file_name: `${draft_message_id}.wav`,
            file_extension: "audio/wav"
        }

        const messageLists = _.get(this.state, 'chat', [])
        const chatData = [draftMessage].concat(messageLists)
        store.dispatch(chat(chatData))

        try {
            const file = new File([this.soundRecord], `${draft_message_id}.wav`)
            const resSendTheMessage = await sendFileMessage(this.state.chatInfo.chat_room_id, '3', file)
            const chat_message_id = _.get(resSendTheMessage, 'new_chat_message.chat_message_id')
            this.setState({'last_read':_.get(resSendTheMessage, 'last_read')});

            // update message for everyone in group
            emit_message('', this.state.chatInfo.chat_room_id, this.state.user.user_id, chat_message_id, draft_message_id)

            // update our own
            emit_update_friend_chat_list(this.state.user.user_id, this.state.user.user_id)

            // update every friends in group
            const friend_user_ids = this.state.chatInfo.friend_user_ids.split(',')
            friend_user_ids.forEach((friend_user_id) => {
                emit_update_friend_chat_list(this.state.user.user_id, friend_user_id)
            })

            this.setState({
                message: '',
                roundRecording: 0
            })
        } catch(err) {
            const indexLocal = chatData.findIndex((message) => {
                return _.get(message, 'draft_message_id', 'unknown') == draft_message_id
            })

            chatData[indexLocal].isError = true
            store.dispatch(chat(chatData))

            return
        }
    }

    load_chat = () => {
      console.log(this.state);
        const chat_id = window.location.pathname.replace('/chat/','')
        store.dispatch(isLoading(true))
        emit_unsubscribe(_.get(this.state.chatInfo, 'chat_room_id'))
        console.log('fetchChatInfo')
        fetchChatInfo(chat_id).then((res) => {
          console.log('fetchChatInfo success')
            if(_.get(res, 'data.data')) {
                this.setState({
                    chatInfo: res.data.data,
                    isSelectChatForOpenCase: false,
                    selectedOptionMessageId: {},
                    show_selected_chat_message: false,
                    selected_chat_message_id: '',
                    showInviteFriend: false,
                    footer_selected: '',
                    outside_customer: _.get(res.data.data,'outside_customer',null)
                })
                store.dispatch(selectChat(res.data.data))
                store.dispatch(onFetchFriendInGroup(chat_id))   
                this.updateConferenceRoom(chat_id);
                this.getChatRoomNotes(chat_id); 
                this.getChatRoomTags(chat_id);
                console.log('fetchChatInfo done')
            } else {
                this.props.history.push('/error')
            }
        }, () => {
        })
    }

    updateConferenceRoom = (chat_room_id) =>{
      var main = this;
      get_conference_room(chat_room_id,function(listOfRooms){
        console.log(listOfRooms);
        if(listOfRooms && listOfRooms.length>0){
          var participants = listOfRooms[0].participants;
          main.setState({
              conferenceCount: participants.length
          })
        }
        else{            
          main.setState({
              conferenceCount: 0
          })
        }
      });
    }

    startConference = (chat_room_id) =>{
      var h = window.screen.height-150;
      var w = window.screen.width-100;
      // console.log('https://bpkconnect.com/bpk_connect/api/conference/join_room?token=asdf1234aaa&chat_room_id='+chat.chat_room_id+'&user_id='+this.state.user.user_id);
      window.open('https://bpkconnect.com/bpk_connect/api/conference/join_room?token=asdf1234aaa&chat_room_id='+chat_room_id+'&user_id='+this.state.user.user_id
          , 'Conference', 'toolbar=no,location=no,status=no,titlebar=no,menubar=no,fullscreen=yes,directories=no,top=0,left=0,width='+w +',height=' +h);

      var main = this;
      setTimeout(function(){
        main.updateConferenceRoom(chat_room_id);
      },3000);
    }

    onClickMessage = (chat,e) =>{
        if(chat.stream_id && $.trim(chat.stream_id)!=''){
          e.stopPropagation();
          this.startConference(chat.chat_room_id);
        }
    }

    componentDidMount() {
      // start load chat
      this.load_chat()
      this.props.history.listen((location, action) => {
          if(window.location.pathname.indexOf('/chat/') >= 0) {
              this.load_chat()                
          }
      })
      
      window.addEventListener('message', this.handleWindowMessage, false);
      start_socket_conference();
    }

    componentWillUnmount() {
      window.removeEventListener('message', this.handleWindowMessage, false);
      disconnect_socket_conference();
    }

    componentDidUpdate() {
    }

    handleWindowMessage(event) {
      console.log('handleWindowMessage');
      var type = _.get(event, 'data.type', '');
      if(type=='sendInviteConference'){
        this._pushMessage("New Conference Started, Click to Join.",true)
        // var chat_room_id = _.get(event, 'data.chat_room_id', '');
        // var user_id = _.get(event, 'data.user_id', '');
        // var chat_message_id = _.get(event, 'data.chat_message_id', '');
        // var message = _.get(event, 'data.message', '');
        // if(chat_room_id!=''){
        //   // update message for everyone in group
        //   emit_message(message, chat_room_id, user_id, chat_message_id, '');
        //   // update our own
        //   emit_update_friend_chat_list(user_id, user_id);
        // }
      }
    }

    isBlocked = () => {
        return _.get(this.state.chatInfo, 'is_blocked', '0') == '1'
    }

    isMute = () => {
        return _.get(this.state.chatInfo, 'is_mute', '0') == '1'
    }

    updateData = () => {
      const state = store.getState()
      if(_.get(state, 'chat.sticker')) {
          this.setState({
              sticker: state.chat.sticker || []
          })
      }

      if(_.get(state, 'chat.chat')) {
          this.setState({
              chat: state.chat.chat || []
          }, () => {
                 
          })
      }

      if(_.get(state, 'user.user')) {
          this.setState({
              user: state.user.user
          })
      }

      if(_.get(state, 'system')) {
          this.setState({
              isLoading: _.get(state, 'system.isLoading')
          })
      }

      if(_.get(state, 'chat.memberInGroup.data')) {
          this.setState({
              member: _.get(state, 'chat.memberInGroup.data', []),
          })
      }

      if(_.get(state, 'chat.inviteFriends.data')) {
          this.setState({
              inviteFriends: _.get(state, 'chat.inviteFriends.data', []),
              inviteFriendsCount: _.get(state, 'chat.inviteFriends.totalCount', 0),
          })
      }

      if(_.get(state, 'chat.chatInfo.last_read')) {
          this.setState({
            last_read: this.state.last_read==null? _.get(state, 'chat.chatInfo.last_read', null) : this.state.last_read,
          })
      }

      this.setState({
        is_group_admin: (_.get(state, 'chat.chatInfo.is_group_admin', 'F') && _.get(state, 'chat.chatInfo.system_type', null)==null),
        is_manager: _.get(state, 'chat.chatInfo.is_manager', 'F'),
        is_crm: (_.get(state, 'user.user.is_crm', false) && _.get(state, 'chat.chatInfo.system_type', null)==null),
        is_resolved: _.get(state, 'chat.chatInfo.is_resolved', 'F'),
        is_follow: _.get(state, 'chat.chatInfo.is_follow', 'F'),
        system_type: _.get(state, 'chat.chatInfo.system_type', null),
        can_add_conf: (_.get(state, 'chat.chatInfo.system_type', null)==null &&
          (
            _.get(state, 'user.user.is_crm', false) || 
            (
              _.get(state, 'user.user.user_role', '') == 'helpdesk' && 
              _.get(state, 'chat.chatInfo.chat_room_type', 'N')=='G' && 
              _.get(state, 'chat.chatInfo.user_type_id', null)==null
            )
          )
        ),
        is_show_note: (_.get(state, 'chat.chatInfo.system_type', null)==null &&
          (
            _.get(state, 'user.user.is_crm', false) || 
            (
              _.get(state, 'user.user.user_role', '') == 'helpdesk' && 
              _.get(state, 'chat.chatInfo.chat_room_type', 'N')=='G' && 
              _.get(state, 'chat.chatInfo.user_type_id', null)==null
            )
          )
        ),
        user_type_branchcode: _.get(state, 'chat.chatInfo.user_type_branchcode', null)
      })
    }

    componentWillReceiveProps() {
      this.updateData();
    }

    getChatRoomNotes = async(chat_room_id) => {
        const res = await chatRoomNoteGet(chat_room_id);
        this.setState({
          chat_room_notes: _.get(res, 'data.chat_room_notes', [])
        });
    }

    getChatRoomTags = async(chat_room_id) => {
        var res = await chatRoomTagGet(chat_room_id);
        this.setState({
          chat_room_tags: _.get(res, 'data.chat_room_tags', [])
        });

        var res = await apiGetTagSuggestion();
        var tags = _.get(res, 'data.tags', []);
        var chatTagSuggestions = new Array();
        for(var i in tags){
          chatTagSuggestions.push({'id':tags[i], 'name':tags[i]});
        }

        this.setState({
          chatTagSuggestions
        });
    }

    render_sticker_collection = () => {
        return this.state.sticker.map((item) => {
            return (
                <img src={item.collection_image_url} style={{ width: '90px', padding: '15px', height: '80px', cursor: 'pointer' }}  onClick={() => {
                    this.setState({
                        collectionKeySelected: item.key
                    })
                }} />
            )
        })
    }

    render_sticker = () => {
        return _.get(this.state.sticker, `${this.state.collectionKeySelected}.sticker_lists`, []).map((item) => {
            return (
                <img src={item.url} className="sticker-collection" style={{ width: '145px', padding: '15px', cursor: 'pointer' }} onClick={() => this._pushSticker(item.path, item.url)} />
            )
        })
    }

    _image_upload_handler = (e) => {
      this.setState({
        'showMessageSending': true
      });
      getBase64(e.target.files[0]).then(res => {
          this._pushPhoto(res, res)
      })
    }

    _file_upload_handler = (e) => {
      this.setState({
        'showMessageSending': true
      });
      this._pushFile(e.target.files[0])
        // console.log(e.target.files[0])
    }

    startRecording = () => {
        this.setState({
            record: true,
            status_record: 'start'
        })
        this.timer = setInterval(() => { 
            this.setState({
                timer: this.state.timer + 1
            })
        }, 1000)
    }

    stopRecording = () => {
        this.setState({
            record: false,
            timer: 0,
            status_record: 'stop'
        })
        clearInterval(this.timer)
        this.setState({stoppedRecording: true, recording: false, paused: false})
    }
    
    onData = (recordedBlob) => {
        console.log('chunk of real-time data is: ', recordedBlob);
    }
    
    onStop = (recordedBlob) => {
        this.soundRecord = recordedBlob
        this.setState({
            roundRecording: this.state.roundRecording + 1
        })
    }
    
    loadMoreHistory = () => {
        return new Promise((resolve) => {
            store.dispatch(onLoadMoreMessageLists(this.state.filter))
            resolve('done')
        })
    }

    render_addi_footer = () => {
        if(this.state.footer_selected == 'sticker') {
            return (
                <div style={{ height: 'auto !important', overflowY: 'scroll' }}>
                    <div style={{ overflowX: 'auto', display: 'flex', height: '80px', backgroundColor: 'rgb(251, 251, 251)', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc'}}>
                        {
                            this.render_sticker_collection()
                        }
                    </div>
                    {
                        this.render_sticker()
                    }
                </div>
            )
        } else if(this.state.footer_selected == 'record') {
            return (
                <div style={{ height: 'auto !important', textAlign: 'center', paddingTop: '40px', background: 'rgb(251, 251, 251)' }}>
                  <ReactMic
                    record={this.state.record}
                    className="sound-wave"
                    onStop={this.onStop}
                    onData={this.onData}
                    strokeColor="#000000"
                    backgroundColor="#FF4081" />
                  <button onClick={this.startRecording} type="button">Start</button>
                  <button onClick={this.stopRecording} type="button">Stop</button>
                </div>
            )
        }
    }

    download_file = (e) => {
        e.stopPropagation()
    }

    is_group = () => {
        return _.get(this.state.chatInfo, 'chat_room_type') == 'G' || _.get(this.state.chatInfo, 'chat_room_type') == 'C'
    }

    selected_chat_message = (chat) => {
        if(!this.state.isSelectChatForOpenCase) {
          var showUnsend = false;
          if(this.state.user.username == chat.username && moment(chat.create_date).isAfter(moment().subtract(1, 'days'))){
            showUnsend = true;
          }
          this.setState({
              selected_chat_message_id: chat.chat_message_id,
              show_selected_chat_message: true,
              showUnsendBtn: showUnsend
          })
        }
    }

    render_select_container = () => {

    }

    _resend = () => {
        this.setState({
            showHandleError: false
        }, () => {
            const {
                message,
                index
            } = this.state.selectedMessageError

            const messageLists = this.state.chat
            messageLists.splice(index, 1)
            store.dispatch(chat(messageLists))

            if (message.message_type == '1') {
                this._pushMessage(message.content)
            } else if (message.message_type == '2') {
                this._pushPhoto(message.base64, message.object_url)
            } else if (message.message_type == '3') {
                this._pushAudio()
            } else if (message.message_type == '4') {
                this._pushSticker(message.sticker_path, message.object_url)
            } else if (message.message_type == '5') {
                this._pushFile({
                    uri: message.object_url,
                    fileName: message.file_name,
                    type: message.file_extension
                })
            }
        })
    }

    _deleteErrorMessage = () => {
        this.setState({
            showHandleError: false
        }, () => {
            const {
                message,
                index
            } = this.state.selectedMessageError

            const messageLists = this.state.chat
            messageLists.splice(index, 1)
            store.dispatch(chat(messageLists))
        })
    }
    
    render_message = () => {
        return _.get(this.state, 'chat', []).map((chat, index) => {
            if(!this.state.user || !this.state.chatInfo) {
                return
            }

            if(!chat) return;

            let isStartDate = false;
            if(chat.draft_message_id){
              isStartDate = false;
            }
            else{
              if(!chat.draft_message_id && index < this.state.chat.length - 1 &&
                this.state.chat[index+1] != null && this.state.chat[index+1].create_date &&
                (typeof this.state.chat[index+1].create_date === 'string' || this.state.chat[index+1].create_date instanceof String) &&
                (this.state.chat[index+1].create_date.substring(0,10) != chat.create_date.substring(0,10))
                ){
                isStartDate = true;
              }
              if(index == this.state.chat.length - 1){
                isStartDate = true;
              }
            }

            let isStartUnread = false;
            if(chat.draft_message_id){
              isStartUnread = false;
            }
            else{
              if(!chat.draft_message_id && index < this.state.chat.length -1 &&
                (chat.create_date > this.state.last_read
                  && this.state.chat[index+1] != null && this.state.chat[index+1].create_date
                  && this.state.chat[index+1].create_date <= this.state.last_read)
                ){
                isStartUnread = true;
              }
            }


            let seenMessage = ''
            const reader = chat.who_read.filter((id) => {
                return id != _.get(this.state.user, 'user_id')
            })
            const is_show_avatar = (this.state.chatInfo.chat_room_type == 'G' || this.state.chatInfo.chat_room_type == 'C') && _.get(this.state.user, 'username') != chat.username
            if((this.state.chatInfo.chat_room_type == 'G' || this.state.chatInfo.chat_room_type == 'C') && reader.length != 0) {
                seenMessage = `seen by ${reader.length}`
            } else if(reader.length != 0){
                seenMessage = `seen`
                if(_.get(this.state.user, 'username') != chat.username) {
                    seenMessage = ''
                }
            }
            const class_reciever = is_show_avatar? 'receiver receiver-message' : 'receiver'
            let class_message_text = 'message-text';
            if(chat.stream_id && $.trim(chat.stream_id)!=''){
              class_message_text += ' conference-invite';
            }
            let start_date_text = '';
            if(isStartDate){
              start_date_text = (<div key={chat.chat_message_id+'_date'} style={{textAlign:'center', marginTop:'3px'}}><label className="badge" style={{fontSize:'14px',backgroundColor:'#5bc0de'}}>{chat.create_date.substring(0,10)}</label></div>);
            }

            let start_unread_text = '';
            if(isStartUnread){
              start_unread_text = (<div key={chat.chat_message_idd+'_unread'} style={{textAlign:'center', marginTop:'3px'}}><label className="badge" style={{fontSize:'14px',backgroundColor:'#969696'}}>Unread Below</label></div>);
            }

            /* text message */
            if(chat.is_unsent=='T') {
              var unreadSentBy = 'You';
              if(this.state.user.username != chat.username){
                unreadSentBy = chat.display_name;
              }
              return (<div key={chat.chat_message_id} style={{textAlign:'center'}}><label className="badge" style={{fontSize:'12px',backgroundColor:'#969696',fontWeight:'normal'}}>{unreadSentBy} unsent the message.</label></div>)
            }
            else if(chat.message_type == '1') {
                return [start_unread_text,start_date_text,
                    <div key={chat.chat_message_id} className="row message-body" style={{ marginRight: '10px' }} onClick={(e) => {
                      if(e.target.classList.contains('display-name') || e.target.classList.contains('avatar-img')){
                        this.showProfileModal(e,chat)
                        return;
                      }
                      if(!_.get(chat, 'isError', false)) {
                          if(this.state.footer_selected) {
                              this.setState({
                                  footer_selected: ''
                              })
                          } else if(e.target.classList.contains('fa-check-circle')) {
                              this.selected_chat_message(chat)
                          }
                      }
                    }}>
                        {
                            !this.state.isSelectChatForOpenCase && !_.get(chat, 'isError', false) && <div className={ this.state.selected_chat_message_id == chat.chat_message_id ? "hide" : "disapear-selected-message" }/>
                        }
                        {
                            !this.state.isSelectChatForOpenCase && !_.get(chat, 'isError', false) && <i className={ this.state.selected_chat_message_id == chat.chat_message_id ? "fa fa-check-circle selected-message" : "fa fa-check-circle draft-selected-message" } aria-hidden="true" style={{ fontSize: '28px', marginLeft: '15px', color: 'rgb(200, 200, 200)' }}></i>    
                        }
                        {
                            this.state.isSelectChatForOpenCase && <Checkbox onChange={(event) => {
                                const selectedOptionMessageId = {
                                    [chat.chat_message_id]: event.target.checked
                                }
                                this.setState({
                                    selectedOptionMessageId: Object.assign(this.state.selectedOptionMessageId, selectedOptionMessageId, {})
                                })
                            }} />
                        }
                        <div className={ (this.state.user.username == chat.username ? "col-sm-12 message-main-sender": "col-sm-12 message-main-receiver")+(chat.is_user_deactivated=='1'? ' deactivated': '') }>
                            
                            <div className={ is_show_avatar? 'avatar-icon': 'hide' }   style={{ width: '40px' }}>
                                <span className={ chat.is_user_deactivated=='1'? 'avatar-img deactivated': 'avatar-img' } style={{backgroundImage:`url(${chat.profile_pic_url})`, width: '38px', height: '38px'}}></span>
                            </div>
                            <span className={ this.state.user.username == chat.username ? "message-time" : "hide" } >
                                <i className={ _.get(chat, 'isError', false)? 'fa fa-exclamation-circle' : 'hide' } aria-hidden="true" style={{ fontSize: '30px', cursor: 'pointer' }} onClick={(e) => {
                                    e.stopPropagation()
                                    this.setState({
                                        showHandleError: true,
                                        selectedMessageError: {
                                            message: chat,
                                            index: index
                                        }
                                    })
                                }}></i>
                                <span className={ _.get(chat, 'isError')? 'hide' : '' }>{ chat.create_date != null && chat.create_date.length >= 16 && chat.create_date.substring(11,16) }</span>
                                <span className={ seenMessage? 'show': 'hide' }><br/>{ seenMessage }</span>
                            </span>
                            {
                              is_show_avatar &&
                              <span className={ (this.state.user.username == chat.username? 'hide': 'show') + ' display-name' } style={{position:'absolute',top:'-1px',left:'55px',fontSize:'12px'}}>{chat.display_name}</span>
                            }
                            {
                              !is_show_avatar &&
                              <span className={ this.state.user.username == chat.username? 'hide': 'show' + ' display-name' } style={{position:'absolute',top:'-1px',fontSize:'12px'}}>{chat.display_name}</span>
                            }
                            <div className={ this.state.user.username == chat.username ? "sender": class_reciever }>
                                <div className={class_message_text} onClick={(e) => this.onClickMessage(chat,e) }>
                                  <Linkify
                                      componentDecorator={(decoratedHref, decoratedText, key) => (
                                          <a target="blank" href={decoratedHref} key={key}>
                                              {decoratedText}
                                          </a>
                                      )}
                                  >
                                    { chat.content }
                                  </Linkify>
                                </div>
                            </div>
                            <span className={ this.state.user.username != chat.username ? "message-time message-time-receiver" : "hide" } style={{ width: '75px', textAlign: 'left' }}>
                                    { chat.create_date != null && chat.create_date.length >= 16 && chat.create_date.substring(11,16) }
                                    <span className={ 1==0? 'show': 'hide' }><br/>{ seenMessage }</span>
                            </span>
                        </div>
                    </div>
                ]
            }

             /* image message */
            if(chat.message_type == '2') {
                return [start_unread_text,start_date_text,
                    <div key={chat.chat_message_id} className="row message-body" onClick={(e) => {
                      if(e.target.classList.contains('display-name') || e.target.classList.contains('avatar-img')){
                        this.showProfileModal(e,chat)
                        return;
                      }
                      if(!_.get(chat, 'isError', false)) {
                          if(this.state.footer_selected) {
                              this.setState({
                                  footer_selected: ''
                              })
                          } else if(e.target.classList.contains('fa-check-circle')) {
                              this.selected_chat_message(chat)
                          }
                      }
                    }}>
                        {
                            !this.state.isSelectChatForOpenCase && !_.get(chat, 'isError', false) && <div className={ this.state.selected_chat_message_id == chat.chat_message_id ? "hide" : "disapear-selected-message" }/>
                        }
                        {
                            !this.state.isSelectChatForOpenCase && !_.get(chat, 'isError', false) && <i className={ this.state.selected_chat_message_id == chat.chat_message_id ? "fa fa-check-circle selected-message" : "fa fa-check-circle draft-selected-message" } aria-hidden="true" style={{ fontSize: '28px', marginLeft: '15px', color: 'rgb(200, 200, 200)' }}></i>    
                        }
                        {
                            this.state.isSelectChatForOpenCase && <Checkbox onChange={(event) => {
                                const selectedOptionMessageId = {
                                    [chat.chat_message_id]: event.target.checked
                                }
                                this.setState({
                                    selectedOptionMessageId: Object.assign(this.state.selectedOptionMessageId, selectedOptionMessageId, {})
                                })
                            }} />
                        }
                        <div className={ (this.state.user.username == chat.username ? "col-sm-12 message-main-sender": "col-sm-12 message-main-receiver")+(chat.is_user_deactivated=='1'? ' deactivated': '') }>
                            <div className={ is_show_avatar? 'avatar-icon': 'hide' }   style={{ width: '40px' }}>
                                <span className={ chat.is_user_deactivated=='1'? 'avatar-img deactivated': 'avatar-img' } style={{backgroundImage:`url(${chat.profile_pic_url})`, width: '38px', height: '38px'}}></span>
                            </div>
                            <span className={ this.state.user.username == chat.username ? "message-time" : "hide" } >
                                <i className={ _.get(chat, 'isError', false)? 'fa fa-exclamation-circle' : 'hide' } aria-hidden="true" style={{ fontSize: '30px', cursor: 'pointer' }} onClick={(e) => {
                                    e.stopPropagation()
                                    this.setState({
                                        showHandleError: true,
                                        selectedMessageError: {
                                            message: chat,
                                            index: index
                                        }
                                    })
                                }}></i>
                                <span className={ _.get(chat, 'isError')? 'hide' : '' }>{ chat.create_date != null && chat.create_date.length >= 16 && chat.create_date.substring(11,16) }</span>
                                <span className={ seenMessage? 'show': 'hide' }><br/>{ seenMessage }</span>
                            </span>
                            {
                              is_show_avatar &&
                              <span className={ (this.state.user.username == chat.username? 'hide': 'show') + ' display-name' } style={{position:'absolute',top:'-1px',left:'55px',fontSize:'12px'}}>{chat.display_name}</span>
                            }
                            {
                              !is_show_avatar &&
                              <span className={ this.state.user.username == chat.username? 'hide': 'show' + ' display-name' } style={{position:'absolute',top:'-1px',fontSize:'12px'}}>{chat.display_name}</span>
                            }
                            <div className={ this.state.user.username == chat.username ? "sender background-transparent": "receiver background-transparent" }>
                                <img src={ chat.object_url } style={{ maxWidth: '200px', maxHeight: '200px', cursor:'pointer' }} onClick={(e) => {
                                    e.stopPropagation()
                                    this.setState({ selected_image: chat.object_url })
                                }}  />
                            </div>
                            <span className={ this.state.user.username != chat.username ? "message-time" : "hide" } style={{ width: '75px', textAlign: 'left' }}>
                                { chat.create_date != null && chat.create_date.length >= 16 && chat.create_date.substring(11,16) }
                                <span className={ 1==0? 'show': 'hide' }><br/>{ seenMessage }</span>
                            </span>
                        </div>
                    </div>
                ]
            }

            /* audio player message */
            if(chat.message_type == '3') {
                return [start_unread_text,start_date_text,
                    <div key={chat.chat_message_id} className="row message-body" onClick={(e) => {
                      if(e.target.classList.contains('display-name') || e.target.classList.contains('avatar-img')){
                        this.showProfileModal(e,chat)
                        return;
                      }
                      if(!_.get(chat, 'isError', false)) {
                          if(this.state.footer_selected) {
                              this.setState({
                                  footer_selected: ''
                              })
                          } else if(e.target.classList.contains('fa-check-circle')) {
                              this.selected_chat_message(chat)
                          }
                      }
                    }}>
                        {
                            !this.state.isSelectChatForOpenCase && !_.get(chat, 'isError', false) && <div className={ this.state.selected_chat_message_id == chat.chat_message_id ? "hide" : "disapear-selected-message" }/>
                        }
                        {
                            !this.state.isSelectChatForOpenCase && !_.get(chat, 'isError', false) && <i className={ this.state.selected_chat_message_id == chat.chat_message_id ? "fa fa-check-circle selected-message" : "fa fa-check-circle draft-selected-message" } aria-hidden="true" style={{ fontSize: '28px', marginLeft: '15px', color: 'rgb(200, 200, 200)' }}></i>    
                        }
                        {
                            this.state.isSelectChatForOpenCase && <Checkbox onChange={(event) => {
                                const selectedOptionMessageId = {
                                    [chat.chat_message_id]: event.target.checked
                                }
                                this.setState({
                                    selectedOptionMessageId: Object.assign(this.state.selectedOptionMessageId, selectedOptionMessageId, {})
                                })
                            }} />
                        }
                        <div className={ (this.state.user.username == chat.username ? "col-sm-12 message-main-sender": "col-sm-12 message-main-receiver")+(chat.is_user_deactivated=='1'? ' deactivated': '') }>
                            <div className={ is_show_avatar? 'avatar-icon': 'hide' }   style={{ width: '40px' }}>
                                <span className={ chat.is_user_deactivated=='1'? 'avatar-img deactivated': 'avatar-img' } style={{backgroundImage:`url(${chat.profile_pic_url})`, width: '38px', height: '38px'}}></span>
                            </div>
                            <span className={ this.state.user.username == chat.username ? "message-time" : "hide" } >
                                <i className={ _.get(chat, 'isError', false)? 'fa fa-exclamation-circle' : 'hide' } aria-hidden="true" style={{ fontSize: '30px', cursor: 'pointer' }} onClick={(e) => {
                                    e.stopPropagation()
                                    this.setState({
                                        showHandleError: true,
                                        selectedMessageError: {
                                            message: chat,
                                            index: index
                                        }
                                    })
                                }}></i>
                                <span className={ _.get(chat, 'isError')? 'hide' : '' }>{ chat.create_date != null && chat.create_date.length >= 16 && chat.create_date.substring(11,16) }</span>
                                <span className={ seenMessage? 'show': 'hide' }><br/>{ seenMessage }</span>
                            </span>
                            {
                              is_show_avatar &&
                              <span className={ (this.state.user.username == chat.username? 'hide': 'show') + ' display-name' } style={{position:'absolute',top:'-1px',left:'55px',fontSize:'12px'}}>{chat.display_name}</span>
                            }
                            {
                              !is_show_avatar &&
                              <span className={ this.state.user.username == chat.username? 'hide': 'show' + ' display-name' } style={{position:'absolute',top:'-1px',fontSize:'12px'}}>{chat.display_name}</span>
                            }
                            <div className={ this.state.user.username == chat.username ? "sender background-transparent audio-right": "receiver background-transparent audio-left" }>
                                <AudioPlayer src={chat.object_url} />
                            </div>
                            <span className={ this.state.user.username != chat.username ? "message-time" : "hide" } style={{ width: '75px', textAlign: 'left' }}>
                                { chat.create_date != null && chat.create_date.length >= 16 && chat.create_date.substring(11,16) }
                                <span className={ 1==0? 'show': 'hide' }><br/>{ seenMessage }</span>
                            </span>
                        </div>
                        
                    </div>
                ]
            }

             /* sticker message */
            if(chat.message_type == '4') {
                return [start_unread_text,start_date_text,
                    <div key={chat.chat_message_id} className="row message-body" onClick={(e) => {
                      if(e.target.classList.contains('display-name') || e.target.classList.contains('avatar-img')){
                        this.showProfileModal(e,chat)
                        return;
                      }
                      if(!_.get(chat, 'isError', false)) {
                          if(this.state.footer_selected) {
                              this.setState({
                                  footer_selected: ''
                              })
                          } else if(e.target.classList.contains('fa-check-circle')) {
                              this.selected_chat_message(chat)
                          }
                      }
                    }}>
                        {
                            !this.state.isSelectChatForOpenCase && !_.get(chat, 'isError', false) && <div className={ this.state.selected_chat_message_id == chat.chat_message_id ? "hide" : "disapear-selected-message" }/>
                        }
                        {
                            !this.state.isSelectChatForOpenCase && !_.get(chat, 'isError', false) && <i className={ this.state.selected_chat_message_id == chat.chat_message_id ? "fa fa-check-circle selected-message" : "fa fa-check-circle draft-selected-message" } aria-hidden="true" style={{ fontSize: '28px', marginLeft: '15px', color: 'rgb(200, 200, 200)' }}></i>    
                        }
                        {
                            this.state.isSelectChatForOpenCase && <Checkbox onChange={(event) => {
                                const selectedOptionMessageId = {
                                    [chat.chat_message_id]: event.target.checked
                                }
                                this.setState({
                                    selectedOptionMessageId: Object.assign(this.state.selectedOptionMessageId, selectedOptionMessageId, {})
                                })
                            }} />
                        }
                        <div className={ (this.state.user.username == chat.username ? "col-sm-12 message-main-sender": "col-sm-12 message-main-receiver")+(chat.is_user_deactivated=='1'? ' deactivated': '') }>
                            <div className={ is_show_avatar? 'avatar-icon': 'hide' }   style={{ width: '40px' }}>
                                <span className={ chat.is_user_deactivated=='1'? 'avatar-img deactivated': 'avatar-img' } style={{backgroundImage:`url(${chat.profile_pic_url})`, width: '38px', height: '38px'}}></span>
                            </div>
                            <span className={ this.state.user.username == chat.username ? "message-time" : "hide" } >
                                <i className={ _.get(chat, 'isError', false)? 'fa fa-exclamation-circle' : 'hide' } aria-hidden="true" style={{ fontSize: '30px', cursor: 'pointer' }} onClick={(e) => {
                                    e.stopPropagation()
                                    this.setState({
                                        showHandleError: true,
                                        selectedMessageError: {
                                            message: chat,
                                            index: index
                                        }
                                    })
                                }}></i>
                                <span className={ _.get(chat, 'isError')? 'hide' : '' }>{ chat.create_date != null && chat.create_date.length >= 16 && chat.create_date.substring(11,16) }</span>
                                <span className={ seenMessage? 'show': 'hide' }><br/>{ seenMessage }</span>
                            </span>
                            {
                              is_show_avatar &&
                              <span className={ (this.state.user.username == chat.username? 'hide': 'show') + ' display-name' } style={{position:'absolute',top:'-1px',left:'55px',fontSize:'12px'}}>{chat.display_name}</span>
                            }
                            {
                              !is_show_avatar &&
                              <span className={ this.state.user.username == chat.username? 'hide': 'show' + ' display-name' } style={{position:'absolute',top:'-1px',fontSize:'12px'}}>{chat.display_name}</span>
                            }
                            <div className={ this.state.user.username == chat.username ? "sender background-transparent sticker-right ": " receiver background-transparent sticker-left" } onClick={(e) => e.stopPropagation() }>
                                <img className="sticker" src={ chat.object_url } style={{ width: '150px' }} />
                            </div>
                            <span className={ this.state.user.username != chat.username ? "message-time" : "hide" } style={{ width: '75px', textAlign: 'left' }}>
                                { chat.create_date != null && chat.create_date.length >= 16 && chat.create_date.substring(11,16) }
                                <span className={ 1==0 ? 'show': 'hide' }><br/>{ seenMessage }</span>
                            </span>
                        </div>
                    </div>
                ]
            }

             /* file message */
            if(chat.message_type == '5') {
                return [start_unread_text,start_date_text,
                    <div key={chat.chat_message_id} className="row message-body" onClick={(e) => {
                      if(e.target.classList.contains('display-name') || e.target.classList.contains('avatar-img')){
                        this.showProfileModal(e,chat)
                        return;
                      }
                      if(!_.get(chat, 'isError', false)) {
                          if(this.state.footer_selected) {
                              this.setState({
                                  footer_selected: ''
                              })
                          } else if(e.target.classList.contains('fa-check-circle')) {
                              this.selected_chat_message(chat)
                          }
                      }
                    }}>
                        {
                            !this.state.isSelectChatForOpenCase && !_.get(chat, 'isError', false) && <div className={ this.state.selected_chat_message_id == chat.chat_message_id ? "hide" : "disapear-selected-message" }/>
                        }
                        {
                            !this.state.isSelectChatForOpenCase && !_.get(chat, 'isError', false) && <i className={ this.state.selected_chat_message_id == chat.chat_message_id ? "fa fa-check-circle selected-message" : "fa fa-check-circle draft-selected-message" } aria-hidden="true" style={{ fontSize: '28px', marginLeft: '15px', color: 'rgb(200, 200, 200)' }}></i>    
                        }
                        {
                            this.state.isSelectChatForOpenCase && <Checkbox onChange={(event) => {
                                const selectedOptionMessageId = {
                                    [chat.chat_message_id]: event.target.checked
                                }
                                this.setState({
                                    selectedOptionMessageId: Object.assign(this.state.selectedOptionMessageId, selectedOptionMessageId, {})
                                })
                            }} />
                        }
                        <div className={ (this.state.user.username == chat.username ? "col-sm-12 message-main-sender": "col-sm-12 message-main-receiver")+(chat.is_user_deactivated=='1'? ' deactivated': '') }>
                            <div className={ is_show_avatar? 'avatar-icon': 'hide' }   style={{ width: '40px' }}>
                                <span className={ chat.is_user_deactivated=='1'? 'avatar-img deactivated': 'avatar-img' } style={{backgroundImage:`url(${chat.profile_pic_url})`, width: '38px', height: '38px'}}></span>
                            </div>
                            <span className={ this.state.user.username == chat.username ? "message-time" : "hide" } >
                                <i className={ _.get(chat, 'isError', false)? 'fa fa-exclamation-circle' : 'hide' } aria-hidden="true" style={{ fontSize: '30px', cursor: 'pointer' }} onClick={(e) => {
                                    e.stopPropagation()
                                    this.setState({
                                        showHandleError: true,
                                        selectedMessageError: {
                                            message: chat,
                                            index: index
                                        }
                                    })
                                }}></i>
                                <span className={ _.get(chat, 'isError')? 'hide' : '' }>{ chat.create_date != null && chat.create_date.length >= 16 && chat.create_date.substring(11,16) }</span>
                                <span className={ seenMessage? 'show': 'hide' }><br/>{ seenMessage }</span>
                            </span>
                            {
                              is_show_avatar &&
                              <span className={ (this.state.user.username == chat.username? 'hide': 'show') + ' display-name' } style={{position:'absolute',top:'-1px',left:'55px',fontSize:'12px'}}>{chat.display_name}</span>
                            }
                            {
                              !is_show_avatar &&
                              <span className={ this.state.user.username == chat.username? 'hide': 'show' + ' display-name' } style={{position:'absolute',top:'-1px',fontSize:'12px'}}>{chat.display_name}</span>
                            }
                            <a className={ this.state.user.username == chat.username ? "sender": "receiver"} style={{ height: '64px', padding: '11px' }} href={chat.file_download_url} download target="_blank" onClick={(e) => this.download_file(e) }>
                               
                                <div style={{ display: 'flex', cursor: 'pointer' }}>
                                    <i className="fa fa-file" aria-hidden="true" 
                                    style={{ fontSize: '25px', color: '#3a6d99', padding: '5px', textAlign: 'center'}}></i>
                                    <div style={{ paddingLeft: '12px' }}>
                                        <p style={{ margin: '0px', fontWeight: 'bold', color: '#3a6d99', whiteSpace: 'nowrap', textAlign: 'left' }}>{ chat.file_name }</p>
                                        <p style={{ margin: '0px', color: '#3a6d99', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflowY: 'hidden' }}>Download</p>
                                    </div>
                                </div>
                                
                            </a>
                            <span className={ this.state.user.username != chat.username ? "message-time" : "hide" } style={{ width: '75px', textAlign: 'left' }}>
                                { chat.create_date != null && chat.create_date.length >= 16 && chat.create_date.substring(11,16) }
                                <span className={ 1==0? 'show': 'hide' }><br/>{ seenMessage }</span>
                            </span>
                        </div>
                    </div>
                ]
            }
        })
    }

    onSearchMessage = (e) => {
        if(e) {
            e.preventDefault()
        }
        store.dispatch(onFetchMessageLists(this.state.filter))
        // this.setState({ show_search_input: false })
    }

    closeSearch = () => {
        this.setState({
            show_search_input : false,
            filter: ''
        }, () => {
            store.dispatch(onFetchMessageLists(''))
        })
    }

    openCamera = () => {
        var video = document.getElementById('video')
        
        // Get access to the camera!
        if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            // Not adding `{ audio: true }` since we only want video now
            navigator.mediaDevices.getUserMedia({ video: true }).then(function(stream) {
                // video.src = window.URL.createObjectURL(stream)
                video.srcObject = stream;
                video.play()
            })
        }
    }
    
    takePhoto = () => {
        var canvas = document.getElementById('canvas')
        var context = canvas.getContext('2d')
        var video = document.getElementById('video')
        context.drawImage(video, 0, 0, 640, 480)
        this.setState({
            alreadyTaken: true
        }, () => {
            this.base64 = canvas.toDataURL("image/jpeg")
        })
    }

    onSearchInviteFriend = (e) => {
        e.preventDefault()
        store.dispatch(onFetchInviteFriend(this.state.inviteFilter))
    }

    editChatRoomNote = (e, chat_room_note_id) => {
      e.preventDefault()
      let chatRoomNotes = this.state.chat_room_notes.slice();
      for(var i in chatRoomNotes){
        if(chatRoomNotes[i].chat_room_note_id == chat_room_note_id){
          chatRoomNotes[i].is_editing = true;
          break;
        }
      }
      this.setState({'chat_room_notes':chatRoomNotes});
    }

    editChatRoomNoteUpdate = (e, chat_room_note_id) => {
      e.preventDefault()
      let chatRoomNotes = this.state.chat_room_notes.slice();
      for(var i in chatRoomNotes){
        if(chatRoomNotes[i].chat_room_note_id == chat_room_note_id){
          chatRoomNotes[i].content = e.target.value;
          break;
        }
      }
      this.setState({'chat_room_notes':chatRoomNotes});
    }

    editChatRoomNoteConfirm = async(e, chat_room_note_id) => {
      e.preventDefault()
      let chatRoomNotes = this.state.chat_room_notes.slice();
      for(var i in chatRoomNotes){
        if(chatRoomNotes[i].chat_room_note_id == chat_room_note_id){
          chatRoomNotes[i].is_editing = false;
          let data = chatRoomNotes[i];
          data.chat_room_id = this.state.chatInfo.chat_room_id;
          data.update_by = this.state.user.user_id;
          const res = await chatRoomNoteSave(data);
          if(res.data.error!=''){
            alert(res.data.error);
          }
          else{
            chatRoomNotes[i] = res.data.chat_room_note;
          }
          break;
        }
      }
      this.setState({'chat_room_notes':chatRoomNotes});
    }

    deleteChatRoomNote = (e, chat_room_note_id) => {
      e.preventDefault()
      let chatRoomNotes = this.state.chat_room_notes.slice();
      for(var i in chatRoomNotes){
        if(chatRoomNotes[i].chat_room_note_id == chat_room_note_id){
          chatRoomNotes[i].is_deleting = true;
          break;
        }
      }
      this.setState({'chat_room_notes':chatRoomNotes});
    }

    deleteChatRoomNoteConfirm = async(e, chat_room_note_id, is_confirm) => {
      e.preventDefault()
      let chatRoomNotes = this.state.chat_room_notes.slice();
      for(var i in chatRoomNotes){
        if(chatRoomNotes[i].chat_room_note_id == chat_room_note_id){
          chatRoomNotes[i].is_deleting = false;   
          if(is_confirm){
            chatRoomNotes.splice(i, 1);
            const res = await chatRoomNoteDelete(chat_room_note_id);
            if(res.data.error!=''){
              alert(res.data.error);
            }
          }
          break;       
        }
      }
      this.setState({'chat_room_notes':chatRoomNotes});
    }

    addChatRoomNote = (e) => {
      e.preventDefault()
      let chatRoomNotes = this.state.chat_room_notes.slice();
      var newId = 0;
      if(chatRoomNotes.length>0){
        var lastId = chatRoomNotes[chatRoomNotes.length-1].chat_room_note_id;
        if(lastId>0) newId = -1;
        else newId = lastId-1;
      }
      else{
        newId=-1;
      }
      chatRoomNotes.push(
        {chat_room_note_id:newId, content:'', update_date:null, update_by_name:null, is_editing: true, is_deleting: false}
      );
      this.setState({'chat_room_notes':chatRoomNotes});
    }


    editOutsideCustomerName = async(e) => {
      e.preventDefault()
      var data = {
        chat_room_id : this.state.chatInfo.chat_room_id,
        outside_customer : this.state.outside_customer,
      }
      const res = await chatRoomUpdateOutsideCustomer(data);
      if(res.data.error!=''){
        alert(res.data.error);
      }
      else{
        var chatInfo = this.state.chatInfo;
        chatInfo.display_name = "(Conf.)"+ this.state.outside_customer;
        this.setState({
          'chatInfo': chatInfo
        })
      }
    }

    onClickShowSearchPatientModal = async(searchFor, e) => {
      e.preventDefault()
      e.stopPropagation()

      var hos_code = '900';
      if(this.state.user_type_branchcode!=null && this.state.user_type_branchcode!=''){
        hos_code = this.state.user_type_branchcode;
      }

      this.setState({
        searchPatientLists: [],
        searchPatientFor: searchFor,
        searchPatientListShowMore: true,
        searchPatientListStart: 0,
        search_patient_hos_code: hos_code,
        search_patient_hn: '',
        search_patient_pid: '',
        search_patient_passport_no: '',
        search_patient_firstname: '',
        search_patient_lastname: '',
        selectedSearchPatientId: '',
        isShowSearchPatientModal: true
      });
    }

    onClickSearchPatientList = async(e,showMore) => {
      e.preventDefault()
      e.stopPropagation()

      var hos_code = this.state.search_patient_hos_code;
      var hn = this.state.search_patient_hn;
      var pid = this.state.search_patient_pid;
      var passport_no = this.state.search_patient_passport_no;
      var firstname = this.state.search_patient_firstname;
      var lastname = this.state.search_patient_lastname;
      var start = this.state.searchPatientListStart;
      if(showMore){
        start += 50;
      }
      else{
        start = 0;
      }

      store.dispatch(isLoading(true))
      var res = await apiGetSearchPatientList(hos_code,hn,pid,passport_no,firstname,lastname,start);
      store.dispatch(isLoading(false))
      var newList = _.get(res, 'data.patients', []);
      if(showMore){
        newList = this.state.searchPatientLists.concat(_.get(res, 'data.patients', []));
      }
      this.setState({
        searchPatientLists: newList,
        searchPatientListStart: start
      });
    }

    onClickSearchPatient = async(e, info) => {
      e.preventDefault()
      e.stopPropagation()

      var patient_id = info.patient_id;
      var hn = info.hn;

      if(this.state.searchPatientFor=='COVID19_RESULT'){
        store.dispatch(isLoading(true))
        var res = await apiGetCovidResultList(this.state.search_patient_hos_code, patient_id, hn);
        var covidResults = _.get(res, 'data.result_list', []);
        for(var i in covidResults){
          covidResults[i].url = URL.createObjectURL(this.b64toBlob(covidResults[i].base64, 'application/pdf'));
        }

        store.dispatch(isLoading(false))
        this.setState({
          isShowSearchPatientModal: false,
          selectedSearchPatientId: patient_id,
          isShowSearchCovidResultModal: true,
          searchCovidResultList: covidResults,
        });
      }
    }

    onClickSendCovidResult = async(e) => {
      e.preventDefault()
      e.stopPropagation()

      var resultList = this.state.searchCovidResultList;
      for(var i in resultList){
        var result = resultList[i];
        var elem = document.getElementById('covidResultId_'+result.list_id);
        if(elem && elem.checked){
          await this._pushFileBase64(result.base64,result.file_name,result.file_type);
          var res = apiAddCovidResultLog(this.state.search_patient_hos_code,result.visit_id
            ,result.doc_type,result.file_name,this.state.chatInfo.chat_room_id);
        }
      }

      this.setState({
        isShowSearchCovidResultModal: false
      });
    }

    onClickShowQuickReply = async(e) => {
      e.preventDefault()
      e.stopPropagation()

      var filterQuickReplyText = '';
      store.dispatch(isLoading(true))
      var res = await apiGetQuickReplyList(filterQuickReplyText);
      store.dispatch(isLoading(false))
      this.setState({
        quickReplyLists: _.get(res, 'data.quick_reply_list', []),
        isShowQuickReplyModal: true
      });
    }

    onClickQuickReply = async(e, info) => {
      e.preventDefault()
      e.stopPropagation()

      this._pushMessage(info.text);

      this.setState({
        isShowQuickReplyModal: false
      });
    }

    renderChatRoomNotes = () => {
        return this.state.chat_room_notes.map((item) => {
            return (
                <div className="chat-note" key={item.chat_room_note_id}>
                  <textarea className="form-control" rows="3" autoFocus={item.chat_room_note_id==-1?true:false} disabled={item.is_editing?false:true} value={item.content} onChange={(e) => this.editChatRoomNoteUpdate(e,item.chat_room_note_id)} />
                  <div className="clearfix"></div>
                  <div>
                    {
                      item.is_deleting ?
                      <div style={{float:'left', width:'auto'}}>
                      <b>Confirm Remove? </b>
                      <a href="#" chat_room_note_id={item.chat_room_note_id} onClick={(e) => this.deleteChatRoomNoteConfirm(e,item.chat_room_note_id,true)}>Yes</a>
                      <a href="#" chat_room_note_id={item.chat_room_note_id} onClick={(e) => this.deleteChatRoomNoteConfirm(e,item.chat_room_note_id,false)} style={{marginLeft:'5px'}}>No</a>
                      </div>
                      :
                      <div style={{float:'left', width:'200px'}}>
                      <span className="chat-note-time">{item.update_date==null?'':item.update_date.substring(0,10)}, {item.update_date==null?'':item.update_date.substring(11,16)}</span>
                      <span className="chat-note-by">{item.update_by_name}</span>
                      </div>
                    }
                    {
                      this.state.user.user_id==item.update_by &&
                      <a href="#" chat_room_note_id={item.chat_room_note_id} className="chat-note-remove" onClick={(e) => this.deleteChatRoomNote(e,item.chat_room_note_id)}><i className="fa fa-trash" style={{  }}></i></a>
                    }
                    {
                      item.is_editing ?
                      <a href="#" chat_room_note_id={item.chat_room_note_id} className="chat-note-edit" onClick={(e) => this.editChatRoomNoteConfirm(e,item.chat_room_note_id)}><i className="fa fa-check" style={{  }}></i></a>
                      :
                      ( 
                      this.state.user.user_id==item.update_by &&
                      <a href="#" chat_room_note_id={item.chat_room_note_id} className="chat-note-edit" onClick={(e) => this.editChatRoomNote(e,item.chat_room_note_id)}><i className="fa fa-pencil" style={{  }}></i></a>
                      )
                    }
                  </div>
                  <div className="clearfix"></div>
                </div>
            )
        })
    }

    render() {
        if(!this.state.user) return '';
        return (
          <div className="col-sm-7" style={{padding:0, paddingLeft:'1px'}}>
              <div className="row heading header-chat" style={{ backgroundColor: '#3b5998', display: 'flex' }}>
                  <a href="#" className="pc-hide" style={{ marginRight: '10px', width: 'auto', marginTop: '6px' }}  onClick={() => this.props.history.push('/')}>
                      <i className="fa fa-arrow-left fa-lg " style={{ color: 'white', padding: '10px' }}></i>
                  </a>
                  <div className="heading-avatar " style={{ width: 'auto' }}>
                    <div className="heading-avatar-icon avatar-icon">
                      <div className="avatar-img" style={{width:'40px', height:'40px', backgroundColor:'white', backgroundImage:`url(${ _.get(this.state.chatInfo, 'profile_pic_url')})`}}></div>
                    </div>
                  </div>
                  <div className={ this.state.show_search_input? 'heading-name mobile-hide' : 'heading-name'} style={{ width: 'auto', flex: '1', display: 'inline-block', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} onClick={() => {
                      if( _.get(this.state.chatInfo, 'display_name') != 'KEEP' ) {
                          // this.setState({
                          //     showContactInfo: true
                          // }, () => {
                          //     store.dispatch(onFetchInviteFriend())
                          // })
                      }
                  }}>
                      <a className="heading-name-meta" style={{ cursor:'default', color: 'white', display: 'inline-block', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow:'hidden' }}>{ _.get(this.state.chatInfo, 'display_name') }
                      </a>
                  </div>
                  <div className={ !this.state.show_search_input ? 'heading-dot pull-right' : 'hide' } style={{ position: 'absolute', right:'5px', height:'auto', width:'auto' }}>
                      <i title="Setting" className="fa fa-cog fa-lg  pull-right" style={{ color: 'white', padding: '15px', paddingRight: '5px' }} aria-hidden="true" onClick={() => {
                          if( _.get(this.state.chatInfo, 'display_name') != 'KEEP' ) {
                              this.setState({
                                  showContactInfo: true
                              }, () => {
                                  store.dispatch(onFetchInviteFriend())
                              })
                          }
                      }}></i>
                      {
                        (_.get(this.state.chatInfo,'is_conference','F')=='T') &&
                        <>
                        <i title="Start Conference" className="fa fa-tv fa-lg  pull-right" style={{ color: 'white', padding: '15px', paddingRight: '5px' }} aria-hidden="true" onClick={() => this.startConference(this.state.chatInfo.chat_room_id) }></i>
                        {
                          this.state.conferenceCount>0 && <span className="badge" style={{position:'absolute',width: 'auto',height:'auto',right:'50px',backgroundColor:'red'}}>{this.state.conferenceCount}</span>
                        }
                        </>
                      }
                      <i title="Search" className="fa fa-search fa-lg  pull-right" style={{ color: 'white', padding: '15px', paddingRight: '5px' }} aria-hidden="true" onClick={() => this.setState({ show_search_input: true }) }></i>
                      {
                        (this.state.is_crm || this.state.can_add_conf) &&
                        <span id="btn-group-crm">
                        {
                          this.state.is_crm &&
                          <>
                          {
                            this.state.is_resolved=='F' &&
                            <Button bsStyle="warning" bsSize="small" className="pull-right" style={{ marginTop: '5px' }} onClick={() => this.onClickChatRoomResolve('T')}>
                              <i className="fa fa-check-circle fa-lg" style={{ color: 'white' }}></i>
                              Resolve
                            </Button>
                          }
                          {
                            (this.state.is_resolved=='T' || this.state.is_resolved==null) &&
                            <Button bsStyle="danger" bsSize="small" className="pull-right" style={{ marginTop: '5px' }} onClick={() => this.onClickChatRoomResolve('F')}>
                              <i className="fa fa-times-circle fa-lg" style={{ color: 'white' }}></i>
                              Unresolve
                            </Button>
                          }
                          {
                            this.state.is_follow=='F' &&
                            <Button bsStyle="success" bsSize="small" className="pull-right" style={{ marginTop: '5px', marginRight: '5px' }} onClick={() => this.onClickChatRoomFollow('T')}>
                              <i className="fa fa-plus fa-lg" style={{ color: 'white' }}></i>
                              Follow
                            </Button>
                          }
                          {
                            this.state.is_follow=='T' &&
                            <Button bsStyle="danger" bsSize="small" className="pull-right" style={{ marginTop: '5px', marginRight: '5px' }} onClick={() => this.onClickChatRoomFollow('F')}>
                              <i className="fa fa-minus fa-lg" style={{ color: 'white' }}></i>
                              Unfollow
                            </Button>
                          }
                          </>
                        }
                        {
                          this.state.can_add_conf &&
                          <>
                          {
                            (_.get(this.state.chatInfo,'is_conference','F')!='T') &&
                            <Button bsStyle="info" bsSize="small" className="pull-right" style={{ marginTop: '5px', marginRight: '5px' }} onClick={() => this.onClickAddConference()}>
                              <i className="fa fa-tv fa-lg" style={{ color: 'white' }}></i>
                              Add Conference
                            </Button>
                          }
                          {
                            (_.get(this.state.chatInfo,'is_conference','F')=='T') &&
                            <Button bsStyle="danger" bsSize="small" className="pull-right" style={{ marginTop: '5px', marginRight: '5px' }} onClick={() => this.onClickEndConference()}>
                              <i className="fa fa-tv fa-lg" style={{ color: 'white' }}></i>
                              End Conference
                            </Button>
                          }
                          </>
                        }
                        </span>
                      }
                  </div>
                  <div className={ this.state.show_search_input ? 'pull-right' : 'hide' } style={{  width: 'auto', flex: '1' }}>
                      <form onSubmit={this.onSearchMessage} style={{ marginLeft: '25px', marginTop: '5px' }}>
                          <div className="input-group">
                              <input type="text" style={{ height: '40px' }} className="form-control" placeholder="Search" value={this.state.filter} aria-describedby="basic-addon1" onChange={(event) => this.setState({filter: event.target.value})} />
                              <a className="input-group-addon" style={{ cursor: 'pointer' }} onClick={() => this.onSearchMessage()}>
                                  <i className='fa fa-search' aria-hidden="true" ></i>
                              </a>
                              <a className="input-group-addon" style={{ cursor: 'pointer' }} onClick={() => this.closeSearch()}>
                                  <i className='fa fa-close' aria-hidden="true" ></i>
                              </a>
                          </div>
                      </form>
                  </div>
              </div>
              <div className={this.state.is_show_note?"col-lg-8 conversation":"col-lg-12 conversation"}>
                  <div className={ this.state.isLoading? '' : 'hide' } style={{ padding: '0', argin: '0', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                      <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Loading ...</p>
                  </div>
                  <ReactChatView className={ this.state.isLoading? 'hide' : '' } flipped={true}  onInfiniteLoad={this.loadMoreHistory.bind(this)} scrollLoadThreshold={50} onClick={() => this.setState({ footer_selected: '' })} className={!!this.state.footer_selected? 'row message message-small content': 'row message content' } ref={(el) => { this.messagesEnd = el }}>
                      {
                        this.render_message()
                      }
                  </ReactChatView>
                  <input id="image-upload" type="file" className="form-control-file" style={{ display: 'none' }} onChange={this._image_upload_handler} onClick={(e) => {e.target.value=null;}} aria-describedby="fileHelp" />
                  <input id="file-upload" type="file" className="form-control-file" style={{ display: 'none' }} onChange={this._file_upload_handler} onClick={(e) => {e.target.value=null;}} aria-describedby="fileHelp" />

                  { (this.state.system_type==null || this.state.is_manager=='T')  && 
                    <div className={ (this.state.isSelectChatForOpenCase || this.state.show_selected_chat_message || this.state.showHandleError )? "hide" : "row reply" } >
                        <div style={{ display: 'flex' }}>
                            {
                              this.state.is_crm &&
                              <i className="fa fa-bolt fa-lg" style={{ marginTop: '7px', cursor: 'pointer', padding: '10px', color: '#93918f' }} title="Quick Reply" onClick={(e) => {
                                  this.onClickShowQuickReply(e);
                              }}></i>
                            }
                            <i className={ this.state.show_addi_item? "fa fa-chevron-circle-left fa-lg" : "fa fa-chevron-circle-right fa-lg" } style={{ marginTop: '7px', cursor: 'pointer', padding: '10px', color: '#93918f' }} onClick={() => {
                                this.setState({
                                    show_addi_item: !this.state.show_addi_item,
                                    footer_selected: ''
                                })
                            }}></i>
                            <i className={ this.state.show_addi_item? "fa fa-smile-o fa-lg" : "hide" } style={{ marginTop: '7px', cursor: 'pointer', padding: '10px', color: '#93918f' }} onClick={() => {
                                this.setState({
                                    footer_selected: 'sticker'
                                })
                            }}></i>
                            <i className={ this.state.show_addi_item? "fa fa-file-image-o fa-lg" : "hide" }  style={{ marginTop: '7px', cursor: 'pointer', padding: '10px', color: '#93918f' }} 
                                onClick={() => {
                                    $('#image-upload').trigger('click')
                                }}
                            ></i>
                            <i className={ this.state.show_addi_item? "fa fa-file-o fa-lg" : "hide" }  style={{ marginTop: '7px', cursor: 'pointer', padding: '10px', color: '#93918f' }}
                                onClick={() => {
                                    $('#file-upload').trigger('click')
                                }}
                            ></i>
                            <i className={ this.state.show_addi_item? "fa fa-camera fa-lg" : "hide" }  style={{ marginTop: '7px', cursor: 'pointer', padding: '10px', color: '#93918f' }}
                                onClick={() => {
                                    this.setState({
                                        showCamera: true
                                    }, () => {
                                        this.openCamera()
                                    })
                                }}
                            ></i>
                            <textarea onClick={() => this.setState({ show_addi_item: false, footer_selected: false })} onKeyDown={(e) => {
                                if(e.keyCode == 13 && e.shiftKey == false) {
                                    e.preventDefault()
                                    this._pushMessage(this.state.message)
                                }
                            }} value={this.state.message} onChange={(event) => this.setState({message: event.target.value})} className="form-control" rows="1" id="comment" style={{ marginLeft: '10px', marginRight: '10px' }}></textarea>
                            {1==0 &&
                              <i className="fa fa-microphone fa-lg" aria-hidden="true" style={{ marginTop: '7px', padding: '10px', color: '#93918f' }} onClick={() => {
                                  this.setState({
                                      footer_selected: 'record'
                                  })
                              }}></i>
                            }
                            {
                              this.state.showMessageSending &&
                              <i className="fa fa-spinner fa-pulse fa-2x fa-fw" aria-hidden="true" style={{ margin: '0', padding: '0', paddingRight:'3px', lineHeight: '40px', verticalAlign: 'middle', color: '#93918f' }}></i>
                            }
                            {
                              !this.state.showMessageSending &&
                              <i className="material-icons" aria-hidden="true" style={{ margin: '0', padding: '0', paddingRight:'3px', lineHeight: '40px', verticalAlign: 'middle', cursor: 'pointer' , color: '#93918f' }} onClick={ () => this._pushMessage(this.state.message) }>
                              send
                              </i>
                            }
                        </div>
                    </div>
                  }
                  <div className="row reply" className={ this.state.isSelectChatForOpenCase? "row reply" : "hide" }>
                      <div style={{ display: 'flex' }}>
                          <div style={{ flex: '1' }} />
                          <Button bsStyle="success" onClick={() => {
                              const selectedOptionMessageId = []
                              _.forEach(this.state.selectedOptionMessageId, (value, key) => {
                                  if(value) {
                                      selectedOptionMessageId.push(key)
                                  }
                              })
                              store.dispatch(onInviteFriendToGroupWithOpenCase(this.state.chatInfo.chat_room_id, this.state.selected_invite_friend_user_id, selectedOptionMessageId))
                          }} >Send</Button>
                          <Button bsStyle="warning" style={{ marginLeft: '15px', marginRight: '15px' }} onClick={() => {
                              this.setState({
                                  isSelectChatForOpenCase: false,
                                  selectedOptionMessageId: {}
                              })
                          }} >Cancel</Button>
                      </div>
                  </div>
                  <div className="row reply" className={ this.state.showHandleError? "row reply" : "hide" }>
                      <div style={{ display: 'flex' }}>
                          <div style={{ flex: '1' }} />
                          <Button bsStyle="success" onClick={() => {
                              this._resend()
                          }} >Resend</Button>
                          <Button bsStyle="warning" style={{ marginLeft: '15px' }} onClick={() => {
                              this._deleteErrorMessage()
                          }} >Delete</Button>
                          <Button bsStyle="default" style={{ marginLeft: '15px', marginRight: '15px' }} onClick={() => {
                              this.setState({
                                  showHandleError: false
                              })
                          }} >Cancel</Button>
                      </div>
                  </div>
                  <div className="row reply" className={ this.state.show_selected_chat_message? "row reply" : "hide" }>
                      <div style={{ display: 'flex' }}>
                          <div style={{ flex: '1' }} />
                          <Button bsStyle="danger" style={{ marginLeft: '5px', marginRight:'5px' }} onClick={() => {
                            this.deleteMessage(this.state.selected_chat_message_id)
                          }} >Delete</Button>
                          {
                            this.state.showUnsendBtn &&
                            <Button bsStyle="danger" style={{ marginLeft: '5px', marginRight:'5px' }} onClick={() => {
                              this.unsendMessage(this.state.selected_chat_message_id)
                            }} >Unsend</Button>
                          }
                          <Button bsStyle="info" style={{ marginLeft: '5px', marginRight:'5px' }} onClick={() => {
                                  this.setState({
                                      isShowForwardModal: true
                                  })        
                                  store.dispatch(onSearchFriendForward(''))
                              }} >Forward</Button>
                          {
                              _.get(this.state.chatInfo, 'display_name') != 'KEEP' && <Button bsStyle="warning" style={{ marginLeft: '5px', marginRight:'5px' }} onClick={() => {
                                  this.setState({ 
                                      show_selected_chat_message: false,
                                      selected_chat_message_id: ''
                                  })
                                  new Promise(() => {
                                      saveInKeep(this.state.selected_chat_message_id)
                                  })
                              }} >Save in Keep</Button>
                          }
                          
                          <Button bsStyle="default" style={{ marginLeft: '5px', marginRight:'5px' }} onClick={() => {
                                  this.setState({
                                      show_selected_chat_message: false,
                                      selected_chat_message_id: ''
                                  })
                              }} >Cancel</Button>
                      </div>
                  </div>
                  { 
                    (this.state.system_type==null || this.state.is_manager=='T')  && 
                    <div style={{ height: '200px', backgroundColor: 'white' }}>
                        {
                            this.render_addi_footer()
                        }
                    </div>
                  }

                  {this.state.selected_image && (
                    <Lightbox
                      mainSrc={this.state.selected_image}
                      onCloseRequest={() => this.setState({ selected_image: '' })} />
                  )}

                  <div className="static-modal">
                      <Modal show={this.state.showCamera} onHide={() => {
                              this.setState({
                                  showCamera: false,
                                  alreadyTaken: false
                              })
                          }}>
                          <Modal.Header>
                              <Modal.Title>Take a photo</Modal.Title>
                          </Modal.Header>

                              <video id="video" className={ !this.state.alreadyTaken? 'show' : 'hide' } width="640" height="480" autoplay style={{maxWidth:'100%',maxHeight:'100%'}}></video>
                              <canvas id="canvas" className={ this.state.alreadyTaken? 'show' : 'hide' } width="640" height="480" style={{maxWidth:'100%',maxHeight:'100%'}}></canvas>
                              
                          <Modal.Footer>
                              <Button onClick={() => {
                                  this.setState({
                                      showCamera: false,
                                      alreadyTaken: false
                                  })
                                  navigator.getUserMedia({audio: false, video: true},
                                      function(stream) {
                                      // can also use getAudioTracks() or getVideoTracks()
                                      var track = stream.getTracks()[0]  // if only one media track
                                      track.stop()
                                  },
                                  function(error){
                                      console.log('getUserMedia() error', error)
                                  })
                              }}>Close</Button>
                              {
                                  this.state.alreadyTaken && <Button bsStyle="warning" 
                                      onClick={() => {
                                          this.setState({
                                              alreadyTaken: false
                                          })
                                      }}
                                  >
                                      <i className="fa fa-undo" aria-hidden="true"></i>
                                  </Button>
                              }
                              {
                                  this.state.alreadyTaken && <Button bsStyle="success" 
                                      onClick={() => {
                                          this._pushPhoto(this.base64, this.base64)
                                          this.setState({
                                              alreadyTaken: false,
                                              showCamera: false
                                          })
                                      }}
                                  >Send</Button>
                              }
                              {
                                  !this.state.alreadyTaken && <Button bsStyle="primary" 
                                      onClick={() => {
                                          this.takePhoto()
                                      }}
                                  >Snapshot</Button>
                              }
                          </Modal.Footer>
                      </Modal>
                  </div>
                  <div className="height-auto">
                      <div className="static-modal">
                          <Modal show={this.state.showContactInfo} onHide={() => {
                                  this.setState({
                                      showContactInfo: false
                                  })
                              }}>
                              <Modal.Header style={{ backgroundColor: '#eee' }}>
                                  <Modal.Title>Chat Room Setting</Modal.Title>
                              </Modal.Header>
                              <div>

                                  <div style={{ display: 'flex', backgroundColor: '#fff', backgroundSize: 'cover', backgroundImage: `url(${ _.get(this.state.chatInfo, 'friend_wall_pic_url') })` }}>
                                      <div style={{ }}>
                                          <div className='avatar-icon'>
                                              <img style={{maxHeight:'150px',maxWidth:'150px'}} src={_.get(this.state.chatInfo, 'profile_pic_normal_url')} />
                                          </div>
                                      </div>
                                  </div>  
                                  <span className="setting-chat-name">{ _.get(this.state.chatInfo, 'display_name') }</span>

                                  {
                                    (this.state.is_group_admin=='T' && this.state.outside_customer!=null) && 
                                    <>
                                    <div style={{ display: 'flex', paddingTop: '15px' }}>
                                        <div style={{ width: '200px', textAlign: 'center'  }}>
                                            <label style={{ padding: '10px', paddingLeft: '35px' }}>Customer</label>
                                        </div>
                                        <div>
                                            <div className="col-md-10"  style={{ paddingLeft: '0' }}>
                                                <input type="text" id="inputOutsideCustomerName" className="form-control" placeholder="Customer Name" value={this.state.outside_customer} onChange={(event) => this.setState({outside_customer: event.target.value})}/>
                                            </div>
                                            <div className="col-md-1"  style={{ paddingLeft: '0', marginTop: '5px' }}>
                                                <a href="#" onClick={(e) => this.editOutsideCustomerName(e)}><i className="fa fa-check" style={{  }}></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '200px', textAlign: 'center'  }}>
                                            <label style={{ padding: '10px', paddingLeft: '35px' }}>Customer Link</label>
                                        </div>
                                        <div>
                                            <div className="col-md-10" style={{ padding: '0' }}>
                                                <input type="text" style={{fontSize:'12px'}} id="inputConfCustomerLink" readOnly className="form-control" value={"https://bpkconnect.com/bpk_connect/api/conference/join?chat_room_id="+this.state.chatInfo.chat_room_id}/>
                                            </div>
                                            <div className="col-md-2"  style={{ padding: '0', paddingLeft: '3px' }}>
                                              <button className="btn btn-default" onClick={() => {this.onClickCopyLink('inputConfCustomerLink')}}>Copy</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '200px', textAlign: 'center'  }}>
                                            <label style={{ padding: '10px', paddingLeft: '35px' }}>Doctor Link</label>
                                        </div>
                                        <div>
                                            <div className="col-md-10" style={{ padding: '0' }}>
                                              <input type="text" style={{fontSize:'12px'}} id="inputConfDoctorLink" readOnly className="form-control" value={"https://bpkconnect.com/bpk_connect/api/conference/join/doctor?chat_room_id="+this.state.chatInfo.chat_room_id}/>
                                            </div>
                                            <div className="col-md-2"  style={{ padding: '0', paddingLeft: '3px' }}>
                                              <button className="btn btn-default" onClick={() => {this.onClickCopyLink('inputConfDoctorLink')}}>Copy</button>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                  }

                                  { this.state.system_type==null && 
                                    <div style={{ display: 'flex', paddingTop: '15px', paddingBottom: '15px' }}>
                                        <div style={{ width: '200px', textAlign: 'center'  }}>
                                            <i className="fa fa-user fa-lg" aria-hidden="true" style={{ padding: '10px', paddingLeft: '35px' }}></i>
                                        </div>
                                        <div>
                                            { (this.state.is_manager=='T' || this.state.is_group_admin=='T' || _.get(this.state, 'chatInfo.chat_room_type', '')=='N') &&
                                              <p style={{ fontSize: '16px', padding: '5px', cursor: 'pointer' }} onClick={() => {
                                                  this.setState({
                                                      // showContactInfo: false,
                                                      showInviteFriend: true,
                                                      isOpenCase: false
                                                  })
                                              }}>Invite</p>
                                            }
                                            <p className={ !this.is_group()? '' : 'hide' } style={{ fontSize: '16px', padding: '5px', cursor: 'pointer' }} onClick={() => {
                                                this.setState({
                                                    // showContactInfo: false,
                                                    showInviteFriend: true,
                                                    isOpenCase: true
                                                })
                                            }}>Open case</p>
                                            <p className={ this.is_group()? '' : 'hide' } style={{ fontSize: '16px', padding: '5px', cursor: 'pointer' }} onClick={() => {
                                                store.dispatch(onExitTheGroup(_.get(this.state.chatInfo, 'chat_room_id')))
                                                this.props.history.push('/')
                                            }}>Leave group</p>
                                            <div style={{ borderBottom: '1px solid #dfdfdf', marginTop: '10px' }} />
                                        </div>
                                    </div>
                                  }

                                  <div style={{ display: 'flex', paddingTop: '15px', paddingBottom: '15px' }}>
                                      <div style={{ width: '200px', textAlign: 'center'  }}>
                                          <i className="fa fa-bars fa-lg" aria-hidden="true" style={{ padding: '10px', paddingLeft: '35px' }}></i>
                                      </div>
                                      <div>
                                          <p style={{ fontSize: '16px', padding: '5px', cursor: 'pointer' }} onClick={() => {
                                              store.dispatch(onHideChat())
                                              this.props.history.push('/')
                                          }}>Hide chat</p>

                                          { !this.isMute() && <p style={{ fontSize: '16px', padding: '5px', cursor: 'pointer' }} onClick={() => {
                                              this.setState({
                                                  chatInfo: Object.assign({}, this.state.chatInfo, {
                                                      is_mute: '1'
                                                  })
                                              }, () => {
                                                  store.dispatch(onMuteChat())
                                              })
                                          }}>Mute chat</p> }
                                          { this.isMute() && <p style={{ fontSize: '16px', padding: '5px', cursor: 'pointer' }} onClick={() => {
                                              this.setState({
                                                  chatInfo: Object.assign({}, this.state.chatInfo, {
                                                      is_mute: '0'
                                                  })
                                              }, () => {
                                                  store.dispatch(onUnmuteChat())
                                              })
                                          }}>Unmute chat</p> }

                                          { !this.isBlocked() && <p style={{ fontSize: '16px', padding: '5px', cursor: 'pointer' }} onClick={() => {
                                              this.setState({
                                                  chatInfo: Object.assign({}, this.state.chatInfo, {
                                                      is_blocked: '1'
                                                  })
                                              }, () => {
                                                  store.dispatch(onBlockChat())
                                              })
                                          }}>Block chat</p> }
                                          { this.isBlocked() && <p style={{ fontSize: '16px', padding: '5px', cursor: 'pointer' }} onClick={() => {
                                              this.setState({
                                                  chatInfo: Object.assign({}, this.state.chatInfo, {
                                                      is_blocked: '0'
                                                  })
                                              }, () => {
                                                  store.dispatch(onUnblockChat())
                                              })
                                          }}>Unblock chat</p> }
                                          
                                          {1==0 &&
                                          <p style={{ fontSize: '16px', padding: '5px', cursor: 'pointer' }} onClick={() => {
                                              store.dispatch(onDeleteChat())
                                              this.props.history.push('/')
                                          }}>Delete chat</p>
                                          }
                                      </div>
                                  </div>
                                  <div className={ this.is_group()? '' : 'hide' } style={{ borderTop: '1px solid #dfdfdf', minHeight: '12px', background: '#f5f5f5' }} />
                                  <div className={ this.is_group()? '' : 'hide' } style={{ display: 'flex', paddingTop: '15px', paddingBottom: '15px' }}>
                                      <div style={{ width: '200px', textAlign: 'center'  }}>
                                          <i className="fa fa-users fa-lg" aria-hidden="true" style={{ padding: '10px', paddingLeft: '35px' }}></i>
                                      </div>
                                      <div>
                                          {
                                              this.state.member.map((member) => (<div key={member.friend_user_id} style={{ paddingTop: '8px', paddingBottom: '8px', display: 'flex' }}>                                                
                                                  { (this.state.is_manager=='T' || this.state.is_group_admin=='T') &&
                                                    <Confirm
                                                        onConfirm={()=>{
                                                          store.dispatch(onRemoveFriendFromGroup(this.state.chatInfo.chat_room_id, member.friend_user_id, true))
                                                        }}
                                                        body="Are you sure you want to remove this?"
                                                        confirmText="Confirm Remove"
                                                        title="Remove"
                                                        confirmBSStyle="danger">
                                                        <button>
                                                          <i className="fa fa-close fa-lg" aria-hidden="true" style={{ padding: '10px', paddingLeft:'0', marginRight: '0', marginTop: '0', cursor: 'pointer' }}></i>
                                                        </button>
                                                    </Confirm>
                                                  }
                                                  <div className='avatar-icon' style={{ width: 'auto', margin: '0' }} >
                                                    <span className="avatar-img" style={{backgroundImage:`url(${_.get(member, 'profile_pic_url')})`, width: '40px', height: '40px'}}></span>
                                                  </div>
                                                  <div style={{ width: 'auto', flex: '1',  textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                      <p style={{ fontSize: '16px', padding: '10px', cursor: 'pointer', flex: '1', display: 'inline-block',  textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{ member.display_name }</p>
                                                      
                                                  </div>
                                                  {
                                                    (this.state.is_manager=='T' &&  member.is_group_admin!='T' && ["outside","agent"].indexOf(member.user_role)==-1) &&
                                                    <Confirm
                                                        onConfirm={()=>{
                                                          store.dispatch(onGroupToggleAdmin(_.get(this.state.chatInfo, 'chat_room_id'), member.friend_user_id, true, 'add'))
                                                        }}
                                                        body="Are you sure?"
                                                        confirmText="Confirm Add Manager"
                                                        title="Add Manager"
                                                        confirmBSStyle="primary">
                                                        <button className="btn btn-default btn-group-admin-toggle">
                                                          + Manager
                                                        </button>
                                                    </Confirm>
                                                  }
                                                  {
                                                    (this.state.is_manager=='T' &&  member.is_group_admin=='T') &&
                                                    <Confirm
                                                        onConfirm={()=>{
                                                          store.dispatch(onGroupToggleAdmin(this.state.chatInfo.chat_room_id, member.friend_user_id, true, 'remove'))
                                                        }}
                                                        body="Are you sure?"
                                                        confirmText="Confirm Remove Manager"
                                                        title="Remove Manager"
                                                        confirmBSStyle="danger">
                                                        <button className="btn btn-success btn-group-admin-toggle">
                                                          Manager
                                                        </button>
                                                    </Confirm>                                                  
                                                  }                                                
                                              </div>))
                                          }
                                      </div>
                                  </div>
                              </div>
                              <Modal.Footer>
                                  <Button onClick={() => {
                                      this.setState({
                                          showContactInfo: false
                                      })
                                  }}>Close</Button>
                              </Modal.Footer>
                          </Modal>
                      </div>
                  </div>
                  
                  <div className="height-auto">
                      <div className="static-modal">
                          <Modal className="modal-invite" show={this.state.showInviteFriend} onHide={() => {
                                  this.setState({
                                      inviteFilter: '',
                                      showInviteFriend: false
                                  })
                              }}>
                              <Modal.Header style={{ backgroundColor: '#eee' }}>
                                  <Modal.Title>Invite Friend</Modal.Title>
                              </Modal.Header>
                              <div>
                                  <form onSubmit={this.onSearchInviteFriend}>
                                      <div className="col-sm-12 searchBox-inner">
                                          <div className="input-group">
                                              <input type="text" style={{ height: '40px' }} className="form-control" placeholder="Search" value={this.state.inviteFilter} aria-describedby="basic-addon1" onChange={(event) => this.setState({inviteFilter: event.target.value})} />
                                              <a className="input-group-addon" style={{ cursor: 'pointer' }} onClick={() =>  store.dispatch(onFetchInviteFriend(this.state.inviteFilter)) }>
                                                  <i className='fa fa-search' aria-hidden="true"></i>
                                              </a>
                                          </div>
                                      </div>
                                  </form>
                                  <div className="modal-invite-list">
                                  {
                                      this.state.showInviteFriend && this.state.inviteFriends.filter((friend) => friend.friend_user_id != this.state.chatInfo.friend_user_id).map((friend, key) => {
                                          return (
                                              <div className="box invite-friend-container" key={key} onClick={() => {
                                                  if(this.state.isOpenCase) {
                                                      this.setState({
                                                          showInviteFriend: false,
                                                          selected_invite_friend_user_id: friend.friend_user_id,
                                                          isSelectChatForOpenCase: true
                                                      })
                                                  } else {
                                                      if(friend.invited) {
                                                          store.dispatch(onRemoveFriendFromGroup(this.state.chatInfo.chat_room_id, friend.friend_user_id, false))
                                                      } else {
                                                          store.dispatch(onInviteFriendToGroup(this.state.chatInfo.chat_room_id, friend.friend_user_id, friend))
                                                      }
                                                  }
                                              }}>
                                                  <Friend image={friend.profile_pic_url} name={friend.display_name} status={this.is_group()? friend.status_quote : 'Tap to invite'} />
                                              </div>
                                          )
                                      })
                                  }
                                  </div>
                                  <div className={ this.state.inviteFriendsCount > this.state.inviteFriends.length ?  'row message-previous' : 'hide' } >
                                      <div className="col-sm-12 previous">
                                          <a onClick={() => store.dispatch(loadMoreInviteFriends(0, this.state.inviteFilter))} >
                                              Show more friends
                                          </a>
                                      </div>
                                  </div>
                              </div>
                              <Modal.Footer>
                                  <Button onClick={() => {
                                      this.setState({
                                          inviteFilter: '',
                                          showInviteFriend: false
                                      })
                                  }}>Close</Button>
                              </Modal.Footer>
                          </Modal>
                          {
                              <ForwardModal show={this.state.isShowForwardModal} data={this.props.data} selectedMessageId={this.state.selected_chat_message_id}
                                  onHide={() => {
                                      this.setState({
                                          isShowForwardModal: false
                                      })
                                  }}

                                  onSuccess={() => {
                                      this.setState({
                                          show_selected_chat_message: false,
                                          selected_chat_message_id: ''
                                      });
                                      store.dispatch(isShowAlertModal(true,'Success','Forwarded successfully.'))
                                  }}
                              />
                          }
                      </div>
                  </div>
              </div>
              {this.state.is_show_note &&
              <div id="sidebar-right" className="col-lg-4">
                <div align="center" style={{marginTop:'10px'}}>
                  {
                    this.state.is_crm && 
                    <a href="#" onClick={(e) => this.onClickShowSearchPatientModal('COVID19_RESULT',e)} style={{ borderBottom: '1px solid #dfdfdf', fontWeight: 'bold', display: 'block', textAlign:'right', marginBottom: '3px'}}>Send COVID19 Result ></a>
                  }
                  <a href={ _.get(this.state.chatInfo, 'profile_pic_normal_url') } target="_blank"><img src={ _.get(this.state.chatInfo, 'profile_pic_normal_url') } style={{maxHeight:'150px'}} /></a>
                  <ReactTags
                    ref={this.reactChatTags}
                    tags={this.state.chat_room_tags}
                    suggestions={this.state.chatTagSuggestions}
                    onDelete={this.onDeleteChatTag.bind(this)}
                    onAddition={this.onAdditionChatTag.bind(this)}
                    placeholderText="+ Add tags"
                    allowNew={true} />
                  <div style={{ borderBottom: '1px solid #dfdfdf', height: '1px', margin: '5px' }} />
                  <div style={{ height: 'auto' }}>
                    <b className="pull-left">Notes</b>
                    <a href="#" className="pull-right" onClick={(e) => this.addChatRoomNote(e)}><b>+</b></a>                    
                  </div>
                  <div style={{ height: 'calc(100% - 350px)', overflow:'hidden auto' }}>
                    {
                      this.renderChatRoomNotes()
                    }
                  </div>
                </div>
              </div>
              }

            <div className="height-auto">
                <div className="static-modal">
                    <Modal show={this.state.isShowQuickReplyModal} onHide={() => {
                            this.setState({
                                isShowQuickReplyModal: false
                            })
                        }}>
                        <Modal.Header style={{ backgroundColor: '#eee' }}>
                          <Modal.Title>Quick Reply</Modal.Title>
                        </Modal.Header>
                        <div style={{padding:0}}>
                        {
                            this.renderQuickReplyLists()
                        }
                        </div>
                        <Modal.Footer>
                            <Button onClick={() => {
                                this.setState({
                                    isShowQuickReplyModal: false
                                })
                            }}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>

            <div className="height-auto">
                <div className="static-modal">
                    <Modal show={this.state.isShowSearchPatientModal} onHide={() => {
                            this.setState({
                                isShowSearchPatientModal: false
                            })
                        }}>
                        <Modal.Header style={{ backgroundColor: '#eee' }}>
                          <Modal.Title>Search Patient</Modal.Title>
                        </Modal.Header>
                        <form className="form-horizontal" style={{marginTop: '10px'}}>
                          <div className="col-md-6" style={{padding: '0'}}>
                            <div className="form-group" style={{marginLeft: '0', marginRight:'0'}}>
                              <label className="control-label col-md-4" style={{paddingRight: '0px'}}>Hospital</label>
                              <div className="col-md-8">
                                <select className="form-control" value={this.state.search_patient_hos_code} onChange={(event) => this.setState({search_patient_hos_code: event.target.value})} style={{paddingLeft:'0',paddingRight:'0'}}>
                                <option value="100">(Test)Bangpakok1</option>
                                <option value="301">BPK Samutprakan</option>
                                <option value="300">(Test)Bangpakok3</option>
                                <option value="600">Bangpakok-Rangsit2</option>
                                <option value="800">(Test)Bangpakok8</option>
                                <option value="900">Bangpakok9</option>
                                <option value="500">Piyavate</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6" style={{padding: '0'}}>
                            <div className="form-group" style={{marginLeft: '0', marginRight:'0'}}>
                              <label className="control-label col-md-4" style={{paddingRight: '0px'}}>HN</label>
                              <div className="col-md-8">
                                <input type="text" className="form-control" placeholder="HN" value={this.state.search_patient_hn}  onChange={(event) => this.setState({search_patient_hn: event.target.value})} />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6" style={{padding: '0'}}>
                            <div className="form-group" style={{marginLeft: '0', marginRight:'0'}}>
                              <label className="control-label col-md-4" style={{paddingRight: '0px'}}>เลขบัตรปชช.</label>
                              <div className="col-md-8">
                                <input type="text" className="form-control" placeholder="เลขบัตรประชาชน" value={this.state.search_patient_pid}  onChange={(event) => this.setState({search_patient_pid: event.target.value})} />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6" style={{padding: '0'}}>
                            <div className="form-group" style={{marginLeft: '0', marginRight:'0'}}>
                              <label className="control-label col-md-4" style={{paddingRight: '0px'}}>Passport</label>
                              <div className="col-md-8">
                                <input type="text" className="form-control" placeholder="Passport No." value={this.state.search_patient_passport_no}  onChange={(event) => this.setState({search_patient_passport_no: event.target.value})} />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6" style={{padding: '0'}}>
                            <div className="form-group" style={{marginLeft: '0', marginRight:'0'}}>
                              <label className="control-label col-md-4" style={{paddingRight: '0px'}}>ชื่อ</label>
                              <div className="col-md-8">
                                <input type="text" className="form-control" placeholder="ชื่อ" value={this.state.search_patient_firstname}  onChange={(event) => this.setState({search_patient_firstname: event.target.value})} />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6" style={{padding: '0'}}>
                            <div className="form-group" style={{marginLeft: '0', marginRight:'0'}}>
                              <label className="control-label col-md-4" style={{paddingRight: '0px'}}>นามสกุล</label>
                              <div className="col-md-8">
                                <input type="text" className="form-control" placeholder="นามสกุล" value={this.state.search_patient_lastname}  onChange={(event) => this.setState({search_patient_lastname: event.target.value})} />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12" style={{textAlign: 'right'}}>
                            <button className="btn btn-primary" onClick={(e) => {this.onClickSearchPatientList(e,false)}}>Search</button>
                          </div>
                          <div className="clearfix"></div>
                        </form>
                        <div style={{ borderBottom: '1px solid #dfdfdf', marginTop: '10px' }} />
                        <div style={{padding:0}}>
                        {
                            this.renderSearchPatientLists()
                        }
                        </div>
                        { this.state.isLoading && 
                          <div className="row message-previous">
                              <div className="col-sm-12 previous">
                                  <a>
                                    Loading...
                                  </a>
                              </div>
                          </div>
                        }
                        { !this.state.isLoading && 
                          <div className={ this.state.searchPatientListShowMore ?  'row message-previous' : 'hide' } >
                              <div className="col-sm-12 previous">
                                  <a onClick={(e) => this.onClickSearchPatientList(e,true)} >
                                      Show more
                                  </a>
                              </div>
                          </div>
                        }
                        <Modal.Footer>
                            <Button onClick={() => {
                                this.setState({
                                    isShowSearchPatientModal: false
                                })
                            }}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>

            <div className="height-auto">
                <div className="static-modal">
                    <Modal show={this.state.isShowSearchCovidResultModal} onHide={() => {
                            this.setState({
                                isShowSearchCovidResultModal: false
                            })
                        }}>
                        <Modal.Header style={{ backgroundColor: '#eee' }}>
                          <Modal.Title>COVID19 RESULT</Modal.Title>
                        </Modal.Header>
                        {
                          this.state.searchCovidResultList!=null && this.state.searchCovidResultList.length>0 &&
                          <div style={{padding:0}}>
                          {
                              this.renderSearchCovidResultLists()
                          }
                          </div>
                        }
                        {
                          (this.state.searchCovidResultList==null || this.state.searchCovidResultList.length==0) &&
                          <div style={{padding:10, textAlign:'center', fontWeight:'bold'}}>
                          No Result
                          </div>
                        }
                        <Modal.Footer>
                            <Button onClick={() => {
                                this.setState({
                                    isShowSearchCovidResultModal: false
                                })
                            }}>Close</Button>
                            {
                              this.state.searchCovidResultList!=null && this.state.searchCovidResultList.length>0 &&
                              <Button className="btn btn-primary" onClick={(e) => {
                                  this.onClickSendCovidResult(e)
                              }}>Confirm</Button>
                            }
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
          </div>
        )
    }

    
    renderQuickReplyLists = () => {
      const quickReplyLists = this.state.quickReplyLists.map((info) => {
          return (
              <div key={info.text} className="row compose-sideBar row-quick_reply" onClick={(e) => {
                this.onClickQuickReply(e, info);
              }}>
                  <div className="row sideBar-body">
                      <div className="col-sm-12 col-xs-12 sideBar-main">
                          <div className="row">
                              <div className="col-sm-12 col-xs-12 sideBar-name">
                                  <div className="name-meta" style={{whiteSpace:'pre-line'}}> { info.text }
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          )
      })
      return quickReplyLists
    }
    
    renderSearchPatientLists = () => {
      const patientList = this.state.searchPatientLists.map((info) => {
          return (
              <div key={info.patient_id} className="row compose-sideBar row-quick_reply" onClick={(e) => {
                this.onClickSearchPatient(e, info);
              }}>
                  <div className="row sideBar-body">
                      <div className="col-sm-12 col-xs-12 sideBar-main">
                          <div className="row">
                              <div className="col-sm-12 col-xs-12 sideBar-name">
                                  <div className="name-meta" style={{whiteSpace:'pre-line', color:'grey'}}> HN: { info.bpk_hn }, PID: { info.pid }, Passport: { info.passport_no }
                                  </div>
                                  <div className="name-meta" style={{whiteSpace:'pre-line'}}> { info.patient_name } (DOB:{ info.birthdate })
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          )
      })
      return patientList
    }

    b64toBlob = (b64Data, contentType='', sliceSize=512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, {type: contentType});
      return blob;
    }

    renderSearchCovidResultLists = () => {
      const resultList = this.state.searchCovidResultList.map((info) => {
          // let detected = (info.lab_result.toLowerCase()=='detect' || info.lab_result.toLowerCase()=='detected' || info.lab_result.toLowerCase()=='[detected]' || info.lab_result.toLowerCase()=='equivocal' || (info.resultName=='COVID19 Real time PCR' && info.lab_result=='SEE SCAN'));
          let detected = false;
          return (
              <div key={info.list_id} className="row compose-sideBar row-quick_reply">
                  <div className="row sideBar-body">
                      <div className="col-sm-12 col-xs-12 sideBar-main">
                          <div className="row">
                              <div className="col-sm-12 col-xs-12 sideBar-name">
                                <label className="name-meta" style={{whiteSpace:'pre-line'}}>
                                  { 
                                    detected &&
                                    <span style={{display: 'block', fontWeight:'bold', color:'red', fontSize:20, padding:3}}>กรุณาประสานงานผู้รับผิดชอบในการแจ้งผลผู้ใช้บริการ</span>
                                  }
                                  { 
                                    !detected &&
                                    <input type="checkbox" className="col-md-1" id={`covidResultId_${info.list_id}`} value={info.list_id}  defaultChecked={true} />
                                  }
                                  <span style={{display: 'block', fontWeight:'bold', color:'grey', padding:3}}>{ info.list_header }</span>
                                  <span style={{display: 'block', padding:3}}>{ info.list_detail }</span>
                                </label>
                                { 
                                  !detected &&
                                  <iframe src={info.url} width="100%" height="350">
                                  </iframe>
                                }
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          )
      })
      return resultList
    }
}

export default Content
