import _ from 'lodash'
import SocketIOClient from 'socket.io-client'

const socket = SocketIOClient('https://bpkconnect.com:9001/', {
    transports: ['websocket']
})

export const get_conference_room = (chat_room_id,callback) => {
    console.log('[get_conference_room]')

    socket.emit('get-public-rooms', chat_room_id, function(listOfRooms) {
      // updateListOfRooms(listOfRooms);
      callback(listOfRooms);
    });

    // socket.emit('check-presence', chat_room_id + '', function(isRoomExist, _roomid, extra) {
    //   console.log(extra);
    //         console.log('checkPresence.isRoomExist: ', isRoomExist, ' roomid: ', _roomid);
    // });
}

export const delete_conference_room = (chat_room_id) => {
    console.log('[delete_conference_room]')

    const socketAdmin = SocketIOClient('https://bpkconnect.com:9001/?userid=admin&adminUserName=username&adminPassword=password', {
        transports: ['websocket']
    })
    socketAdmin.emit('admin', {
      deleteRoom: true,
      roomid: chat_room_id
    }, function(isDeleted) {
      console.log(isDeleted);
    });
}

export const disconnect_socket_conference = () => {
  socket.disconnect();
}

export const start_socket_conference = () => {
    // Connect!
        console.log(' start_socket_conference ')
    socket.connect();

    // An event to be fired on connection to socket
    socket.on('connect', () => {
        console.log(' socket connected ')
    })
    // looper();

    socket.on('disconnect', (reason) => {
      console.log(' socket disconnect ')
      // socket.removeListener("updateFriendChatList");
      if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.connect();
      }
      // else the socket will automatically try to reconnect
    });
}
