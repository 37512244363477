import _ from 'lodash'
import $ from 'jquery'
import React, { Component } from 'react'

import { isShowAlertModal } from '../../redux/actions.js'
import { store } from '../../redux'
import { Modal, Button } from 'react-bootstrap'

class AlertModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          'isShowAlertModal': false,
          'alertTitle': '',
          'alertSuccessMsg': '',
          'alertErrorMsg': ''
        }
    }
    
    componentDidMount() {
      this.load_data()
    }

    componentWillUnmount() {
    }

    load_data = () => {
        if(_.get(this.props.data, 'user.user')) {
            // console.log(_.get(this.props.data, 'system.selectedFriend',null));
            this.setState({
                user: _.get(this.props.data, 'user.user',null),
            })
        }

        if(_.get(this.props.data, 'system')) {
            this.setState({
                isShowAlertModal: _.get(this.props.data, 'system.isShowAlertModal'),
                alertTitle: _.get(this.props.data, 'system.alertTitle'),
                alertSuccessMsg: _.get(this.props.data, 'system.alertSuccessMsg'),
                alertErrorMsg: _.get(this.props.data, 'system.alertErrorMsg'),
            })
        }
    }

    componentWillReceiveProps() {
        this.load_data()
    }
    
    render = () => {
        return (
            <div className="static-modal">
            <Modal show={this.state.isShowAlertModal} onHide={() => {
                    store.dispatch(isShowAlertModal(false))
                }}>
                <Modal.Header closeButton>
                  <Modal.Title>{this.state.alertTitle}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  {
                    this.state.alertSuccessMsg!='' &&
                    <p>{this.state.alertSuccessMsg}</p>
                  }
                  {
                    this.state.alertErrorMsg!='' &&
                    <p>{this.state.alertErrorMsg}</p>
                  }
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => {
                        store.dispatch(isShowAlertModal(false))
                    }}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
        )
    }
}

export default AlertModal