import axios from "axios"
import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import XORCipher from './hash'

const baseUrl = 'https://bpkconnect.com/bpk_connect';
const helpdeskBaseUrl = 'https://bpkconnect.com/helpdesk';
const purchaseBaseUrl = 'https://bpkconnect.com/purchase';

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    if(config.method == 'post') {
        config.headers['Content-Type'] =  'application/x-www-form-urlencoded; charset=UTF-8' 
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
})

export const getAuth = () => {
    return new Promise(function(resolve, reject) {
        const user_id = localStorage.getItem("user_id")
        resolve(XORCipher.decode('user_id', user_id))
    })
}

export const fetchMyProfile = () => {
    return getAuth().then((user_id) => {
        return axios.get(`${baseUrl}/api/user/my_profile?token=asdf1234aaa&user_id=${user_id}`)
    })
}

export const apiGetTagList = (searchText='') => {
    return getAuth().then((user_id) => {
        return axios.get(`${baseUrl}/api/tag/get_tag_list?token=asdf1234aaa&search_text=${searchText}`)
    })
}

export const apiSaveTag = (data) => {
    return getAuth().then((user_id) => {
        data.user_id = user_id;
        return axios.post(`${baseUrl}/api/tag/save_tag?token=asdf1234aaa`, data)
    })
}

export const apiDeleteTag = (data) => {
    return getAuth().then((user_id) => {
        data.user_id = user_id;
        return axios.post(`${baseUrl}/api/tag/delete_tag?token=asdf1234aaa`, data)
    })
}

export const apiGetQuickReplyList = (searchText='') => {
    return getAuth().then((user_id) => {
        return axios.get(`${baseUrl}/api/quick_reply/get_quick_reply_list?token=asdf1234aaa&search_text=${searchText}`)
    })
}

export const apiSaveQuickReply = (data) => {
    data.old_quick_reply = encodeURIComponent(data.old_quick_reply);
    data.new_quick_reply = encodeURIComponent(data.new_quick_reply);
    return getAuth().then((user_id) => {
        data.user_id = user_id;
        return axios.post(`${baseUrl}/api/quick_reply/save_quick_reply?token=asdf1234aaa`, data)
    })
}

export const apiDeleteQuickReply = (data) => {
    return getAuth().then((user_id) => {
        data.user_id = user_id;
        return axios.post(`${baseUrl}/api/quick_reply/delete_quick_reply?token=asdf1234aaa`, data)
    })
}

export const apiGetSearchPatientList = (hos_code='900',hn='',pid='',passport_no='',firstname='',lastname='',start=0) => {
    return getAuth().then((user_id) => {
        return axios.get(`${baseUrl}/api/patient/get_search_patient_list?token=asdf1234aaa&hos_code=${hos_code}&hn=${hn}&pid=${pid}&passport_no=${passport_no}&firstname=${firstname}&lastname=${lastname}&start=${start}&limit=50`)
    })
}

export const apiAddCovidResultLog = (hos_code='900',visit_id='',doc_type='',doc_name='',chat_room_id='') => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/patient/add_covid_result_log?token=asdf1234aaa`, {
          'hos_code' : hos_code,
          'visit_id' : visit_id,
          'doc_type' : doc_type,
          'doc_name' : doc_name,
          'chat_room_id' : chat_room_id,
          'user_id' : user_id
        })
    })
}

export const apiGetCovidResultList = (hos_code='900',patient_id='',hn='') => {
    return getAuth().then((user_id) => {
        return axios.get(`${baseUrl}/api/patient/get_covid_result_list?token=asdf1234aaa&hos_code=${hos_code}&patient_id=${patient_id}&hn=${hn}`)
    })
}

export const apiGetCovidBedList = (hos_code='') => {
    return getAuth().then((user_id) => {
        return axios.get(`${baseUrl}/api/patient/get_covid_bed_list?token=asdf1234aaa&hos_code=${hos_code}`)
    })
}

export const fetchChatLists = (filterType='', searchText='', fetchLimit='', before_last_chat='', room_type_hos='') => {
    return getAuth().then((user_id) => {
        return axios.get(`${baseUrl}/api/chat/chat_list?token=asdf1234aaa&is_pc=T&user_id=${user_id}&start=0&limit=10&fetchLimit=${fetchLimit}&chat_room_type=${filterType}&search_text=${searchText}&before_last_chat=${before_last_chat}&room_type_hos=${room_type_hos}`)
    })
}

export const fetchFriendListCount = (group, filter) => {
    return getAuth().then((user_id) => {
        return axios.get(`${baseUrl}/api/friend/friend_list_count?token=asdf1234aaa&user_id=${user_id}&friend_type=${group}&filter=${filter}`)
    })
}

export const fetchFriendGroups = () => {
    return axios.get(`${baseUrl}/api/friend/friend_type_list`)
}

export const fetchFriendLists = (group, range=50, start = 0, filter = '') => {
    return getAuth().then((user_id) => {
        return axios.get(`${baseUrl}/api/friend/friend_list?token=asdf1234aaa&user_id=${user_id}&start=${start}&limit=${range}&filter=${filter}&friend_type=${group}`)
    })
}

export const fetchFriendProfile = (userID) => {
    return axios.get(`${baseUrl}/api/user/data/${userID}`)
}

export const fetchChatInfo = (chat_room_id) => {
    return getAuth().then((user_id) => {
        return axios.get(`${baseUrl}/api/chat/data?token=asdf1234aaa&user_id=${user_id}&chat_room_id=${chat_room_id}`)
    })
}

export const loginApi = (username, password) => {
    const bodyFormData = new FormData()
    bodyFormData.append('user_id', username)
    bodyFormData.append('password', password)
    return axios({
      method: "post",
      url: `${baseUrl}/api/user/check_login`,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
    .then(function (response) {
      //handle success
      return response;
    })
    .catch(function (response) {
      //handle error
      return null;
    });
}

export const logoutApi = () => {
    return getAuth().then((user_id) => {
        return axios.get(`${baseUrl}/api/user/logout?token=asdf1234aaa&user_id=${user_id}`)
    })
}

export const fetchLanguage = () => {
    return axios.get(`${baseUrl}/api/user/language_list`)
}

export const updateProfileImage = () => {
    return axios.post(`${baseUrl}/api/group/update_picture`, {
        token: 'asdf1234aaa',
    })
}

export const addFavoriteApi = (user_id, friend_user_id) => {
    return axios.post(`${baseUrl}/api/friend/add_fav`, {
        token: 'asdf1234aaa',
        user_id,
        friend_user_id
    })
}

export const removeFavoriteApi = (user_id, friend_user_id) => {
    return axios.post(`${baseUrl}/api/friend/remove_fav`, {
        token: 'asdf1234aaa',
        user_id,
        friend_user_id
    })
}

export const createNewAccount = (id, password, display_name, mobile_no, pid, passport_no, language) => {
    return axios.post(`${baseUrl}/api/user/register`, {
        username: id,
        password,
        display_name,
        mobile_no,
        pid,
        passport_no,
        user_language_id: language
    })
}

export const apiForgotPassword = (data) => {
    return  axios.post(`${baseUrl}/api/user/forgot_password?token=asdf1234aaa`, data, {timeout:5*1000})
        .then(function (response) {
          return response
        })
        .catch(function (error) {
          return null
        });
}

export const updatePicture = (data) => {
    return  axios.post(`${baseUrl}/api/group/update_picture?token=asdf1234aaa`, data).then((res) => {
        return res
    }, (err) => {
        return err
    })
}

export const updateGroupSetting = (data) => {
    return axios.post(`${baseUrl}/api/group/update_setting?token=asdf1234aaa`, data).then((res) => {
        return res
    }, (err) => {
        return err
    })
}

export const fetchChat = (chatRoomId, topChatMessageId = '', after_chat_message_id = '', search = '') => {
    return getAuth().then((user_id) => {
        return axios.get(`${baseUrl}/api/message/message_list?token=asdf1234aaa&user_id=${user_id}&chat_room_id=${chatRoomId}&after_chat_message_id=${after_chat_message_id}&before_chat_message_id=${topChatMessageId}&limit=20&search=${search}`)
        .then((res) => {
          return res
        }, (err) => {
            console.log('[fetchChat] Reject ', err)
            return {
                data: {
                    data: []
                }
            }
        }).catch((err) => {
          if (axios.isCancel(err)) {
            console.log('fetchChat canceled', err.message);
          }
          else{
            console.log('[fetchChat] Error ', err)
          }
        })
    })
}

export const sendTheMessage = (chat_room_id, message_type, content, sticker_path, image_base64, copy_chat_message_id = '',stream_id='') => {
    content = encodeURIComponent(content);
    return getAuth().then((user_id) => {
        const bodyFormData = new FormData()
        bodyFormData.append('user_id', user_id)
        bodyFormData.append('chat_room_id', chat_room_id)
        bodyFormData.append('message_type', message_type)
        bodyFormData.append('content', content)
        bodyFormData.append('sticker_path', sticker_path)
        bodyFormData.append('image_base64', image_base64)
        bodyFormData.append('copy_chat_message_id', copy_chat_message_id)
        bodyFormData.append('stream_id', stream_id)
        return fetch(`${baseUrl}/api/message/send?token=asdf1234aaa`, {
            method: 'post',
            body: bodyFormData
            }).then(res => {
                if(res.status != 200) {
                    return {
                        error: 'lost connection'
                    }
                }
                return res.json()
            })
    })
}

export const sendFileMessage = (chat_room_id, message_type, file, file_base64='', file_name='') => {
    return getAuth().then((user_id) => {
        const bodyFormData = new FormData()
        bodyFormData.append('user_id', user_id)
        bodyFormData.append('chat_room_id', chat_room_id)
        bodyFormData.append('message_type', message_type)
        bodyFormData.append('content', '')
        bodyFormData.append('sticker_path', '')
        bodyFormData.append('image_base64', '')
        bodyFormData.append('file', file)
        if(file_base64!='' && file_name!=''){
            bodyFormData.append('file_base64', file_base64)
            bodyFormData.append('file_name', file_name)
        }
        return fetch(`${baseUrl}/api/message/send?token=asdf1234aaa`, {
            method: 'post',
            body: bodyFormData
            }).then(res => {
                if(res.status != 200) {
                    return {
                        error: 'lost connection'
                    }
                }
                return res.json()
            })
    })
}

export const fetchSticker = () => {
    return getAuth().then((user_id) => {
        return axios.get(`${baseUrl}/api/message/sticker_list?token=asdf1234aaa&user_id=${user_id}`)
    })
}

export const muteChat = (chat_room_id) => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/chat/mute?token=asdf1234aaa`, {
            chat_room_id,
            user_id: user_id
        })
    })
}

export const deleteChat = (chat_room_id) => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/chat/delete?token=asdf1234aaa`, {
            chat_room_id,
            user_id: user_id
        })
    })
}

export const hideChat = (chat_room_id) => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/chat/hide?token=asdf1234aaa`, {
            chat_room_id,
            user_id: user_id
        })
    })
}

export const blockChat = (chat_room_id) => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/chat/block?token=asdf1234aaa`, {
            chat_room_id,
            user_id: user_id
        })
    })
}

export const unblockChat = (chat_room_id) => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/chat/unblock?token=asdf1234aaa`, {
            chat_room_id,
            user_id: user_id
        })
    })
}

export const unmuteChat = (chat_room_id) => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/chat/unmute?token=asdf1234aaa`, {
            chat_room_id,
            user_id: user_id
        })
    })
}

export const setAsSeen = (chat_room_id) => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/chat/read_message?token=asdf1234aaa`, {
            chat_room_id,
            user_id: user_id
        })
    })
}

export const chatRoomFollow = (chat_room_id,is_follow='T') => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/chat/chat_room_follow?token=asdf1234aaa`, {
            chat_room_id, user_id, is_follow
        })
    })
}

export const chatRoomResolve = (chat_room_id,is_resolved='T') => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/chat/chat_room_resolve?token=asdf1234aaa`, {
            chat_room_id, user_id, is_resolved
        })
    })
}

export const chatRoomUpdateOutsideCustomer = (data) => {
    return getAuth().then((user_id) => {
        data.user_id = user_id;
        return axios.post(`${baseUrl}/api/chat/update_outside_customer?token=asdf1234aaa`, data)
    })
}

export const chatRoomNoteSave = (data) => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/chat/chat_room_note_save?token=asdf1234aaa`, data)
    })
}

export const chatRoomNoteDelete = (chat_room_note_id) => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/chat/chat_room_note_delete?token=asdf1234aaa`, {'chat_room_note_id':chat_room_note_id, 'user_id':user_id})
    })
}

export const chatRoomNoteGet = (chat_room_id) => {
    return getAuth().then((user_id) => {
        return axios.get(`${baseUrl}/api/chat/chat_room_note_get?token=asdf1234aaa&chat_room_id=${chat_room_id}`)
    })
}

export const chatRoomTagSave = (data) => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/chat/chat_room_tag_save?token=asdf1234aaa`, data)
    })
}

export const chatRoomTagDelete = (chat_room_tag_id) => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/chat/chat_room_tag_delete?token=asdf1234aaa`, {'chat_room_tag_id':chat_room_tag_id, 'user_id':user_id})
    })
}

export const chatRoomTagGet = (chat_room_id) => {
    return getAuth().then((user_id) => {
        return axios.get(`${baseUrl}/api/chat/chat_room_tag_get?token=asdf1234aaa&chat_room_id=${chat_room_id}`)
    })
}

export const apiGetTagSuggestion = () => {
    return getAuth().then((user_id) => {
        return axios.get(`${baseUrl}/api/tag/get_tag_suggestion?token=asdf1234aaa`)
    })
}

export const fetchInviteFriend = (chat_room_id, user_id, start=0, limit=30, filter='') => {
    return  axios.get(`${baseUrl}/api/group/friend_list_for_invite?token=asdf1234aaa&chat_room_id=${chat_room_id}&user_id=${user_id}&start=${start}&limit=${limit}&filter=${filter}`)
}

export const inviteFriendToGroup = (chat_room_id, friend_user_id) => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/group/invite?token=asdf1234aaa`, {
            chat_room_id,
            user_id: user_id,
            friend_user_id
        })
    })
}

export const removeFriendFromGroup = (chat_room_id, friend_user_id) => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/group/remove_from_group?token=asdf1234aaa`, {
            chat_room_id,
            user_id: user_id,
            friend_user_id
        })
    })
}

export const groupAddAdmin = (chat_room_id, friend_user_id) => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/group/add_admin?token=asdf1234aaa`, {
            chat_room_id,
            user_id: user_id,
            friend_user_id
        })
    })
}

export const groupRemoveAdmin = (chat_room_id, friend_user_id) => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/group/remove_admin?token=asdf1234aaa`, {
            chat_room_id,
            user_id: user_id,
            friend_user_id
        })
    })
}

export const exitTheGroup = (chat_room_id) => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/group/exit_group?token=asdf1234aaa`, {
            chat_room_id,
            user_id: user_id
        })
    })
}

export const friendInGroup = (chat_room_id, start=0, limit=9999, filter='') => {
    return getAuth().then((user_id) => {
        return axios.get(`${baseUrl}/api/group/friend_list_for_remove?token=asdf1234aaa&chat_room_id=${chat_room_id}&user_id=${user_id}&start=${start}&limit=${limit}&filter=${filter}`)
    })
}

export const updateProfile = (data) => {
    return axios.post(`${baseUrl}/api/user/update_setting?token=asdf1234aaa`, data)
}

export const updatePictureAuth = (data) => {
    return  axios.post(`${baseUrl}/api/user/update_picture?token=asdf1234aaa`, data)
}

export const inviteFriendToGroupWithOpenCase = (data) => {
    return axios.post(`${baseUrl}/api/group/open_case?token=asdf1234aaa`, data)
}

export const inviteFriendToGroupWithConference = (data) => {
    return axios.post(`${baseUrl}/api/group/add_conference?token=asdf1234aaa`, data)
}

export const deleteConference = (data) => {
    return getAuth().then((user_id) => {
      data.user_id = user_id;
      return axios.post(`${baseUrl}/api/conference/delete_conference?token=asdf1234aaa`, data)
    })
}

export const createNewRoom = (friend_user_id) => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/chat/create?token=asdf1234aaa`, {
            user_id: user_id,
            friend_user_id
        })
    })
}

export const fetchKeepProfile = () => {
    return getAuth().then((user_id) => {
        return axios.get(`${baseUrl}/api/chat/get_keep_room_data?token=asdf1234aaa&user_id=${user_id}`)
    })
}

export const saveInKeep = (copy_chat_message_id) => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/message/keep?token=asdf1234aaa`, {
            user_id,
            chat_room_id: "",
            message_type: "",
            copy_chat_message_id,
            content: "",
            sticker_path: ""
        })
    })
}

export const apiUnsendMessage = (chat_message_id) => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/message/unsend?token=asdf1234aaa`, {
            user_id,
            chat_message_id,
        })
    })
}

export const apiDeleteMessageFromUserChatRoom = (chat_message_id) => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/message/delete_from_user_chat_room?token=asdf1234aaa`, {
            user_id,
            chat_message_id,
        })
    })
}

export const saveNotiToken = (token) => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/user/save_noti_android_token?token=asdf1234aaa`, {
            user_id,
            android_token: token
        })
    })
}

export const sendNoti = (data) => {
    return getAuth().then((user_id) => {
        return axios.post(`${baseUrl}/api/message/send_noti?token=asdf1234aaa`, data)
    })
}