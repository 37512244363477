import _ from 'lodash'
import React from 'react'
import moment from 'moment'

import { Switch, Route, Redirect } from 'react-router-dom'
import { ButtonToolbar, DropdownButton, MenuItem, Glyphicon, Button, Modal } from 'react-bootstrap'
import { onSearchChatList, onSearchChatListMore, onInviteFriendToGroupWithConference, isShowUserProfile, filterChatTag } from '../../redux/actions.js'
import { store } from '../../redux'

class ChatList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            chatLists: [],
            is_crm: false,
            user: null,
            filterRoomTypeName: 'All',
            filterRoomType: 'all',
            filterRoomTypeHos: 'all',
            filterRoomTypeHosName: 'ทุก รพ.',
            filterRoomText: '',
            isShowAddConferenceModal: false,
            add_conf_customer_name: '',
            isLoadingSelectChat: false,
            selectedChatRoomId:null,
            isShowLoadMoreChatList: true
        }

        this.navigateToChat = () => {
            props.navigateToChat()
        }

        this.goToContact = () => {
            props.goToContact()
        }

        this.goToChatList = () => {
            props.goToChatList()
        }        
    }

    componentWillReceiveProps() {
        if(_.get(this.props.data, 'chat.chatLists')) {
            this.setState({
                chatLists: this.props.data.chat.chatLists,
                chatListsClone: this.props.data.chat.chatLists
            })
        }
        this.setState({
          is_crm: _.get(this.props.data, 'user.user.is_crm', false),
          user: _.get(this.props.data, 'user.user', null),
          isLoadingSelectChat: _.get(this.props.data, 'system.isLoadingSelectChat', false),
          selectedChatRoomId: _.get(this.props.data, 'chat.chatInfo.chat_room_id', null),
          isShowLoadMoreChatList: _.get(this.props.data, 'system.isShowLoadMoreChatList', true),
        })

        var state = store.getState();
        if(_.get(state, 'system.filter_chat_tag','')!=''){
          this.setState({
            filterRoomText: _.get(state, 'system.filter_chat_tag',''),
          })
          store.dispatch(filterChatTag(''))
        }
    }

    renderChatLists = () => {
        const chatLists = this.state.chatLists.map((info) => {
            let myClass="row compose-sideBar";
            if(this.state.selectedChatRoomId==info.chat_room_id){
              myClass+=" selected";
            }
            return (
                <div key={info.chat_room_id} className={myClass} onClick={() => 
                  this.props.history.push('/chat/'  + info.chat_room_id)
                }>
                    <div className="row sideBar-body">
                        <div className="col-sm-2 col-xs-3 sideBar-avatar">
                            <div className="avatar-icon">
                                <div className="avatar-img" style={{backgroundImage:`url(${info.profile_pic_url})`}}></div>
                            </div>
                        </div>
                        <div className="col-sm-10 col-xs-9 sideBar-main">
                            <div className="row">
                                <div className="col-sm-8 col-xs-8 sideBar-name">
                                    <span className="name-meta"> { info.display_name }
                                    </span>
                                    {
                                      (_.get(info,'user_type_detail',null)!=null) && 
                                      <span className="name-meta" style={{fontSize:'12px',fontWeight:'bold'}}> { info.user_type_detail }
                                      </span>
                                    }
                                    <span className="status-meta"> { info.last_message }
                                    </span>

                                    {
                                      (_.get(info,'user_type_id',null)!=null) && (_.get(this.state.user,'user_role','')=='helpdesk') &&
                                      <span className="status-meta no-padding" style={{}}>
                                        {
                                          (_.get(info,'is_follow','T') =='T') &&
                                          <span className="badge" style={{fontSize: '12px', backgroundColor: 'green', width: 'auto', height: 'auto'}}>
                                          Follow
                                          </span>
                                        }
                                        {
                                          (_.get(info,'is_resolved','F') =='F') &&
                                          <span className="badge" style={{fontSize: '12px', backgroundColor: 'red', width: 'auto', height: 'auto'}}>
                                          Unresolved
                                          </span>
                                        }
                                      </span>
                                    }
                                </div>
                                <div className="col-sm-4 col-xs-4 pull-right sideBar-time">
                                    <span className="time-meta pull-right"> {info.last_chat?moment(info.last_chat).fromNow():''}
                                    </span>
                                    <span style={{position: 'absolute', width: 'auto', height: 'auto', right: '14px', top: '34px'}}>
                                      {
                                        (_.get(info,'is_mute','0') =='1') &&
                                        <i className="material-icons" style={{ color: 'grey', marginRight: '8px', verticalAlign:'middle', fontSize:'20px'}}>volume_off</i>
                                      }
                                      { info.unread_count!=null && info.unread_count > 0 &&
                                          <span className="badge" style={{fontSize: '14px', backgroundColor: 'red', width: 'auto', height: 'auto'}}>
                                          { info.unread_count }
                                          </span>
                                      }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return chatLists
    }


    onSearchChatListMore = (event) => {
      var filterRoomText = this.state.filterRoomText;
      var filterRoomType = this.state.filterRoomType;

      const state = store.getState()
      var chatLists1 = _.get(state, 'chat.chatLists', []);
      var lastChatRoom = chatLists1[chatLists1.length-1];
      var filterRoomTypeHos = this.state.filterRoomTypeHos;
      store.dispatch(onSearchChatListMore(filterRoomText, filterRoomType, lastChatRoom.last_chat, filterRoomTypeHos))
    }

    onSearchChatList = (event, room_type, room_type_name, new_text) => {
      var filterRoomText = '';
      if(event!=null){
        event.preventDefault()
        filterRoomText = event.target.value;
        this.setState({
            filterRoomText: event.target.value
        })
      }
      else if(new_text!=null){
        filterRoomText = new_text;
        this.setState({
            filterRoomText: new_text
        })
      }
      else{
        filterRoomText = this.state.filterRoomText;
      }

      var filterRoomType = 'all';
      var filterRoomTypeName = 'All';
      if(room_type!=null){
        filterRoomType = room_type;
        filterRoomTypeName = room_type_name;
        this.setState({
            filterRoomType, filterRoomTypeName
        })
      }
      else{
        filterRoomType = this.state.filterRoomType;
        filterRoomTypeName = this.state.filterRoomTypeName;
      }

      var filterRoomTypeHos = this.state.filterRoomTypeHos;
      console.log(filterRoomTypeHos);

      store.dispatch(onSearchChatList(filterRoomText, filterRoomType, filterRoomTypeHos))
    }

    addConferenceRoom = async() => {
      store.dispatch(onInviteFriendToGroupWithConference(null, [], [],this.state.add_conf_customer_name))
      this.setState({
        isShowAddConferenceModal: false,
      })
    }

    render = () => {
      let inputSearchPaddingLeft = 0;
      if(this.state.filterRoomType=='CONFERENCE'){
        inputSearchPaddingLeft = '155px'
      }
      else if(this.state.is_crm){
        inputSearchPaddingLeft = '100px'
      }

      let user_type_staff_list = _.get(this.state, 'user.user_type_staff_list', []);
      let user_type_manager_list = _.get(this.state, 'user.user_type_manager_list', []);
      let show_select_hospital = this.state.is_crm && (user_type_manager_list.length>0 || user_type_staff_list.length>0);

      let inputSearchPaddingRight = 0;
      if(show_select_hospital){
        inputSearchPaddingRight = '100px'
      }

        return (
            <div style={{ backgroundColor: 'white'}}>
                <div className="row heading hide-in-mobile" style={{ backgroundColor: '#3b5998' }}>
                    <div className="newMessage-title" style={{ color: 'white' }}>
                        Chats
                    </div>
                </div>

                <div className="row heading hide-in-pc" style={{ backgroundColor: '#3b5998', display: 'flex', position: 'fixed', left: '0', bottom: '0', width: '100%' }}>
                  
                    <div className=""  onClick={() => this.goToContact()}  style={{ width: 'auto', textAlign: 'center',  flex: '1', cursor: 'pointer', display: 'flex', flexDirection: 'column' }}>
                        <i className="fa fa-users fa-lg" aria-hidden="true" style={{ padding: '6px',fontSize: '25px !important', color: 'white'}}></i>
                        <span style={{ color: 'white', fontWeight: 'bold', height: 'auto', fontSize: '12px' }}>CONTACTS</span>
                    </div>
                    <div className="" style={{ width: 'auto', textAlign: 'center', flex: '1',  cursor: 'pointer', display: 'flex', flexDirection: 'column' }} onClick={() => this.goToChatList()}>
                        <i className="fa fa-comments fa-lg" aria-hidden="true" style={{ padding: '6px',fontSize: '25px !important', color: 'white'}} ></i>
                        <span style={{ color: 'white', fontWeight: 'bold', height: 'auto', fontSize: '12px' }}>CHAT LISTS</span>
                    </div>
                    <div className="" style={{ width: 'auto', textAlign: 'center', flex: '1',  cursor: 'pointer', display: 'flex', flexDirection: 'column' }} onClick={() => store.dispatch(isShowUserProfile(true))}>
                        <i className="fa fa-cog fa-lg" aria-hidden="true" style={{ padding: '6px', fontSize: '25px !important', color: 'white'}} ></i>
                        <span style={{ color: 'white', fontWeight: 'bold', height: 'auto', fontSize: '12px' }}>ACCOUNT</span>
                    </div>
                </div>
                <div className="row composeBox">
                  <div className="col-sm-12 composeBox-inner">
                    {this.state.is_crm && 
                    <>
                      <div style={{ width: 'auto', height: 'auto', position: 'absolute', paddingTop:'3px', zIndex:'1000' }}>
                        <ButtonToolbar>
                          <DropdownButton
                            bsSize="small"
                            title={this.state.filterRoomTypeName}
                            id="dropdown-size-small"
                            className="caret-left"
                            style={{border: '0', background: 'none', boxShadow: 'none'}}
                          >
                            {
                              this.state.user.see_customer &&
                              <MenuItem eventKey="CUSTOMER" onSelect={() => this.onSearchChatList(null,"CUSTOMER","Customer")}>Customer</MenuItem>
                            }
                            {
                              this.state.user.see_agent &&
                              <MenuItem eventKey="AGENT" onSelect={() => this.onSearchChatList(null,"AGENT","Agent")}>Agent</MenuItem>
                            }
                            <MenuItem eventKey="EMP" onSelect={() => this.onSearchChatList(null,"EMP","Employee")}>Employee</MenuItem>
                            <MenuItem divider />
                            <MenuItem eventKey="FOLLOW" onSelect={() => this.onSearchChatList(null,"FOLLOW","Follow")}>Follow</MenuItem>
                            <MenuItem eventKey="UNRESOLVED" onSelect={() => this.onSearchChatList(null,"UNRESOLVED","Unresolved")}>Unresolved</MenuItem>
                            <MenuItem eventKey="RESOLVED" onSelect={() => this.onSearchChatList(null,"RESOLVED","Resolved")}>Resolved</MenuItem>
                            <MenuItem divider />
                            <MenuItem eventKey="READ" onSelect={() => this.onSearchChatList(null,"READ","Read")}>Read</MenuItem>
                            <MenuItem eventKey="UNREAD" onSelect={() => this.onSearchChatList(null,"UNREAD","Unread")}>Unread</MenuItem>
                            <MenuItem divider />
                            <MenuItem eventKey="CONFERENCE" onSelect={() => this.onSearchChatList(null,"CONFERENCE","Conference")}>Conference</MenuItem>
                            <MenuItem divider />
                            <MenuItem eventKey="all" onSelect={() => this.onSearchChatList(null,"all","All")}>All</MenuItem>
                            
                          </DropdownButton>
                        </ButtonToolbar>
                      </div>
                      {
                        this.state.filterRoomType=='CONFERENCE' &&
                        <Button bsStyle="info" bsSize="small" className="pull-left" style={{ marginTop: '2px', marginLeft: '80px', position:'absolute', zIndex:'1000' }} onClick={() => {
                          this.setState({
                              isShowAddConferenceModal: true,
                              add_conf_customer_name: ''
                          })
                        }}>
                          <i className="fa fa-tv fa-lg" style={{ color: 'white', paddingRight: '3px' }}></i>
                          Add
                        </Button>
                      }
                    </>
                    }

                    {show_select_hospital &&
                    <>
                      <div style={{ width: 'auto', height: 'auto', position: 'absolute', paddingTop:'3px', zIndex:'1000', right:'2px' }}>
                        <ButtonToolbar>
                          <DropdownButton
                            bsSize="small"
                            title={this.state.filterRoomTypeHosName}
                            id="dropdown-size-small"
                            className="caret-right"
                            style={{border: '0', background: 'none', boxShadow: 'none'}}
                          >
                            {
                              (user_type_staff_list.indexOf('2')!=-1||user_type_manager_list.indexOf('2')!=-1) &&
                              <MenuItem eventKey="BPK1" onSelect={() => {
                                this.setState({
                                  'filterRoomTypeHos':'BPK1',
                                  'filterRoomTypeHosName':'BPK1'
                                },()=>{
                                  this.onSearchChatList(null)
                                });
                              }}>BPK1</MenuItem>
                            }

                            {
                              (user_type_staff_list.indexOf('3')!=-1||user_type_manager_list.indexOf('3')!=-1) &&
                              <MenuItem eventKey="BPK3" onSelect={() => {
                                this.setState({
                                  'filterRoomTypeHos':'BPK3',
                                  'filterRoomTypeHosName':'BPK3'
                                },()=>{
                                  this.onSearchChatList(null)
                                });
                              }}>BPK3</MenuItem>
                            }

                            {
                              (user_type_staff_list.indexOf('5')!=-1||user_type_manager_list.indexOf('5')!=-1) &&
                              <MenuItem eventKey="BPK8" onSelect={() => {
                                this.setState({
                                  'filterRoomTypeHos':'BPK8',
                                  'filterRoomTypeHosName':'BPK8'
                                },()=>{
                                  this.onSearchChatList(null)
                                });
                              }}>BPK8</MenuItem>
                            }

                            {
                              (user_type_staff_list.indexOf('6')!=-1||user_type_manager_list.indexOf('6')!=-1) &&
                              <MenuItem eventKey="BPK9" onSelect={() => {
                                this.setState({
                                  'filterRoomTypeHos':'BPK9',
                                  'filterRoomTypeHosName':'BPK9'
                                },()=>{
                                  this.onSearchChatList(null)
                                });
                              }}>BPK9</MenuItem>
                            }

                            {
                              (user_type_staff_list.indexOf('8')!=-1||user_type_manager_list.indexOf('8')!=-1) &&
                              <MenuItem eventKey="BPKR2" onSelect={() => {
                                this.setState({
                                  'filterRoomTypeHos':'BPKR2',
                                  'filterRoomTypeHosName':'BPK รังสิต2'
                                },()=>{
                                  this.onSearchChatList(null)
                                });
                              }}>BPK รังสิต2</MenuItem>
                            }

                            {
                              (user_type_staff_list.indexOf('20')!=-1||user_type_manager_list.indexOf('20')!=-1) &&
                              <MenuItem eventKey="BPKS" onSelect={() => {
                                this.setState({
                                  'filterRoomTypeHos':'BPKS',
                                  'filterRoomTypeHosName':'BPK สมุทรฯ'
                                },()=>{
                                  this.onSearchChatList(null)
                                });
                              }}>BPK สมุทรปราการ</MenuItem>
                            }

                            {
                              (user_type_staff_list.indexOf('7')!=-1||user_type_manager_list.indexOf('7')!=-1
                              ||user_type_staff_list.indexOf('10')!=-1||user_type_manager_list.indexOf('10')!=-1) &&
                              <MenuItem eventKey="PYH" onSelect={() => {
                                this.setState({
                                  'filterRoomTypeHos':'PYH',
                                  'filterRoomTypeHosName':'PYH'
                                },()=>{
                                  this.onSearchChatList(null)
                                });
                              }}>PYH</MenuItem>
                            }

                            <MenuItem divider />
                            {
                              <MenuItem eventKey="all" onSelect={() => {
                                this.setState({
                                  'filterRoomTypeHos':'all',
                                  'filterRoomTypeHosName':'ทุก รพ.'
                                },()=>{
                                  this.onSearchChatList(null)
                                });
                              }}>ทุก รพ.</MenuItem>
                            }
                            
                          </DropdownButton>
                        </ButtonToolbar>
                      </div>
                    </>
                    }

                    <form onSubmit={(event) => {
                        event.preventDefault();
                        this.onSearchChatList(null)
                      }}>
                      <div className="input-group" style={{paddingLeft:`${inputSearchPaddingLeft}`,paddingRight:`${inputSearchPaddingRight}`}}>
                        <input id="composeText" type="text" className="form-control" name="searchText" placeholder="Search" value={this.state.filterRoomText} aria-describedby="basic-addon1" onChange={(event) => this.setState({filterRoomText: event.target.value})} />
                        <div className="input-group-btn">
                          <button className="btn btn-default" type="button" onClick={(event) => this.onSearchChatList(null)}>
                            <i className="fa fa-search"></i>
                          </button>
                          <button className="btn btn-default" type="button" onClick={(event) => this.onSearchChatList(null,null,null,'')}>
                            <i className="fa fa-remove"></i>
                          </button>
                        </div>
                      </div>
                    </form>

                  </div>
                </div>
                <div className="row sideBar" id="div-chatlist">
                    {
                      this.renderChatLists()
                    }
                    {
                      this.state.isLoadingSelectChat &&
                      <div style={{position:'absolute',top:'0',left:'0',backgroundColor: 'rgba(0,0,0,0.2)', width:'100%', height:'100%'}}></div>
                    }

                    {
                      this.state.chatLists.length>0 && this.state.isShowLoadMoreChatList &&
                      <div className="row compose-sideBar" style={{textAlign:'center',border:'0'}}>
                        <button style={{margin:'5px'}} className="btn btn-default" type="button" onClick={(event) => this.onSearchChatListMore(null)}>
                          Load More
                        </button>
                      </div>
                    }
                </div>

                <div className="height-auto">
                    <div className="static-modal">
                        <Modal show={this.state.isShowAddConferenceModal} onHide={() => {
                                this.setState({
                                    isShowAddConferenceModal: false
                                })
                            }}>
                            <Modal.Header style={{ backgroundColor: '#eee' }}>
                                <Modal.Title>Add Conference</Modal.Title>
                            </Modal.Header>
                            <div>

                                <div style={{ display: 'flex', paddingTop: '15px', paddingBottom: '15px' }}>
                                    <div style={{ width: '200px', textAlign: 'center'  }}>
                                        <i className="fa fa-user fa-2x" aria-hidden="true" style={{ padding: '10px', paddingLeft: '35px' }}></i>
                                    </div>
                                    <div>
                                        <div className="form-group col-md-12">
                                            <label>Customer</label>
                                            <input type="text" className="form-control" placeholder="Customer Name" value={this.state.add_conf_customer_name}  onChange={(event) => this.setState({add_conf_customer_name: event.target.value})} />
                                        </div>
                                        <div className="clearfix"></div>
                                        <div style={{ borderBottom: '1px solid #dfdfdf', marginTop: '10px' }} />
                                    </div>
                                </div>
                            </div>
                            <Modal.Footer>
                                <Button onClick={() => {
                                    this.setState({
                                        isShowAddConferenceModal: false
                                    })
                                }}>Close</Button>
                                <Button className="btn btn-primary" onClick={() => {
                                    this.addConferenceRoom()
                                }}>Save</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        )
    }
}

export default ChatList
