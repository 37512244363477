import _ from 'lodash'
import React from 'react'
import moment from 'moment'

import { Switch, Route, Redirect } from 'react-router-dom'
import { ButtonToolbar, DropdownButton, MenuItem, Glyphicon, Button, Modal } from 'react-bootstrap'
import { apiGetQuickReplyList, apiSaveQuickReply, apiDeleteQuickReply } from '../../redux/api.js'
import { store } from '../../redux'

class QuickReplyList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            quickReplyLists: [],
            user: null,
            filterQuickReplyText: '',
            isShowAddQuickReplyModal: false,
            add_quick_reply_text: '',
            selectedQuickReply: null,
            isShowConfirmDelete: false
        }
    }   

    componentDidMount() {
      this.getQuickReplyList()
    }

    componentWillReceiveProps() {
        this.setState({
          user: _.get(this.props.data, 'user.user', null)
        })
    }

    getQuickReplyList = async(event) => {
      var filterQuickReplyText = '';
      if(event!=null){
        event.preventDefault()
        filterQuickReplyText = event.target.value;
        this.setState({
          filterQuickReplyText: event.target.value
        })
      }
      else{
        filterQuickReplyText = this.state.filterQuickReplyText;
      }

      var res = await apiGetQuickReplyList(filterQuickReplyText);
      this.setState({
        quickReplyLists: _.get(res, 'data.quick_reply_list', [])
      });
    }

    onEditQuickReply = (quick_reply) => {
      this.setState({
          selectedQuickReply: quick_reply,
          add_quick_reply_text: quick_reply.text,
          isShowAddQuickReplyModal: true
      })
    }

    onAddQuickReply = () => {
      this.setState({
          selectedQuickReply: null,
          add_quick_reply_text: '',
          isShowAddQuickReplyModal: true
      })
    }

    deleteQuickReply = async() => { 
      var selectedQuickReply = this.state.selectedQuickReply;
      var quick_reply_text = selectedQuickReply.text;
      var data = {
        text : quick_reply_text,
      }

      const res = await apiDeleteQuickReply(data);
      if(res.data.error!=''){
        alert(res.data.error);
      }
      else{
        var quickReplyLists = this.state.quickReplyLists;
        var deleteInd = -1;

        for(var i in quickReplyLists){
          if(quickReplyLists[i].text==quick_reply_text){
            deleteInd = i;
          }
        }

        if (deleteInd !== -1) {
          quickReplyLists.splice(deleteInd, 1);
        }

        this.setState({
          quickReplyLists: quickReplyLists,
          isShowAddQuickReplyModal: false,
          isShowConfirmDelete: false,
        });
      }
    }

    saveQuickReply = async() => {     
      var selectedQuickReply = this.state.selectedQuickReply;
      var new_quick_reply = this.state.add_quick_reply_text;
      var data = {
        old_quick_reply : (selectedQuickReply!=null?selectedQuickReply.text:''),
        new_quick_reply : new_quick_reply,
      }

      const res = await apiSaveQuickReply(data);
      if(res.data.error!=''){
        alert(res.data.error);
      }
      else{
        var quickReplyLists = this.state.quickReplyLists;

        if(selectedQuickReply!=null){
          for(var i in quickReplyLists){
            if(quickReplyLists[i].text==selectedQuickReply.text){
              quickReplyLists[i].text = new_quick_reply;
            }
          }
        }
        else{
          quickReplyLists.push({'text':new_quick_reply});
        }

        this.setState({
          quickReplyLists: quickReplyLists,
          isShowAddQuickReplyModal: false,
        });
      }
    }

    renderQuickReplyLists = () => {
        const quickReplyLists = this.state.quickReplyLists.map((info) => {
            return (
                <div key={info.text} className="row compose-sideBar row-quick_reply" onClick={(e) => {
                  this.onEditQuickReply(info);
                }}>
                    <div className="row sideBar-body">
                        <div className="col-sm-12 col-xs-12 sideBar-main">
                            <div className="row">
                                <div className="col-sm-8 col-xs-8 sideBar-name">
                                    <span className="name-meta" style={{whiteSpace:'pre-line'}}> { info.text }
                                    </span>
                                </div>
                                <div className="col-sm-4 col-xs-4 pull-right sideBar-time">
                                    <span style={{ float:'right', width: 'auto', height: 'auto', marginRight: '14px'}}>
                                      <i className="material-icons" style={{ color: 'grey', marginRight: '8px', verticalAlign:'middle', fontSize:'20px'}} onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({
                                          selectedQuickReply: info,
                                          isShowConfirmDelete: true
                                        })
                                      }}>delete_outline</i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return quickReplyLists
    }

    render = () => {
        return (
            <div style={{ backgroundColor: 'white'}}>
                <div className="row heading hide-in-mobile" style={{ backgroundColor: '#3b5998' }}>
                    <div className="newMessage-title" style={{ color: 'white' }}>
                        Quick Reply
                    </div>
                </div>

                <div className="row composeBox">
                  <div className="col-sm-12 composeBox-inner">
                    <Button bsStyle="info" bsSize="small" className="pull-left" style={{ marginTop: '2px', marginLeft: '0', position:'absolute', zIndex:'1000' }} onClick={() => {
                      this.onAddQuickReply();
                    }}>
                      <i className="fa fa-plus fa-lg" style={{ color: 'white', paddingRight: '3px' }}></i>
                      Add
                    </Button>
                    <div className="form-group has-feedback" style={{paddingLeft:'70px'}}>
                        <input id="composeText" type="text" className="form-control" name="searchText" placeholder="Search" value={this.state.filter} aria-describedby="basic-addon1" onChange={(event) => this.getQuickReplyList(event)} />
                        <span className="glyphicon glyphicon-search form-control-feedback"></span>
                    </div>
                  </div>
                </div>
                <div className="row sideBar">
                    {
                        this.renderQuickReplyLists()
                    }
                </div>

                <div className="height-auto">
                    <div className="static-modal">
                        <Modal show={this.state.isShowAddQuickReplyModal} onHide={() => {
                                this.setState({
                                    isShowAddQuickReplyModal: false
                                })
                            }}>
                            <Modal.Header style={{ backgroundColor: '#eee' }}>
                              {
                                this.state.selectedQuickReply==null && 
                                <Modal.Title>Add Quick Reply</Modal.Title>
                              }
                              {
                                this.state.selectedQuickReply!=null && 
                                <Modal.Title>Edit Quick Reply</Modal.Title>
                              }
                            </Modal.Header>
                            <div>

                                <div style={{ display: 'flex', paddingTop: '15px', paddingBottom: '15px' }}>
                                    <div style={{ width: '200px', textAlign: 'center'  }}>
                                        <i className="fa fa-edit fa-2x" aria-hidden="true" style={{ padding: '30px 10px 10px 35px' }}></i>
                                    </div>
                                    <div>
                                        <div className="form-group col-md-12">
                                            <label>Quick Reply</label>
                                            <textarea type="text" className="form-control" placeholder="New Quick Reply" onChange={(event) => this.setState({add_quick_reply_text: event.target.value})}>
                                              {this.state.add_quick_reply_text}
                                            </textarea>
                                        </div>
                                        <div className="clearfix"></div>
                                        <div style={{ borderBottom: '1px solid #dfdfdf', marginTop: '10px' }} />
                                    </div>
                                </div>
                            </div>
                            <Modal.Footer>
                                <Button onClick={() => {
                                    this.setState({
                                        isShowAddQuickReplyModal: false
                                    })
                                }}>Close</Button>
                                <Button className="btn btn-primary" onClick={() => {
                                    this.saveQuickReply()
                                }}>Save</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>


                <div className="height-auto">
                    <div className="static-modal">
                        <Modal show={this.state.isShowConfirmDelete} onHide={() => {
                                this.setState({
                                    isShowConfirmDelete: false
                                })
                            }}>
                            <Modal.Header style={{ backgroundColor: '#eee' }}>
                              <Modal.Title>Confirm Delete</Modal.Title>
                            </Modal.Header>
                            <div style={{padding:20}}>
                              <b>Are you sure you want to remove this?</b>
                            </div>
                            <Modal.Footer>
                                <Button onClick={() => {
                                    this.setState({
                                        isShowConfirmDelete: false
                                    })
                                }}>Close</Button>
                                <Button className="btn btn-primary" onClick={() => {
                                    this.deleteQuickReply()
                                }}>Confirm</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        )
    }
}

export default QuickReplyList
