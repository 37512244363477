import React from 'react'

class Header extends React.Component {
    render() {
        return (
            <div />

        )
    }
}

export default Header
