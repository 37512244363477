import _ from 'lodash'
import React from 'react'
import moment from 'moment'

import { Switch, Route, Redirect } from 'react-router-dom'
import { ButtonToolbar, DropdownButton, MenuItem, Glyphicon, Button, Modal } from 'react-bootstrap'
import { apiGetTagList, apiSaveTag, apiDeleteTag } from '../../redux/api.js'
import { store } from '../../redux'

class TagList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tagLists: [],
            user: null,
            filterTagText: '',
            isShowAddTagModal: false,
            add_tag_name: '',
            selectedTag: null,
            isShowConfirmDelete: false
        }   

        this.goToChatList = (filter='') => {
          props.goToChatList(filter)
        }    
    }   

    componentDidMount() {
      this.getTagList()
    }

    componentWillReceiveProps() {
        this.setState({
          user: _.get(this.props.data, 'user.user', null)
        })
    }

    getTagList = async(event) => {
      var filterTagText = '';
      if(event!=null){
        event.preventDefault()
        filterTagText = event.target.value;
        this.setState({
            filterTagText: event.target.value
        })
      }
      else{
        filterTagText = this.state.filterTagText;
      }

      var res = await apiGetTagList(filterTagText);
      this.setState({
        tagLists: _.get(res, 'data.tags', [])
      });
    }

    onEditTag = (tag) => {
      this.setState({
          selectedTag: tag,
          add_tag_name: tag.tag,
          isShowAddTagModal: true
      })
    }

    onAddTag = () => {
      this.setState({
          selectedTag: null,
          add_tag_name: '',
          isShowAddTagModal: true
      })
    }

    deleteTag = async() => { 
      var selectedTag = this.state.selectedTag;
      var tag_name = selectedTag.tag;
      var data = {
        tag : tag_name,
      }

      const res = await apiDeleteTag(data);
      if(res.data.error!=''){
        alert(res.data.error);
      }
      else{
        var tagLists = this.state.tagLists;
        var deleteInd = -1;

        for(var i in tagLists){
          if(tagLists[i].tag==tag_name){
            deleteInd = i;
          }
        }

        if (deleteInd !== -1) {
          tagLists.splice(deleteInd, 1);
        }

        this.setState({
          tagLists: tagLists,
          isShowAddTagModal: false,
          isShowConfirmDelete: false,
        });
      }
    }

    saveTag = async() => {     
      var selectedTag = this.state.selectedTag;
      var new_tag = this.state.add_tag_name;
      var data = {
        old_tag : (selectedTag!=null?selectedTag.tag:''),
        new_tag : new_tag,
      }

      const res = await apiSaveTag(data);
      if(res.data.error!=''){
        alert(res.data.error);
      }
      else{
        var tagLists = this.state.tagLists;

        if(selectedTag!=null){
          for(var i in tagLists){
            if(tagLists[i].tag==selectedTag.tag){
              tagLists[i].tag = new_tag;
            }
          }
        }
        else{
          tagLists.push({'tag':new_tag,'count':0});
        }

        this.setState({
          tagLists: tagLists,
          isShowAddTagModal: false,
        });
      }
    }

    renderTagLists = () => {
        const tagLists = this.state.tagLists.map((info) => {
            return (
                <div key={info.tag} className="row compose-sideBar row-tag" onClick={(e) => {
                  this.goToChatList(info.tag);
                }}>
                    <div className="row sideBar-body">
                        <div className="col-sm-12 col-xs-12 sideBar-main">
                            <div className="row">
                                <div className="col-sm-8 col-xs-8 sideBar-name">
                                    <span className="name-meta"> { info.tag }
                                    </span>
                                </div>
                                <div className="col-sm-4 col-xs-4 pull-right sideBar-time">
                                    <span style={{ float:'right', width: 'auto', height: 'auto', marginRight: '14px'}}>
                                      <i className="material-icons" style={{ color: 'grey', marginRight: '8px', verticalAlign:'middle', fontSize:'20px'}} onClick={(e) => {
                                        e.stopPropagation();
                                        this.onEditTag(info);
                                      }}>edit</i>
                                      <i className="material-icons" style={{ color: 'grey', marginRight: '8px', verticalAlign:'middle', fontSize:'20px'}} onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({
                                          selectedTag: info,
                                          isShowConfirmDelete: true
                                        })
                                      }}>delete_outline</i>
                                      <span className="badge" style={{fontSize: '14px', backgroundColor: `${info.count>0?'green':'grey'}`, width: 'auto', height: 'auto'}}>
                                      { info.count }
                                      </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return tagLists
    }

    render = () => {
        return (
            <div style={{ backgroundColor: 'white'}}>
                <div className="row heading hide-in-mobile" style={{ backgroundColor: '#3b5998' }}>
                    <div className="newMessage-title" style={{ color: 'white' }}>
                        Tags
                    </div>
                </div>

                <div className="row composeBox">
                  <div className="col-sm-12 composeBox-inner">
                    <Button bsStyle="info" bsSize="small" className="pull-left" style={{ marginTop: '2px', marginLeft: '0', position:'absolute', zIndex:'1000' }} onClick={() => {
                      this.onAddTag();
                    }}>
                      <i className="fa fa-plus fa-lg" style={{ color: 'white', paddingRight: '3px' }}></i>
                      Add
                    </Button>
                    <div className="form-group has-feedback" style={{paddingLeft:'70px'}}>
                        <input id="composeText" type="text" className="form-control" name="searchText" placeholder="Search" value={this.state.filter} aria-describedby="basic-addon1" onChange={(event) => this.getTagList(event)} />
                        <span className="glyphicon glyphicon-search form-control-feedback"></span>
                    </div>
                  </div>
                </div>
                <div className="row sideBar">
                    {
                        this.renderTagLists()
                    }
                </div>

                <div className="height-auto">
                    <div className="static-modal">
                        <Modal show={this.state.isShowAddTagModal} onHide={() => {
                                this.setState({
                                    isShowAddTagModal: false
                                })
                            }}>
                            <Modal.Header style={{ backgroundColor: '#eee' }}>
                              {
                                this.state.selectedTag==null && 
                                <Modal.Title>Add Tag</Modal.Title>
                              }
                              {
                                this.state.selectedTag!=null && 
                                <Modal.Title>Edit Tag</Modal.Title>
                              }
                            </Modal.Header>
                            <div>

                                <div style={{ display: 'flex', paddingTop: '15px', paddingBottom: '15px' }}>
                                    <div style={{ width: '200px', textAlign: 'center'  }}>
                                        <i className="fa fa-tag fa-2x" aria-hidden="true" style={{ padding: '30px 10px 10px 35px' }}></i>
                                    </div>
                                    <div>
                                        <div className="form-group col-md-12">
                                            <label>Tag</label>
                                            <input type="text" className="form-control" placeholder="New Tag" value={this.state.add_tag_name}  onChange={(event) => this.setState({add_tag_name: event.target.value})} />
                                        </div>
                                        <div className="clearfix"></div>
                                        <div style={{ borderBottom: '1px solid #dfdfdf', marginTop: '10px' }} />
                                    </div>
                                </div>
                            </div>
                            <Modal.Footer>
                                <Button onClick={() => {
                                    this.setState({
                                        isShowAddTagModal: false
                                    })
                                }}>Close</Button>
                                <Button className="btn btn-primary" onClick={() => {
                                    this.saveTag()
                                }}>Save</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>


                <div className="height-auto">
                    <div className="static-modal">
                        <Modal show={this.state.isShowConfirmDelete} onHide={() => {
                                this.setState({
                                    isShowConfirmDelete: false
                                })
                            }}>
                            <Modal.Header style={{ backgroundColor: '#eee' }}>
                              <Modal.Title>Confirm Delete</Modal.Title>
                            </Modal.Header>
                            <div style={{padding:20}}>
                              <b>Are you sure you want to remove this?</b>
                            </div>
                            <Modal.Footer>
                                <Button onClick={() => {
                                    this.setState({
                                        isShowConfirmDelete: false
                                    })
                                }}>Close</Button>
                                <Button className="btn btn-primary" onClick={() => {
                                    this.deleteTag()
                                }}>Confirm</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        )
    }
}

export default TagList
