import _ from 'lodash'
import React from 'react'
import { Switch, Route, Redirect, Link } from 'react-router-dom'
import Friend from '../Friend'

import { enterContacts, callDialog, isShowGroupSetting, startCall, showOrHideFriendLists, onLoadMore
  , isShowUserProfile, isShowProfileModal, onSearchFriend, onSelectKeep, navigate } from '../../redux/actions.js'
import { store } from '../../redux'

class Contact extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            is_show_favorite: true,
            is_show_group: true,
            is_show_department: true,
            is_show_customer: true,
            is_show_other: true,
            numberOfFriendLists: {
                favorite: 0,
                other: 0,
                group: 0,
                department: 0,
                customer: 0
            },
            friends: {
                favorite: [],
                other: [],
                group: [],
                department: [],
                customer: []
            },
            filter: '',
            hide_logout: 'F'
        }

        this.navigateToChat = () => {
            props.navigateToChat()
        }

        this.goToContact = () => {
            props.goToContact()
        }

        this.goToChatList = () => {
            props.goToChatList()
        }    
    }

    componentDidMount() {
        store.dispatch(navigate(this.props.history))
        this.props.onRef(this)
    }

    toggleFavorite = () => {
        this.setState({
            is_show_favorite: !this.state.is_show_favorite
        })
    }

    toggleGroup = () => {
        this.setState({
            is_show_group: !this.state.is_show_group
        })
    }

    toggleDepartment = () => {
        this.setState({
            is_show_department: !this.state.is_show_department
        })
    }

    toggleCustomer = () => {
        this.setState({
            is_show_customer: !this.state.is_show_customer
        })
    }

    toggleOther = () => {
        this.setState({
            is_show_other: !this.state.is_show_other
        })
    }
    
    componentWillReceiveProps() {
        if(_.get(this.props.data, 'friend.numberOfFriendLists')) {
            this.setState({
                numberOfFriendLists: this.props.data.friend.numberOfFriendLists
            })
        }

        if(_.get(this.props.data, 'friend.friends')) {
            this.setState({
                friends: this.props.data.friend.friends
            })
        }

        if(_.get(this.props.data, 'user.user')) {
            this.setState({
                user: this.props.data.user.user
            })
        }
        
        this.setState({
            hide_logout: _.get(this.props.data, 'system.hide_logout','F')
        })
    }
    
    loadmore = (group) => {
        store.dispatch(onLoadMore(group))
    }

    renderFriend = (friends) => {
        return friends.map((friend, key) => {
            return (
                <div className="box" key={key} onClick={() => {
                  store.dispatch(isShowProfileModal(true,friend))
                }}>
                  <Friend image={friend.profile_pic_url} name={friend.display_name} status={friend.status_quote} />
                </div>
            )
        })
    }

    onSearchFriend = (e) => {
        e.preventDefault()
        store.dispatch(onSearchFriend(this.state.filter))
    }

    protectParentOnclick = (e) => {
        e.stopPropagation()
    }

    signout = () => {
        localStorage.clear()
        // location.reload()
        window.location = '/';
    }

    render = () => {
        return (
            <div>
                <div className="row heading hide-in-pc" style={{ backgroundColor: '#3b5998', display: 'flex' }}>
                    <div className="heading-avatar" style={{ width: 'auto' }}>
                        <div className="heading-avatar-icon" onClick={() => {
                                store.dispatch(isShowProfileModal(true,this.state.user))
                            }}>
                            <img src={ _.get(this.state, 'user.profile_pic_url') } style={{ border: '0.5px solid black'}} />
                        </div>
                    </div>
                    <div className="" style={{ width: 'auto', flex: '1', marginLeft: '5px', display: 'inline-block', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        <a style={{ color: 'white', padding: '10px', display: 'inline-block', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}  className="heading-name-meta" onClick={() => {
                                store.dispatch(isShowProfileModal(true,this.state.user))
                            }}>{ _.get(this.state, 'user.display_name') } 
                        </a>
                    </div>
                    { 
                        this.state.hide_logout!='T' &&
                        <div className="" style={{ width: 'auto', padding: '15px', paddingRight: '2px', cursor: 'pointer' }}>
                            <i className="fa fa-sign-out fa-lg pull-right" aria-hidden="true" onClick={() => this.signout()} style={{ color: 'white'}}></i>
                        </div>
                    }
                    <div className="hide-in-mobile" style={{ width: 'auto', padding: '15px', paddingRight: '2px', cursor: 'pointer' }}>
                        <i className="fa fa-comments fa-lg  pull-right" aria-hidden="true" style={{ color: 'white'}} onClick={() => this.navigateToChat()}></i>
                    </div>
                </div>

                <div className="row heading hide-in-mobile" style={{ backgroundColor: '#3b5998' }}>
                    <div className="newMessage-title" style={{ color: 'white' }}>
                        Contacts
                    </div>
                </div>

                <div className="row heading hide-in-pc" style={{ backgroundColor: '#3b5998', display: 'flex', position: 'fixed', left: '0', bottom: '0', width: '100%' }}>
                  
                    <div className=""  onClick={() => this.goToContact()}  style={{ width: 'auto', textAlign: 'center',  flex: '1', cursor: 'pointer', display: 'flex', flexDirection: 'column' }}>
                        <i className="fa fa-users fa-lg" aria-hidden="true" style={{ padding: '6px',fontSize: '25px !important', color: 'white'}}></i>
                        <span style={{ color: 'white', fontWeight: 'bold', height: 'auto', fontSize: '12px' }}>CONTACTS</span>
                    </div>
                    <div className="" style={{ width: 'auto', textAlign: 'center', flex: '1',  cursor: 'pointer', display: 'flex', flexDirection: 'column' }} onClick={() => this.goToChatList()}>
                        <i className="fa fa-comments fa-lg" aria-hidden="true" style={{ padding: '6px',fontSize: '25px !important', color: 'white'}} ></i>
                        <span style={{ color: 'white', fontWeight: 'bold', height: 'auto', fontSize: '12px' }}>CHAT LISTS</span>
                    </div>
                    <div className="" style={{ width: 'auto', textAlign: 'center', flex: '1',  cursor: 'pointer', display: 'flex', flexDirection: 'column' }} onClick={() => store.dispatch(isShowUserProfile(true))}>
                        <i className="fa fa-cog fa-lg" aria-hidden="true" style={{ padding: '6px', fontSize: '25px !important', color: 'white'}} ></i>
                        <span style={{ color: 'white', fontWeight: 'bold', height: 'auto', fontSize: '12px' }}>ACCOUNT</span>
                    </div>
                </div>

                <div className="row searchBox">
                    <form onSubmit={this.onSearchFriend}>
                    <div className="col-sm-12 searchBox-inner">
                        <div className="input-group">
                            <input type="text" style={{ height: '35px' }} className="form-control" placeholder="Search" value={this.state.filter} aria-describedby="basic-addon1" onChange={(event) => this.setState({filter: event.target.value})} />
                            <a className="input-group-addon" style={{ cursor: 'pointer' }} onClick={() =>  store.dispatch(onSearchFriend(this.state.filter)) }>
                                <i className='fa fa-search' aria-hidden="true"></i>
                            </a>
                            <a className="input-group-addon" style={{ cursor: 'pointer' }} onClick={() =>  {
                                    this.setState({filter: ''});
                                    store.dispatch(onSearchFriend(''));
                                }}>
                                <i className='fa fa-remove' aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                    </form>
                </div>

                <div className="row sideBar sideBar-contact">
                    <div style={{ height: '46px', padding: '10px', borderBottom: '0.5px solid #ccc', backgroundColor: '#fbfbfb' }}>
                        <div className="col-sm-5 col-xs-5 heading-avatar">
                            <a className="heading-name-meta">Favorites ({ _.get(this.state.numberOfFriendLists, 'favorite', 0) })</a>                        
                        </div>
                        <div className="col-sm-2 col-xs-2 heading-compose  pull-right">
                            <i className={!this.state.is_show_favorite? 'fa fa-toggle-down  pull-right': 'hide'} aria-hidden="true" onClick={() => this.toggleFavorite()}></i>
                            <i className={this.state.is_show_favorite? 'fa fa-toggle-up  pull-right': 'hide'} aria-hidden="true" onClick={() => this.toggleFavorite()}></i>
                        </div>
                    </div>
                    <div className={this.state.is_show_favorite? 'show': 'hide'}>
                        {
                            this.renderFriend(this.state.friends.favorite)
                        }
                        <div onClick={() => this.loadmore('favorite')}  className={_.get(this.state.numberOfFriendLists, 'favorite', 0) > this.state.friends.favorite.length? 'row message-previous': 'hide'}>
                            <div className="col-sm-12 previous">
                                <a name="20">
                                    LOAD MORE
                                </a>
                            </div>
                        </div>
                    </div>

                    <div style={{ height: '46px', padding: '10px', borderBottom: '0.5px solid #ccc', backgroundColor: '#fbfbfb' }}>
                        <div className="col-sm-5 col-xs-5 heading-avatar">
                            <a className="heading-name-meta">Groups ({this.state.numberOfFriendLists.group})</a>                        
                        </div>
                        <div className="col-sm-2 col-xs-2 heading-compose  pull-right">
                            <i className={!this.state.is_show_group? 'fa fa-toggle-down  pull-right': 'hide'} aria-hidden="true" onClick={() => this.toggleGroup()}></i>
                            <i className={this.state.is_show_group? 'fa fa-toggle-up  pull-right': 'hide'} aria-hidden="true" onClick={() => this.toggleGroup()}></i>
                        </div>
                    </div>
                    <div className={this.state.is_show_group? 'show': 'hide'}>
                        {
                            this.renderFriend(this.state.friends.group)
                        }
                        <div onClick={() => this.loadmore('group')} className={this.state.numberOfFriendLists.group > this.state.friends.group.length? 'row message-previous': 'hide'}>
                            <div className="col-sm-12 previous">
                                <a name="20">
                                    LOAD MORE
                                </a>
                            </div>
                        </div>
                    </div>
                    
                    <div style={{ height: '46px', padding: '10px', borderBottom: '0.5px solid #ccc', backgroundColor: '#fbfbfb' }}>
                        <div className="col-sm-5 col-xs-5 heading-avatar">
                            <a className="heading-name-meta">Departments ({this.state.numberOfFriendLists.department})</a>                        
                        </div>
                        <div className="col-sm-2 col-xs-2 heading-compose  pull-right">
                            <i className={!this.state.is_show_department? 'fa fa-toggle-down  pull-right': 'hide'} aria-hidden="true" onClick={() => this.toggleDepartment()}></i>
                            <i className={this.state.is_show_department? 'fa fa-toggle-up  pull-right': 'hide'} aria-hidden="true" onClick={() => this.toggleDepartment()}></i>
                        </div>
                    </div>
                    <div className={this.state.is_show_department? 'show': 'hide'}>
                        {
                            this.renderFriend(this.state.friends.department)
                        }
                        <div onClick={() => this.loadmore('department')} className={this.state.numberOfFriendLists.department > this.state.friends.department.length? 'row message-previous': 'hide'}>
                            <div className="col-sm-12 previous">
                                <a name="20">
                                    LOAD MORE
                                </a>
                            </div>
                        </div>
                    </div>
                    
                    <div style={{ height: '46px', padding: '10px', borderBottom: '0.5px solid #ccc', backgroundColor: '#fbfbfb' }}>
                        <div className="col-sm-5 col-xs-5 heading-avatar">
                            <a className="heading-name-meta">Customers ({this.state.numberOfFriendLists.customer})</a>                        
                        </div>
                        <div className="col-sm-2 col-xs-2 heading-compose  pull-right">
                            <i className={!this.state.is_show_customer? 'fa fa-toggle-down  pull-right': 'hide'} aria-hidden="true" onClick={() => this.toggleCustomer()}></i>
                            <i className={this.state.is_show_customer? 'fa fa-toggle-up  pull-right': 'hide'} aria-hidden="true" onClick={() => this.toggleCustomer()}></i>
                        </div>
                    </div>
                    <div className={this.state.is_show_customer? 'show': 'hide'}>
                        {
                            this.renderFriend(this.state.friends.customer)
                        }
                        <div onClick={() => this.loadmore('customer')} className={this.state.numberOfFriendLists.customer > this.state.friends.customer.length? 'row message-previous': 'hide'}>
                            <div className="col-sm-12 previous">
                                <a name="20">
                                    LOAD MORE
                                </a>
                            </div>
                        </div>
                    </div>

                    <div style={{ height: '46px', padding: '10px', borderBottom: '0.5px solid #ccc', backgroundColor: '#fbfbfb' }}>
                        <div className="col-sm-5 col-xs-5 heading-avatar">
                            <a className="heading-name-meta">Others ({this.state.numberOfFriendLists.other})</a>                        
                        </div>
                        <div className="col-sm-2 col-xs-2 heading-compose  pull-right">
                            <i className={!this.state.is_show_other? 'fa fa-toggle-down  pull-right': 'hide'} aria-hidden="true" onClick={() => this.toggleOther()}></i>
                            <i className={this.state.is_show_other? 'fa fa-toggle-up  pull-right': 'hide'} aria-hidden="true" onClick={() => this.toggleOther()}></i>
                        </div>
                    </div>
                    <div className={this.state.is_show_other? 'show': 'hide'}>
                        {
                            this.renderFriend(this.state.friends.other)
                        }
                        <div onClick={() => this.loadmore('other')} className={this.state.numberOfFriendLists.other > this.state.friends.other.length? 'row message-previous': 'hide'}>
                            <div className="col-sm-12 previous">
                                <a name="20">
                                    LOAD MORE
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact
